import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderStarRatingComponent } from './provider-star-rating.component';
import { ProviderRatingsPipeModule } from '../../../pipes/provider-ratings-pipe.module';

@NgModule({
  declarations: [ProviderStarRatingComponent],
  imports: [CommonModule, ProviderRatingsPipeModule],
  exports: [ProviderStarRatingComponent],
})
export class ProviderStarRatingModule {}
