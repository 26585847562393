import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderCredentialsComponent } from './provider-credentials.component';

@NgModule({
  declarations: [ProviderCredentialsComponent],
  exports: [ProviderCredentialsComponent],
  imports: [CommonModule],
})
export class ProviderCredentialsModule {}
