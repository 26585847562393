export class DistanceOptionChange {
  isChecked: boolean;
  optionName: string;
  category: string;
  optionValue?: number;

  constructor(category: string, isChecked: boolean, optionName: string, optionValue?: number) {
    this.category = category;
    this.isChecked = isChecked;
    this.optionName = optionName;
    this.optionValue = optionValue;
  }
}
