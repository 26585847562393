/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FadProviderDetailRequest,
  FadProviderDetailService,
  FadSeachStateService,
} from '@dmx-fad-ui/fad/data-access';
import { Subscription } from 'rxjs';

import { SimpleUtilsService } from '@dmx-fad-ui/fad/services';
import { ahnTextPath } from '../../components/ahn-text/ahn-text.enum';

@Component({
  selector: 'atlas-referral-form-page',
  templateUrl: './referral-form-page.component.html',
  styleUrls: ['./referral-form-page.component.scss'],
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class ReferralFormPageComponent implements OnInit, OnDestroy {
  fadStateSub: Subscription;
  pageType: ahnTextPath;
  providerName: any;
  providerDetailSub: any;
  providerDetail: any;
  constructor(
    private fadStateServ: FadSeachStateService,
    private utilsServ: SimpleUtilsService,
    private activatedRoute: ActivatedRoute,
    private fadProvDetServ: FadProviderDetailService,
  ) {
    this.pageType = ahnTextPath.comments;
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.providerName = params.get('providername');
    });

    const provDetReq: FadProviderDetailRequest = this.utilsServ.createFadProviderDetailRequest(
      this.providerName,
    );

    this.subscribeToProviderDetail();
    this.fadProvDetServ.getProviderDetail(provDetReq);
  }

  subscribeToProviderDetail() {
    this.providerDetailSub = this.fadProvDetServ.providerDetailSubj.subscribe((provDetail) => {
      if (provDetail) {
        this.providerDetail = provDetail;
      }
    });
  }

  ngOnDestroy(): void {
    this.providerDetailSub.unsubscribe();
  }
}
