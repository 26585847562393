import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComparePageComponent } from './compare-page.component';
import {
  CompareCategoryModule,
  CompareCredentialsModule,
  CompareDistanceModule,
  CompareExpertiseModule,
  CompareLanguagesModule,
  CompareLgbtModule,
  CompareLocationModule,
  CompareOnlineSchedulingModule,
  CompareProvNameModule,
  CompareRatingsModule,
  NoResultsModule,
  SearchModule,
  AhnTextModule,
  ShareButtonModule,
} from '@dmx-fad-ui/fad/components';

@NgModule({
  declarations: [ComparePageComponent],
  imports: [
    CommonModule,
    CompareProvNameModule,
    CompareCredentialsModule,
    CompareExpertiseModule,
    CompareDistanceModule,
    CompareLanguagesModule,
    CompareLgbtModule,
    CompareLocationModule,
    CompareOnlineSchedulingModule,
    CompareRatingsModule,
    CompareCategoryModule,
    NoResultsModule,
    SearchModule,
    AhnTextModule,
    ShareButtonModule,
  ],
})
export class ComparePageModule {}
