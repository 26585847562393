<div class="distance-filter-container">
  <div class="distance-filter">
    <div class="parent-container" *ngIf="!this.showSelectedAddress">
      <!-- <p class="section-title">Find Near Me</p> -->
      <atlas-address-autocomplete
        class="full-width"
        addressType="geocode"
        ngDefaultControl
        [(ngModel)]="autocompleteInput"
        (setPlaceEmitter)="onEnter($event)"
        (setAddressEmitter)="selectAddress($event)"
        (change)="displayDistancePicklist()"
      >
      </atlas-address-autocomplete>
    </div>

    <div *ngIf="this.showSelectedAddress">
      <!-- <p class="section-title">Results Nearby</p> -->
      <div class="selected-address">
        {{ this.displayAddressValue }}
        <span
          class="selected-address-clear"
          (click)="clearSelectedAddress()"
          (keydown.enter)="clearSelectedAddress()"
          >CHANGE</span
        >
      </div>
    </div>

    <div *ngIf="this.showDistancePicklist" class="filter-padding">
      <mat-form-field appearance="outline">
        <mat-label>Distance</mat-label>
        <mat-select
          disableOptionCentering
          (selectionChange)="selectDistanceValue($event.value)"
          [value]="defaultDistanceValue"
          #distancePicklist
        >
          <mat-option *ngFor="let distanceOption of distanceOptions" [value]="distanceOption.value">
            {{ distanceOption.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div>
    <input #hiddenTriggerField matInput hidden="true" />
  </div>
</div>
