import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultsPageComponent } from './search-results-page.component';
import {
  ProviderResultsModule,
  ProviderResultsFilterModule,
  SortBarModule,
  ResultSummaryModule,
  SearchModule,
  SearchListMapviewModule,
  NoResultsModule,
  CompareProviderDrawerModule,
} from '@dmx-fad-ui/fad/components';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
@NgModule({
  declarations: [SearchResultsPageComponent],
  imports: [
    CommonModule,
    ProviderResultsModule,
    ProviderResultsFilterModule,
    SortBarModule,
    ResultSummaryModule,
    SearchModule,
    MatProgressSpinnerModule,
    MatIconModule,
    SearchListMapviewModule,
    NoResultsModule,
    MatSidenavModule,
    MatExpansionModule,
    MatButtonModule,
    CompareProviderDrawerModule,
  ],
})
export class SearchResultsPageModule {}
