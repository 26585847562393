import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { specialPipe } from './html.pipe';

@NgModule({
  declarations: [specialPipe],
  exports: [specialPipe],
  imports: [CommonModule],
})
export class HtmlPipeModule {}
