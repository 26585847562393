<div *ngIf="userAdded === 'list'">
  <div
    tabindex="0"
    class="list-container"
    *ngIf="providerResults && providerResults.length"
    #searchContent
  >
    <div class="provider-card-block" *ngFor="let result of providerResults; let i = index">
      <atlas-provider-result
        [userAdded]="this.userAdded"
        [providerResult]="result"
        [indexFromResultList]="i"
        [providerResultCount]="this.providerResultCount"
      ></atlas-provider-result>
    </div>
    <div class="show-ten-more">
      <button
        mat-button
        class="show-ten-more-button"
        *ngIf="hasMoreResults"
        (click)="showTenMoreResults()"
        (keydown.enter)="showTenMoreResults()"
        [disableRipple]="true"
        tabindex="0"
      >
        SHOW 10 MORE RESULTS
      </button>
    </div>
  </div>
</div>

<div *ngIf="userAdded === 'map'">
  <div
    *ngIf="providerResults && providerResults.length"
    class="search-page-map no-display-large"
    #searchContent
  >
    <atlas-provider-map
      *ngIf="isBrowser()"
      [mapHeight]="this.mapHeight"
      [providerResult]="this.providerResults"
    ></atlas-provider-map>
  </div>
  <div class="map-container">
    <div class="search-page-list" *ngIf="providerResults && providerResults.length">
      <div class="provider-card-block" *ngFor="let result of providerResults; let i = index">
        <atlas-provider-result
          [userAdded]="this.userAdded"
          [providerResult]="result"
          [indexFromResultList]="i"
          [providerResultCount]="this.providerResultCount"
        ></atlas-provider-result>
      </div>
    </div>
    <div *ngIf="providerResults && providerResults.length" class="search-page-map no-display-small">
      <atlas-provider-map
        *ngIf="isBrowser()"
        [mapHeight]="this.mapHeight"
        [providerResult]="this.providerResults"
      ></atlas-provider-map>
    </div>
  </div>
  <div class="show-ten-more">
    <button
      mat-button
      class="show-ten-more-button"
      *ngIf="hasMoreResults"
      (click)="showTenMoreResults()"
      (keydown.enter)="showTenMoreResults()"
      [disableRipple]="true"
    >
      SHOW 10 MORE RESULTS
    </button>
  </div>
</div>
