import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderInfoComponent } from './provider-info.component';
import { ProviderStarRatingModule } from '../provider-star-rating/provider-start-rating.module';
import { CompareProviderCheckboxModule } from '../../compare-provider/compare-provider-checkbox/compare-provider-checkbox.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ProviderInfoComponent],
  imports: [CommonModule, ProviderStarRatingModule, CompareProviderCheckboxModule, RouterModule],
  exports: [ProviderInfoComponent],
})
export class ProviderInfoModule {}
