<div [ngClass]="isNewProviderDetailExperience ? 'provider-credentials-container-new' : 'provider-credentials-container'">
  <div class="ahn-credentials">
    <h3>Credentials</h3>
    <div class="credentials-info">
      <section *ngIf="jobsCount > 0">
        <div class="credential-wrapper">
        <p class="credentials-title">Positions</p>
        <div class="credential-container">
          <div *ngFor="let job of jobTitlesResult" class="credential-list">{{ job }}</div>
        </div></div>
      </section>
      <section *ngIf="fellowshipsCount > 0">
        <div class="credential-wrapper">
        <p class="credentials-title">Fellowship</p>
        <div class="credential-container">
          <div *ngFor="let fellow of fellowshipsResult" class="credential-list">{{ fellow }}</div>
        </div></div>
      </section>
      <section *ngIf="schoolsCount > 0">
        <div class="credential-wrapper">
        <p class="credentials-title">School</p>
        <div class="credential-container">
          <div *ngFor="let school of schoolsResult" class="credential-list">{{ school }}</div>
        </div></div>
      </section>
      <section *ngIf="boardsCount > 0">
        <div class="credential-wrapper">
        <p class="credentials-title">Board</p>
        <div class="credential-container">
          <div *ngFor="let board of boardsResult" class="credential-list">{{ board }}</div>
        </div></div>
      </section>
      <section *ngIf="resCount > 0">
        <div class="credential-wrapper">
        <p class="credentials-title">Residency</p>
        <div class="credential-container">
          <div *ngFor="let res of residenciesResult" class="credential-list">
            {{ res }}
          </div>
        </div></div>
      </section>
    </div>
  </div>
</div>
