/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../../apps/dmx-fad-ui/src/environments/environment';
import { map } from 'rxjs/operators';
import { FadEmailRequest } from './fad-email-request';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class FadEmailService {
  endpoint: string = `${environment.emailEndpoint}`;
  request: FadEmailRequest;

  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  sendEmail(request: FadEmailRequest, recaptchaToken: string): void {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', `${environment.xApiKey}`)
      .set('recaptcha', recaptchaToken);

    this.httpClient
      .post<any>(this.endpoint, request, {
        headers,
      })
      .pipe(
        map((resp) => {
          console.log(resp);
        }),
      )
      .subscribe();
  }
}
