<section class="desktop-view desktop-phys-column" *ngIf="physiciansList.length > 1" id="directory">
  <div id="section-{{ letter }}" class="nav-letter">
    {{ letter }}
    <div class="flex-doc-container">
      <ul class="flex-doc-item doctor-name" *ngIf="physiciansList.length > 0">
        <li *ngFor="let phys of letteredPhysList">
          <a class="doctor-name-item" href="{{ phys.profileName }}">{{ phys.name }}</a>
        </li>
      </ul>
    </div>

    <div class="return-block">
      <span class="material-icons-outlined chevron-bump-arrow">expand_less</span>
      <a class="back-to-top doctor-name" href="physician-index#header"> RETURN TO TOP </a>
    </div>
    <div class="horizontal-line"></div>
  </div>
</section>
