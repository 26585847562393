export enum ahnTextReturn {
  default = 'Return to Find Care',
  provider = 'Return to Provider',
  compare = 'Return to Comparison',
  results = 'Return to Results',
  directory = 'Return to Directory',
}

export enum ahnTextPath {
  comments = '/comments',
  directory = '/physician-index',
  compare = '/compare/providers',
  results = '/search-results/',
  provider = 'provider-detail',
  referralForm = '/referral-form',
  thankYou = '/thank-you',
}
