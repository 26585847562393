import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-compare-category',
  templateUrl: './compare-category.component.html',
  styleUrls: [
    './compare-category.component.scss',
    '../../../pages/compare-page/compare-page.component.scss',
  ],
})
export class CompareCategoryComponent {
  @Input() title: string;
  constructor() {}
}
