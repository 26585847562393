/// <reference types="@types/google.maps" />

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';
import { FadSeachStateService } from '@dmx-fad-ui/fad/data-access';
import { BehaviorSubject, Subject } from 'rxjs';
import { FilterService } from '../filter-service/filter.service';
import { LocationPracticeBuilding } from './location-practice-building';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class LocationService {
  //user location
  public locationCoordinates: string = '';
  public locationAddress: string = '';

  //user-selected location-filter search criteria
  public searchAddress: string = '';
  public searchDistance: number = 0;

  //used to update location-filter with address when user shares location via sort-bar-component,
  public searchAddressSubject: Subject<string> = new BehaviorSubject<string>('');

  constructor(
    private fadSearchStateServ: FadSeachStateService,
    private filterService: FilterService,
  ) {
    this.fadSearchStateServ = fadSearchStateServ;
    this.filterService = filterService;
  }

  updateAddressWhenLocationSharedFromSortBar() {
    this.searchAddressSubject.next(this.searchAddress);
  }

  // async requestUserLocation() {
  //   if (this.locationCoordinates !== '' || this.getLocationFromSessionStorage() === true) {
  //     return true;
  //   } else {
  //     return await this.getLocationCoordinates();
  //   }
  // }

  // getLocationFromSessionStorage(): boolean {
  //   const coordsFromStorage: string = sessionStorage.getItem('locationCoordinates');
  //   if (coordsFromStorage !== null) {
  //     this.locationCoordinates = coordsFromStorage;
  //     this.locationAddress = sessionStorage.getItem('locationAddress');
  //     return true;
  //   }
  //   return false;
  // }

  // setLocationInSessionStorage(coords: string, address: string): void {
  //   sessionStorage.setItem('locationCoordinates', this.locationCoordinates);
  //   sessionStorage.setItem('locationAddress', this.locationAddress);
  // }
  // deleteLocationSessionStorage() {
  //   sessionStorage.removeItem('locationCoordinates');
  //   sessionStorage.removeItem('locationAddress');
  //   this.filterService.nearestToMeDisabled.next(true);
  // }

  // getLocationCoordinates(): Promise<boolean> {
  //   this.fadSearchStateServ.isShowSpinnerSubj.next(true);

  //   return new Promise((resolve, reject) => {
  //     const options = {
  //       enableHighAccuracy: false,
  //       timeout: 5000,
  //       maximumAge: Infinity,
  //     };

  //     // show spinner
  //     // because google chrome location determination takes 2-5 seconds
  //     this.fadSearchStateServ.isShowSpinnerSubj.next(true);

  //     navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         this.locationCoordinates = position.coords.latitude + ', ' + position.coords.longitude;
  //         this.locationAddress = await this.getAddressFromGeocode(this.locationCoordinates);
  //         this.searchAddress = this.locationAddress;
  //         this.searchDistance = 20;
  //         this.setLocationInSessionStorage(this.locationCoordinates, this.locationAddress);
  //         this.fadSearchStateServ.isShowSpinnerSubj.next(false);
  //         resolve(true);
  //       },
  //       (error) => {
  //         console.log('location: not shared, or not available.');
  //         this.fadSearchStateServ.isShowSpinnerSubj.next(false);
  //         resolve(false);
  //       },
  //       options,
  //     );
  //   });
  // }

  // async getAddressFromGeocode(geoCode: string) {
  //   const geoCoder = new google.maps.Geocoder();
  //   let formattedAddress: string = '';

  //   await geoCoder.geocode({ address: geoCode }, function(results, status) {
  //     if (status === google.maps.GeocoderStatus.OK) {
  //       results[0].address_components.forEach((element) => {
  //         if (element.types[0] === 'locality') {
  //           formattedAddress = formattedAddress.concat(element.short_name.concat(', '));
  //         }
  //         if (element.types[0] === 'administrative_area_level_1') {
  //           formattedAddress = formattedAddress.concat(element.short_name.concat(' '));
  //         }
  //         if (element.types[0] === 'postal_code') {
  //           formattedAddress = formattedAddress.concat(element.short_name);
  //         }
  //       });
  //     } else {
  //       console.log('Geocode exception: ' + status);
  //       formattedAddress = '';
  //     }
  //   });
  //   return formattedAddress;
  // }

  transformPracticeBuildingStringToObject(locationsPracticeBuildingString: string) {
    const locationsPracticeBuilding = new LocationPracticeBuilding();
    const locationsPracticeBuildingStringArr = locationsPracticeBuildingString.split('::');

    locationsPracticeBuilding.primaryPractice = this.getlocationsPracticeBuildingByKey(
      locationsPracticeBuildingStringArr,
      'primaryPractice',
    );

    locationsPracticeBuilding.practiceName = this.getlocationsPracticeBuildingByKey(
      locationsPracticeBuildingStringArr,
      'practiceName',
    );
    locationsPracticeBuilding.phone = this.getlocationsPracticeBuildingByKey(
      locationsPracticeBuildingStringArr,
      'phone',
    );

    locationsPracticeBuilding.locationName = this.getlocationsPracticeBuildingByKey(
      locationsPracticeBuildingStringArr,
      'locationName',
    );
    locationsPracticeBuilding.sublocationName = this.getlocationsPracticeBuildingByKey(
      locationsPracticeBuildingStringArr,
      'pracBuildingSubLocation',
    );
    locationsPracticeBuilding.buildingAddress = this.getlocationsPracticeBuildingByKey(
      locationsPracticeBuildingStringArr,
      'buildingAddress',
    );
    locationsPracticeBuilding.buildingCity = this.getlocationsPracticeBuildingByKey(
      locationsPracticeBuildingStringArr,
      'buildingCity',
    );
    locationsPracticeBuilding.buildingState = this.getlocationsPracticeBuildingByKey(
      locationsPracticeBuildingStringArr,
      'buildingState',
    );
    locationsPracticeBuilding.buildingZip = this.getlocationsPracticeBuildingByKey(
      locationsPracticeBuildingStringArr,
      'buildingZip',
    );
    locationsPracticeBuilding.buildingCounty = this.getlocationsPracticeBuildingByKey(
      locationsPracticeBuildingStringArr,
      'buildingCounty',
    );
    locationsPracticeBuilding.fax = this.getlocationsPracticeBuildingByKey(
      locationsPracticeBuildingStringArr,
      'fax',
    );
    locationsPracticeBuilding.buildingLat = this.getlocationsPracticeBuildingByKey(
      locationsPracticeBuildingStringArr,
      'buildingLat',
    );
    locationsPracticeBuilding.buildingLong = this.getlocationsPracticeBuildingByKey(
      locationsPracticeBuildingStringArr,
      'buildingLong',
    );
    locationsPracticeBuilding.distance = this.getlocationsPracticeBuildingByKey(
      locationsPracticeBuildingStringArr,
      'distance',
    );
    locationsPracticeBuilding.directions =
      'https://maps.google.com/maps?q=' +
      locationsPracticeBuilding.buildingAddress +
      ',' +
      locationsPracticeBuilding.buildingCity +
      ',' +
      locationsPracticeBuilding.buildingState +
      ',' +
      locationsPracticeBuilding.buildingZip;
    return locationsPracticeBuilding;
  }

  getlocationsPracticeBuildingByKey(building: Array<string>, buildingAttribute: string) {
    const result = building.find((item) => item.includes(buildingAttribute));
    if (result) {
      const value = result.split('=')[1];
      return value == 'null' ? '' : value;
    }
    return '';
  }
}
