import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintProviderImageComponent } from './print-provider-image.component';

@NgModule({
  declarations: [PrintProviderImageComponent],
  imports: [CommonModule],
  exports: [PrintProviderImageComponent],
})
export class PrintProviderImageModule {}
