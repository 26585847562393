<div id="provider-detail-page" class="persistent-bar">
  <atlas-search
    [isLandingPage]="false"
    [isProviderDetailPage]="true"
    [isSearchResultsPage]="false"
    [shouldAutoFocus]="false"
  ></atlas-search>
</div>

<div *ngIf="noResultsFound; then noResultsFoundTemplate; else yesResultsFoundTemplate"></div>

<ng-template #noResultsFoundTemplate>
  <div class="no-results-provider-page">
    <atlas-no-results></atlas-no-results>
  </div>
</ng-template>

<ng-template #yesResultsFoundTemplate>
  <div [ngClass]="isNewProviderDetailExperience ? 'provider-page-container container-fluid': 'provider-page-container container'">
    <div class="return-print-share">
      <atlas-result-summary [pageType]="this.pageType"></atlas-result-summary>
    </div>
    <atlas-provider
      [providerName]="this.providerName"
      *ngIf="this.hasData"
      [providerDetails]="this.providerDetail"
    ></atlas-provider>
  </div>
</ng-template>
