/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FilterService } from '@dmx-fad-ui/fad/services';
import { Subscription } from 'rxjs';
import { ChildOptionChange } from '../filter-child/childOptionChange';

@Component({
  selector: 'atlas-new-sliders-filter',
  templateUrl: './new-sliders-filter.component.html',
  styleUrls: ['./new-sliders-filter.component.scss'],
})
export class NewSlidersFilterComponent implements OnInit {
  @Input() slidertitle: string;
  @Input() facetName: string;
  @Input() toolTip: string;
  @Input() sliderCheked: boolean;
  selectedColor: string = 'primary';
  @Output() checkBoxEmitter = new EventEmitter<ChildOptionChange>();

  clearSub: Subscription;

  constructor(private filterServ: FilterService) {
    this.filterServ = filterServ;
  }

  ngOnInit(): void {
    this.clearSub = this.filterServ.clearAllFiltersSubj.subscribe((doClear) => {
      this.sliderCheked = false;
    });
  }

  sliderChange(event) {
    const childOption = new ChildOptionChange('Slider Filters', event.checked, event.source.name);
    this.sliderCheked = event.checked;
    this.checkBoxEmitter.next(childOption);
  }
}
