import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function npiValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const npi = '80840' + value;

    if (!value || value.length < 10) {
      return null;
    }

    const npiArray: number[] = [];

    let j = 1;

    for (let i = npi.length - 1; i >= 0; i--) {
      if (j % 2 === 0) {
        if (Number(npi[i]) * 2 > 9) {
          npiArray.push(Number(npi[i]) * 2 - 9);
        } else {
          npiArray.push(Number(npi[i]) * 2);
        }
      } else {
        npiArray.push(Number(npi[i]));
      }
      j++;
    }

    let sum = 0;

    npiArray.forEach((number) => {
      sum += number;
    });

    return sum % 10 === 0 ? null : { npiCheck: true };
  };
}
