import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TabableService {
  enable: boolean = false;

  private _focusMainContent = new BehaviorSubject<boolean>(false);
  private _focusSearchResults = new BehaviorSubject<boolean>(false);
  private _focusFilterContent = new BehaviorSubject<boolean>(false);

  focusMainContent$ = this._focusMainContent.asObservable();

  focusSearchResults$ = this._focusSearchResults.asObservable();

  focusFilterContent$ = this._focusFilterContent.asObservable();

  mainContent() {
    this.enable = true;
    this._focusMainContent.next(this.enable);
  }

  searchResults() {
    this.enable = true;
    this._focusSearchResults.next(this.enable);
  }

  filterContent() {
    this.enable = true;
    this._focusFilterContent.next(this.enable);
  }
}
