<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="example-sidenav" mode="over">
    <div class="mat-sidenav-header">
      <div class="header-top-items clear-button-close">
        <mat-icon (click)="closeSideNav()">close</mat-icon>
      </div>
      <div class="header-top-items"><div class="filter-title">Filters</div></div>
    </div>
    <!-- Mobile Filter -->
    <div *ngIf="this.isDesktop === false">
      <atlas-provider-results-filter
        (distanceFilterEmitter)="receiveDistanceFilterEmitterMobile($event)"
        (conditionEmitter)="receiveConditionFilterEmitter($event)"
        (languageEmitter)="receiveLanguageFilterEmitter($event)"
        (genderEmitter)="receiveGenderFilterEmitter($event)"
        (ahnEmitter)="receiveCheckboxFilterEmitterMobile('ahn', $event)"
        (onlineScheduleEmitter)="receiveCheckboxFilterEmitterMobile('online', $event)"
        (phoneScheduleEmitter)="receiveCheckboxFilterEmitterMobile('phone', $event)"
        (lgbtqiaplusEmitter)="receiveCheckboxFilterEmitterMobile('lgbtq', $event)"
        (videoBioEmitter)="receiveCheckboxFilterEmitterMobile('video', $event)"
        (mobileApplyEmitter)="receiveMobileApplyEmitter($event)"
      ></atlas-provider-results-filter>
    </div>
    <!-- End Mobile Filter -->
    <!-- Begin Desktop & Tablet Filter -->
  </mat-drawer>
  <div class="dmx-search-results-page">
    <div class="persistent-bar">
      <atlas-search
        [isLandingPage]="false"
        [shouldAutoFocus]="false"
        [existingSearchText]="fadSearchQuery"
        [isProviderDetailPage]="false"
        [isSearchResultsPage]="true"
      >
      </atlas-search>
    </div>
    <div class="container-body">
      <div class="container-row">
        <div *ngIf="this.isDesktop === true" class="item-filter remove-on-tablet remove-on-mobile">
          <atlas-provider-results-filter
            (distanceFilterEmitter)="receiveDistanceFilterEmitter($event)"
            (conditionEmitter)="receiveConditionFilterEmitter($event)"
            (languageEmitter)="receiveLanguageFilterEmitter($event)"
            (genderEmitter)="receiveGenderFilterEmitter($event)"
            (ahnEmitter)="receiveCheckboxFilterEmitter('ahn', $event)"
            (onlineScheduleEmitter)="receiveCheckboxFilterEmitter('online', $event)"
            (phoneScheduleEmitter)="receiveCheckboxFilterEmitter('phone', $event)"
            (lgbtqiaplusEmitter)="receiveCheckboxFilterEmitter('lgbtq', $event)"
            (videoBioEmitter)="receiveCheckboxFilterEmitter('video', $event)"
          ></atlas-provider-results-filter>
        </div>
        <!-- End Desktop & Tablet Filter -->
        <!-- DESKTOP -->
        <div id="desktop" class="item-block remove-on-mobile remove-on-tablet">
          <div>
            <div [ngClass]="{ hidden: this.isHideResultsBar }" class="container-row">
              <div class="results-layout">
                <atlas-result-summary
                  [currentSearchQuery]="fadSearchQuery"
                  [pageType]="this.pageType"
                ></atlas-result-summary>
              </div>
              <div *ngIf="this.isDesktop" class="sort-bar-layout">
                <atlas-sort-bar (sortEmitter)="receiveSortEmitter($event)"></atlas-sort-bar>
              </div>
              <div class="list-map-layout">
                <atlas-search-list-mapview></atlas-search-list-mapview>
              </div>
            </div>
            <div class="no-results" *ngIf="this.isHideResultsBar && !this.isRequestPending">
              <atlas-no-results></atlas-no-results>
            </div>
            <div *ngIf="this.isDesktop">
              <atlas-provider-results
                [providerResults]="this.providerResults"
                (requestTenMoreEmitter)="receiveShowTenMoreRequest($event)"
              ></atlas-provider-results>
            </div>
          </div>
        </div>
        <!-- TABLET & MOBILE -->
        <div id="tablet" class="item-block-mobile">
          <div id="srp" class="container padding-top-mobile">
            <div class="container-row">
              <div class="filter-layout">
                <mat-icon
                  class="material-icons color-filter"
                  aria-hidden="false"
                  aria-label="Show Filter"
                  (click)="drawer.toggle()"
                  >tune</mat-icon
                >
                <div class="filter-text" (click)="drawer.toggle()">FILTERS</div>
              </div>
              <div *ngIf="!this.isDesktop" class="sort-bar-layout">
                <atlas-sort-bar (sortEmitter)="receiveSortEmitter($event)"></atlas-sort-bar>
              </div>
            </div>
            <div
              [ngClass]="{ hidden: this.isHideResultsBar }"
              class="container-row padding-top-mobile padding-bottom-mobile"
            >
              <div class="results-layout">
                <atlas-result-summary
                  [currentSearchQuery]="fadSearchQuery"
                  [pageType]="this.pageType"
                ></atlas-result-summary>
              </div>
              <div [ngClass]="{ hidden: this.isHideResultsBar }" class="list-map-layout">
                <atlas-search-list-mapview></atlas-search-list-mapview>
              </div>
            </div>
            <div class="no-results" *ngIf="this.isHideResultsBar && !this.isRequestPending">
              <atlas-no-results></atlas-no-results>
            </div>
            <div *ngIf="this.isDesktop === false">
              <atlas-provider-results
                [providerResults]="this.providerResults"
                (requestTenMoreEmitter)="receiveShowTenMoreRequest($event)"
              ></atlas-provider-results>
            </div>
          </div>
        </div>
      </div>
    </div>
    <atlas-compare-provider-drawer> </atlas-compare-provider-drawer>
  </div>
</mat-drawer-container>
