import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'atlas-provider-credentials',
  templateUrl: './provider-credentials.component.html',
  styleUrls: ['./provider-credentials.component.scss'],
})
export class ProviderCredentialsComponent implements OnInit {
  @Input() schoolsResult: string[];
  schoolsCount: number;
  @Input() residenciesResult: string[];
  resCount: number;
  @Input() jobTitlesResult: string[];
  jobsCount: number;
  @Input() boardsResult: string[];
  boardsCount: number;
  @Input() fellowshipsResult: string[];
  fellowshipsCount: number;

  @Input() npi: string;
  isNewProviderDetailExperience = environment.isTabProviderPageEnabled;

  constructor() {}

  ngOnInit(): void {
    this.parseCredentialCounts();
  }

  parseCredentialCounts() {
    this.schoolsCount = this.schoolsResult.length;
    this.resCount = this.residenciesResult.length;
    this.jobsCount = this.jobTitlesResult.length;
    this.boardsCount = this.boardsResult.length;
    this.fellowshipsCount = this.fellowshipsResult.length;
  }
}
