import { isPlatformBrowser } from '@angular/common';
import { Inject, Pipe, PipeTransform, PLATFORM_ID } from '@angular/core';

@Pipe({
  name: 'specialPipe',
})
export class specialPipe implements PipeTransform {
  constructor(@Inject(PLATFORM_ID) private platformId: string) {}
  transform(value: string): string {
    if (isPlatformBrowser(this.platformId)) {
      const doc = new DOMParser().parseFromString(value, 'text/html');
      return doc.documentElement.textContent;
    } else {
      return value;
    }
  }
}
