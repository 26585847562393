import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { ProviderMapComponent } from './provider-map.component';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [ProviderMapComponent],
  exports: [ProviderMapComponent],
  imports: [CommonModule, GoogleMapsModule],
})
export class ProviderMapModule {}
