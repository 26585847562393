import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintPageComponent } from './print-page.component';
import {
  PrintProviderImageModule,
  PrintCredentialsModule,
  PrintLocationsModule,
  PrintBioModule,
  PrintExpertiseModule,
} from '@dmx-fad-ui/fad/components';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [PrintPageComponent],
  imports: [
    CommonModule,
    PrintProviderImageModule,
    PrintLocationsModule,
    PrintBioModule,
    PrintExpertiseModule,
    PrintCredentialsModule,
    QRCodeModule,
  ],
})
export class PrintPageModule {}
