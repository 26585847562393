import { Component } from '@angular/core';

@Component({
  selector: 'atlas-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
})
export class NoResultsComponent {
  constructor() {}
}
