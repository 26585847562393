/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  Output,
  AfterViewInit,
  EventEmitter,
} from '@angular/core';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { ViewportScroller } from '@angular/common';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { TabableService, WindowSizeService } from '@dmx-fad-ui/fad/services';

import { environment } from '../../../../../environments/environment';
import { Subscription, debounceTime } from 'rxjs';
import { ProviderPracticeBuilding } from '../../provider-result/provider-practice-building/provider-practice-building';
import { CommentsAndRatings } from '../provider-ratings-comments/commentsAndRatings';
import { FadCommentService } from '@dmx-fad-ui/fad/data-access';

@Component({
  selector: 'atlas-provider-profile',
  templateUrl: './provider-profile.component.html',
  styleUrls: ['./provider-profile.component.scss'],
})
export class ProviderProfileComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() providerProfileDetails: any;
  @Output() isCommentsClicked = new EventEmitter<boolean>();
  imageUrl: string;
  providerId: string;
  signatureSpecialty: string;
  displayName: string;
  profileName: string;
  conditionsDisplay: string = '';
  ratingValue: string;
  ratingCount: string;
  providerNPI: string;
  seeAll = true;
  conditionsText = '';
  maxCharacterCount = 60;
  viewPortWidth = 0;
  schedulable: string;
  providerDetailsResult: any;
  visitTypes: string[];
  employed: boolean = false;
  isSquare: boolean = false;
  @Input() providerPracticeBuildings: Array<ProviderPracticeBuilding>;
  primaryPracticeBuilding: ProviderPracticeBuilding;
  phoneNumber: string;
  outPatientEnabled: string = 'true';
  isNewProviderDetailExperience = environment.isTabProviderPageEnabled;
  roundImageUrl: any;
  imageNotFoundComparePath: string = '../../../../../assets/AHN-provider-not-found-square.png';
  isMobile: boolean;
  noProviderImage: boolean = false;
  commentCount: number;
  commentsAndRatings: CommentsAndRatings;

  @ViewChild('mainContent') mainContent: ElementRef;
  refocusSub: Subscription = new Subscription();

  private readonly viewportChange = this.viewportRuler
    .change(200)
    .subscribe(() => this.ngZone.run(() => this.setMaxWords()));
  referralsOn: boolean;
  commentsAndRatingsSub: Subscription;

  constructor(
    private viewportScroller: ViewportScroller,
    private readonly viewportRuler: ViewportRuler,
    private readonly ngZone: NgZone,
    private router: Router,
    private tabableService: TabableService,
    private windowSizeServ: WindowSizeService,
    private fadCommentServ: FadCommentService,
  ) {
    this.viewportScroller = viewportScroller;
    this.viewportRuler = viewportRuler;
    this.ngZone = ngZone;
    this.router = router;
    this.tabableService = tabableService;
    this.setMaxWords();
  }

  ngOnInit(): void {
    this.windowSizeServ.windowWidthSubj.subscribe((width) => {
      this.isMobile = this.windowSizeServ.checkWindowWidthIsMobile(width);
    });

    if (this.providerProfileDetails) {
      this.subscribeToCommentService();
      this.providerDetailsResult = this.providerProfileDetails.payload.providerDetail;
      this.imageUrl = this.providerDetailsResult.imageUrl;
      this.displayName = this.providerDetailsResult.displayName;
      this.signatureSpecialty = this.providerDetailsResult.signatureSpecialty;
      this.ratingCount = this.providerDetailsResult.reviewRatingCount;
      this.ratingValue = this.providerDetailsResult.reviewRatingValue;
      this.schedulable = this.providerDetailsResult.schedulable;
      this.providerNPI = this.providerDetailsResult.npi;
      this.profileName = this.providerDetailsResult.profileName;
      this.outPatientEnabled = this.providerDetailsResult.outPatientEnabled;
      this.roundImageUrl = this.providerDetailsResult.printImageUrl;

      this.phoneNumber = this.getPhoneNumberFromPracticeBuildings();
      this.employed = this.providerDetailsResult.employed == 'Employed' ? true : false;

      if (this.providerDetailsResult.conditions) {
        this.providerDetailsResult.conditions.forEach((cond) => {
          this.conditionsDisplay += ` ${cond},`;
        });
      }

      this.conditionsDisplay = this.conditionsDisplay.trim();
      this.conditionsDisplay = this.conditionsDisplay.slice(0, -1);
      this.getConditionsText();
      if (this.providerDetailsResult) this.visitTypes = this.providerDetailsResult.visitTypes;
      this.isSquare = this.imageUrl.indexOf('Square') > -1 ? true : false;
      if (this.isNewProviderDetailExperience) {
        // New experience
        this.ratingValue = this.removeTrailingZero(this.providerDetailsResult.reviewRatingValue);
        if (this.roundImageUrl.indexOf('AHN-provider-not-found') > -1) {
          this.isSquare = true;
          this.roundImageUrl = this.imageNotFoundComparePath;
          this.noProviderImage = true;
        }
      }
    }
  }

  isCommentsClick(isClicked: boolean) {
    this.isCommentsClicked.emit(isClicked);
  }

  removeTrailingZero(value) {
    if (value === null || value === undefined || value.trim() === '') {
      return '';
    } else {
      return parseFloat(value).toString();
    }
  }

  ngAfterViewInit() {
    this.refocusSub = this.tabableService.focusMainContent$.subscribe((data) => {
      if (data) {
        this.refocusRecieve();
      }
    });
  }

  ngOnDestroy() {
    this.viewportChange.unsubscribe();
    this.refocusSub.unsubscribe();
  }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  seeMore() {
    this.seeAll = !this.seeAll;
    this.getConditionsText();
  }

  getPhoneNumberFromPracticeBuildings() {
    let phoneNumber = '';
    this.primaryPracticeBuilding = this.providerPracticeBuildings.find(
      (item) => item.primaryPractice == 'true',
    );

    if (this.primaryPracticeBuilding) {
      phoneNumber = this.primaryPracticeBuilding.phone;
    } else {
      this.primaryPracticeBuilding = this.providerPracticeBuildings.find(
        (item) => item.primaryPractice == 'false',
      );
      phoneNumber = this.primaryPracticeBuilding.phone;
    }
    return phoneNumber;
  }

  getConditionsText() {
    if (this.seeAll) {
      if (this.conditionsDisplay.length >= this.maxCharacterCount) {
        this.conditionsText = this.conditionsDisplay.substring(
          0,
          this.conditionsDisplay.substring(0, this.maxCharacterCount).lastIndexOf(' '),
        );
        if (!this.conditionsText.endsWith(',')) {
          //if breaking mid-word OR mid-term, then append ellipsis
          this.conditionsText = this.conditionsText + '...';
        }
      } else {
        this.conditionsText = this.conditionsDisplay;
      }
    } else {
      this.conditionsText = this.conditionsDisplay;
    }
  }

  setMaxWords() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { width, height } = this.viewportRuler.getViewportSize();
    this.viewPortWidth = width;
    if (this.viewPortWidth >= 1360) {
      this.maxCharacterCount = 60;
    }
    // else if(width >=992){
    //   this.maxCharacterCount = 50;
    // }
    else {
      this.maxCharacterCount = 25;
    }
    this.getConditionsText();
  }

  onMakeAppointment() {
    if (environment.docasap) {
      const url = environment.docasapBase + this.providerNPI + environment.docasapTrail;
      window.open(url, '_blank');
    } else if (environment.myChartSchedulingEnabled) {
      const url = environment.myChartProdEndpoint + this.providerNPI;
      window.open(url, '_blank');
    } else {
      this.router.navigate(['schedule-appointment/' + this.profileName]);
    }
  }

  subscribeToCommentService() {
    this.commentsAndRatingsSub = this.fadCommentServ.providerCommentsSubj.subscribe((cNr) => {
      this.commentsAndRatings = cNr.payload.commentDetail.commentsAndRatings;
      this.commentCount = this.commentsAndRatings.comments.length;
    });
  }

  refocusRecieve() {
    if (!window.location.pathname.includes('search-results')) {
      this.mainContent.nativeElement.focus();
      this.tabableService.enable = false;
    }
  }
}
