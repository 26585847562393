import { Component } from '@angular/core';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner/progress-spinner-module';

@Component({
  selector: 'atlas-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  constructor() {}
}
