<div class="make-appointment-container">
  <div *ngIf="this.isOnlineScheduling === 'true'">
    <div
      class="make-appointment-button"
      (click)="onMakeAppointment()"
      (keydown.enter)="onMakeAppointment()"
    >
      MAKE AN APPOINTMENT
    </div>
  </div>
</div>
