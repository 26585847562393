import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'starRating' })
export class starRatingPipe implements PipeTransform {
  transform(ratingnumber: number): string {
    if (ratingnumber > 1 && ratingnumber < 2) {
      return 'star star_half star_outline star_outline star_outline';
    }
    if (ratingnumber > 2 && ratingnumber < 3) {
      return 'star star star_half star_outline star_outline';
    }
    if (ratingnumber > 3 && ratingnumber < 4) {
      return 'star star star star_half star_outline';
    }
    if (ratingnumber > 4 && ratingnumber <= 4.5) {
      return 'star star star star star_half';
    }
    if (ratingnumber > 4.5 && ratingnumber <= 5) {
      return 'star star star star star';
    }
  }
}
