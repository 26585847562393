<div>
  <div
    [ngClass]="seeAll ? 'conditions-text-limited' : 'conditions-text-all'"
    *ngIf="expertiseListed.length > 0"
    style="flex: 1"
  >
    <span>{{ trunctedExpertiseListed }}</span>
    <span
      *ngIf="expertiseListed.length >= maxCharacterCount"
      class="see-all-text"
      (click)="seeMore()"
      >{{ seeAll ? 'See all...' : 'See less...' }}</span
    >
  </div>

  <div *ngIf="expertiseListed.length === 0" class="not-available">- not available -</div>
</div>
