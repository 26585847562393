import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { SeoService, StyleObservablesService, WindowSizeService } from '@dmx-fad-ui/fad/services';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'atlas-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  constructor(
    private styleObservablesServ: StyleObservablesService,
    private seoService: SeoService,
    private windowSizeServ: WindowSizeService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}
  searchBarActiveSub: Subscription;
  isSearchBarActive: boolean = false;
  shouldAutoFocus: boolean = true;
  ngOnInit(): void {
    this.subscribeToSearchBarActivity();
    this.seoService.addMainContent();
    if (isPlatformBrowser(this.platformId)) {
      const wid = document.getElementById('landing-page').clientWidth;
      this.windowSizeServ.windowWidthSubj.next(wid);

      window.addEventListener('resize', () => {
        const resizeWid = document.getElementById('base-page').clientWidth;
        this.windowSizeServ.windowWidthSubj.next(resizeWid);
      });
    }
  }

  subscribeToSearchBarActivity() {
    this.searchBarActiveSub = this.styleObservablesServ.searchBarIsActiveSubj.subscribe(
      (isSearchBarBeingUsed) => {
        this.isSearchBarActive = isSearchBarBeingUsed;
      },
    );
  }

  ngOnDestroy(): void {
    this.searchBarActiveSub.unsubscribe();
  }
}
