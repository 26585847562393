/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

function _window(): any {
  // return the global native browser window object
  return window;
}

export const WINDOW = new InjectionToken('WINDOW_TOKEN');

@Injectable({
  providedIn: 'root',
})
export class WindowRef {
  get nativeWindow(): any {
    return _window();
  }
}

@Injectable()
export class BrowserWindowRef extends WindowRef {
  constructor() {
    super();
  }

  get nativeWindow(): Window | object {
    return window;
  }
}

export function windowFactory(
  browserWindowRef: BrowserWindowRef,
  platformId: object,
): Window | object {
  if (isPlatformBrowser(platformId)) {
    return browserWindowRef.nativeWindow;
  }
  return new Object();
}

export const WINDOW_PROVIDERS = [
  { provide: WindowRef, useClass: BrowserWindowRef },
  { provide: WINDOW, useFactory: windowFactory, deps: [WindowRef, PLATFORM_ID] },
];
