import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderVideodisclaimerComponent } from './provider-videodisclaimer.component';

@NgModule({
  declarations: [ProviderVideodisclaimerComponent],
  exports: [ProviderVideodisclaimerComponent],
  imports: [CommonModule],
})
export class ProviderVideodisclaimerModule {}
