export class LocationCompare {
  locations: string[];
  NPI: string;

  profileName: string;

  constructor(loc: string[], npi: string, profileName: string) {
    this.locations = loc;
    this.NPI = npi;
    this.profileName = profileName;
  }
}
