import { Component, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'atlas-provider-publications',
  templateUrl: './provider-publications.component.html',
  styleUrls: ['./provider-publications.component.scss'],
})
export class ProviderPublicationsComponent {
  @Input() pubMedLinkUrl: string;
  isNewProviderDetailExperience = environment.isTabProviderPageEnabled;

  constructor() {}
}
