import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderDetailPageComponent } from './provider-detail-page.component';
import {
  ProviderModule,
  SearchModule,
  ResultSummaryModule,
  NoResultsModule,
} from '@dmx-fad-ui/fad/components';
import { ProviderComponent } from '../../components/provider/provider.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [ProviderDetailPageComponent],
  exports: [ProviderDetailPageComponent, ProviderComponent],
  imports: [
    CommonModule,
    ProviderModule,
    SearchModule,
    ResultSummaryModule,
    MatProgressSpinnerModule,
    NoResultsModule,
  ],
})
export class ProviderDetailPageModule {}
