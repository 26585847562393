<div id="landing-page" class="container-landing ahn-background-ahn25MistGreen base-height">
  <div class="container-logo" [ngClass]="{ 'pushed-logo': isSearchBarActive }">
    <div class="logo-item">
      <h1>Find Care</h1>
    </div>
  </div>
  <div class="container-search" [ngClass]="{ 'pushed-search': isSearchBarActive }">
    <atlas-search
      class="search-bar"
      [isLandingPage]="true"
      [isProviderDetailPage]="false"
      [shouldAutoFocus]="true"
    ></atlas-search>
    <div
      class="view-full container"
      [ngClass]="{ 'pushed-view-directory': isSearchBarActive }"
    ></div>
  </div>
</div>
