<div class="list-map-container">
  <mat-button-toggle-group
    tabindex="0"
    #group="matButtonToggleGroup"
    [value]="selectedVal"
    (change)="onValChange(group.value)"
  >
    <mat-button-toggle [disableRipple]="true" value="list"> LIST </mat-button-toggle>
    <mat-button-toggle class="" [disableRipple]="true" value="map"> MAP </mat-button-toggle>
  </mat-button-toggle-group>
</div>
