import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultSummaryComponent } from './result-summary.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AhnTextModule } from '../ahn-text/ahn-text.module';
import { ShareButtonModule } from '../share-button/share-button.module';

@NgModule({
  declarations: [ResultSummaryComponent],
  imports: [CommonModule, ClipboardModule, AhnTextModule, ShareButtonModule],
  exports: [ResultSummaryComponent],
})
export class ResultSummaryModule {}
