export class OnlineSchedulingCompare {
  scheduling: string;
  NPI: string;

  profileName: string;

  constructor(sched: string, npi: string, profileName: string) {
    this.scheduling = sched;
    this.NPI = npi;
    this.profileName = profileName;
  }
}
