<div id="footer" class="global-footer section">
  <section class="ahn-global-footer">
    <div class="footer-main-container">
      <div class="container">
        <div class="footer-container">
          <div class="social-links-container">
            <div class="social-bar-global">
              <div class="footer-logo-container">
                <img
                  width="145px"
                  height="46px"
                  src="../../../../assets/ahn-logo-reverse.svg"
                  alt="Allegheny Health Network"
                />
              </div>
              <div class="footer-find-dr-phone-1">(412) DOCTORS</div>
              <div class="footer-find-dr-phone">
                <a href="tel:4123628677" class="footer-find-dr-phone-2"> (412) 362-8677 </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-link-menu">
      <mat-expansion-panel class="footer-expansion-panel" showToggle [expanded]="panelOpenState">
        <mat-expansion-panel-header class="footer-expansion-panel-header">
          <mat-panel-title>
            <div class="footer-drawer-header">Be Informed</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a class="footer-link" href="https://www.ahn.org/about/privacy-policy" target="_blank"
          >Privacy Policy</a
        >
        <a
          class="footer-link"
          href="https://www.ahn.org/about/notice-of-privacy-practices"
          target="_blank"
          >Notice of Privacy Practices</a
        >
        <a class="footer-link" href="https://www.ahn.org/about/gdpr" target="_blank">GDPR</a>
        <a
          class="footer-link"
          href="https://www.ahn.org/about/terms-of-service-for-ahn"
          target="_blank"
          >Terms of Service</a
        >
      </mat-expansion-panel>
    </div>
    <div class="copy-right">
      <section class="container">
        Copyright © {{ thisYear }} Allegheny Health Network. All rights reserved.
      </section>
    </div>
  </section>
</div>
