import { AfterViewChecked, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'atlas-print-bio',
  templateUrl: './print-bio.component.html',
  styleUrls: ['./print-bio.component.scss'],
})
export class PrintBioComponent implements AfterViewChecked {
  @Input() bioInput: string = '';
  @Output() isContentLoadedEmitter = new EventEmitter<boolean>();

  constructor() {}

  ngAfterViewChecked(): void {
    this.isContentLoadedEmitter.emit(true);
  }

  hasBioContent(): boolean {
    if (this.bioInput === '<p></p>') {
      return false;
    } else {
      return true;
    }
  }
}
