import { Component, OnInit } from '@angular/core';
import { ListMapViewService } from '@dmx-fad-ui/fad/services';

@Component({
  selector: 'atlas-search-list-mapview',
  templateUrl: './search-list-mapview.component.html',
  styleUrls: ['./search-list-mapview.component.scss'],
})
export class SearchListMapviewComponent implements OnInit {
  selectedVal: string;
  constructor(private listMapViewService: ListMapViewService) {
    this.listMapViewService = listMapViewService;
  }

  ngOnInit() {
    this.selectedVal = this.listMapViewService.userClickValue;
    //page load set default value to 'list'
    if (!this.selectedVal) {
      this.listMapViewService.userClickValue = 'list';
      this.selectedVal = this.listMapViewService.userClickValue;
    }
  }

  onValChange(val: string) {
    this.selectedVal = val;
    this.listMapViewService.userClickValue = this.selectedVal;
    this.listMapViewService.checkListMapView();
  }
}
