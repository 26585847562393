/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'atlas-provider-expertise',
  templateUrl: './provider-expertise.component.html',
  styleUrls: ['./provider-expertise.component.scss'],
})
export class ProviderExpertiseComponent {
  @Input() providerExpertiseResults: any;
  isNewproviderProfileExperience = environment.isTabProviderPageEnabled;
  constructor() {}
}
