/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CompareProviderService } from '@dmx-fad-ui/fad/services';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'atlas-compare-expertise',
  templateUrl: './compare-expertise.component.html',
  styleUrls: [
    './compare-expertise.component.scss',
    '../../../pages/compare-page/compare-page.component.scss',
  ],
})
export class CompareExpertiseComponent implements OnInit, OnDestroy {
  @Input() expertises: string[] = [];

  seeAll = false;
  expertiseListed: string = '';
  trunctedExpertiseListed = '';
  maxCharacterCount: number = 70;
  showHideAllSubscription: Subscription;
  constructor(private compareProviderService: CompareProviderService) {
    this.compareProviderService = compareProviderService;
  }

  ngOnInit(): void {
    this.expertises.forEach((exp) => {
      this.expertiseListed += exp + ', ';
    });

    this.showHideAllSubscription = this.compareProviderService.seeAll$.subscribe((seeAllValue) => {
      this.seeAll = seeAllValue;
      this.getExpertiseText();
    });
  }
  ngOnDestroy() {
    this.showHideAllSubscription.unsubscribe();
  }

  seeMore() {
    this.seeAll = !this.seeAll;
    this.compareProviderService.seeAll(this.seeAll);
  }

  getExpertiseText() {
    if (this.seeAll) {
      if (this.expertiseListed.length >= this.maxCharacterCount) {
        this.trunctedExpertiseListed = this.expertiseListed.substring(
          0,
          this.expertiseListed.substring(0, this.maxCharacterCount).lastIndexOf(' '),
        );
        if (!this.trunctedExpertiseListed.endsWith(',')) {
          //if breaking mid-word OR mid-term, then append ellipsis
          this.trunctedExpertiseListed = this.trunctedExpertiseListed + '...';
        }
      } else {
        this.trunctedExpertiseListed = this.expertiseListed;
      }
    } else {
      this.trunctedExpertiseListed = this.expertiseListed;
    }
  }
}
