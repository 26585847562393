/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  TabableService,
  UrlMonitorService,
  CompareProviderService,
} from '@dmx-fad-ui/fad/services';
import { NavigationEndEvent } from '../../models/navigationEndEvent';
import { Subscription } from 'rxjs';
import { FadCommentService, FadSeachStateService } from '@dmx-fad-ui/fad/data-access';
import { ahnTextPath, ahnTextReturn } from './ahn-text.enum';
import { elementAt } from 'rxjs/operators';

@Component({
  selector: 'atlas-ahn-text',
  templateUrl: './ahn-text.component.html',
  styleUrls: ['./ahn-text.component.scss'],
})
export class AhnTextComponent implements OnInit, AfterViewInit, OnDestroy {
  urlArray: NavigationEndEvent[] = [];
  urlMonitorSub: Subscription;
  isLoaded: boolean = false;
  returnText: string;
  returnProvider: string;
  refocusSub: Subscription = new Subscription();

  @Input() indexPage: boolean = true;
  @ViewChild('mainContent') mainContent: ElementRef;

  constructor(
    private router: Router,
    private location: Location,
    private urlMonitorServ: UrlMonitorService,
    private tabableService: TabableService,
    private fadSearchStateService: FadSeachStateService,
    private compareServ: CompareProviderService,
  ) {
    this.router = router;
    this.location = location;
    this.urlMonitorServ = urlMonitorServ;
    this.tabableService = tabableService;
    this.fadSearchStateService = fadSearchStateService;
    this.compareServ = compareServ;
  }

  ngOnInit(): void {
    this.urlMonitorSub = this.urlMonitorServ.urlMonitorSubj.subscribe((navEvents) => {
      this.urlArray = [];
      navEvents.forEach((item) => {
        if (item) {
          if (item.url.includes('physician-index#section')) {
            item.url = ahnTextPath.directory;
          }
          this.urlArray.push(item);
        }
      });

      this.urlArray = this.urlArray.filter((x) => {
        return !x.url.includes('#');
      });

      const textUrl1 = this.urlArray[this.urlArray.length - 1]
        ? this.urlArray[this.urlArray.length - 1].url
        : '/';
      const textUrl2 = this.urlArray[this.urlArray.length - 2]
        ? this.urlArray[this.urlArray.length - 2].url
        : '/';
      if (textUrl1.includes('physician-index#')) {
        this.urlArray[this.urlArray.length - 1].url = ahnTextPath.directory;
      }

      if (
        this.urlArray.length > 1 &&
        textUrl1 == ahnTextPath.directory &&
        textUrl2 == ahnTextPath.directory
      ) {
        this.urlArray.pop();
      }
      this.setReturnText();
    });
  }

  ngAfterViewInit() {
    this.tabableService.focusMainContent$.subscribe((data) => {
      if (data) {
        this.refocusRecieve();
      }
    });
  }
  ngOnDestroy() {
    this.urlMonitorSub.unsubscribe();
    this.refocusSub.unsubscribe();
  }

  onReturnToCare() {
    if (this.returnText === ahnTextReturn.provider) {
      this.router.navigate([`/${this.returnProvider}`]);
    }
    if (this.returnText === ahnTextReturn.compare) {
      this.router.navigate([ahnTextPath.compare], {
        queryParams: this.compareServ.buildCompareQueryParams(),
      });
    }
    if (this.returnText === ahnTextReturn.default) {
      this.router.navigate(['']);
    }
    if (this.returnText === ahnTextReturn.results) {
      const y = [];
      for (const i in this.urlArray) {
        if (this.urlArray[i].url.indexOf(ahnTextPath.results) > -1) {
          y.push(i);
        }
      }
      const targetURL = this.urlArray[y[y.length - 1]].url;
      this.router.navigateByUrl(targetURL);
    }
  }

  setReturnText() {
    const thisUrl = this.urlArray[this.urlArray.length - 1]
      ? this.urlArray[this.urlArray.length - 1].url
      : '/';

    const secondLastUrl = this.urlArray[this.urlArray.length - 2]
      ? this.urlArray[this.urlArray.length - 2].url
      : '/';

    const thirdLastUrl = this.urlArray[this.urlArray.length - 3]
      ? this.urlArray[this.urlArray.length - 3].url
      : '/';

    if (thisUrl === '/') {
      this.returnText = ahnTextReturn.default;
      this.isLoaded = true;
    } else if (thisUrl.includes(ahnTextPath.directory)) {
      if (secondLastUrl.includes(ahnTextPath.compare)) {
        this.returnText = ahnTextReturn.compare;
      } else if (secondLastUrl.lastIndexOf(ahnTextPath.comments) !== -1) {
        this.returnText = ahnTextReturn.provider;
        this.returnProvider = secondLastUrl;
      } else if (secondLastUrl.indexOf(ahnTextPath.results) !== -1) {
        this.returnText = ahnTextReturn.results;
        this.isLoaded = true;
      } else if (secondLastUrl === '/' || thirdLastUrl.includes(ahnTextPath.directory)) {
        this.returnText = ahnTextReturn.default;
        this.isLoaded = true;
      } else if (secondLastUrl.includes(ahnTextPath.thankYou)) {
        this.returnText = ahnTextReturn.default;
        this.isLoaded = true;
      } else if (secondLastUrl.includes(ahnTextPath.referralForm)) {
        this.returnText = ahnTextReturn.provider;
        this.returnProvider = thirdLastUrl.substring(thirdLastUrl.lastIndexOf('/') + 1);
        this.isLoaded = true;
      } else {
        this.returnText = ahnTextReturn.provider;
        this.isLoaded = true;
        this.returnProvider = secondLastUrl.substring(secondLastUrl.lastIndexOf('/') + 1);
      }
    } else if (thisUrl.includes(ahnTextPath.compare)) {
      if (
        secondLastUrl === '/' ||
        this.urlArray.find((navEvent) => navEvent.url.includes(ahnTextPath.results)) === undefined
      ) {
        this.returnText = ahnTextReturn.default;
        this.isLoaded = true;
      } else {
        this.returnText = ahnTextReturn.results;
        this.isLoaded = true;
      }
    } else if (thisUrl.indexOf(ahnTextPath.comments) !== -1) {
      this.returnText = ahnTextReturn.provider;
      this.returnProvider = thisUrl.split(ahnTextPath.comments)[0];
    } else if (thisUrl.indexOf(ahnTextPath.referralForm) !== -1) {
      this.returnText = ahnTextReturn.provider;
      this.returnProvider = thisUrl.split(ahnTextPath.referralForm)[0];
    } else {
      if (secondLastUrl.includes(ahnTextPath.compare)) {
        this.returnText = ahnTextReturn.compare;
      } else if (secondLastUrl.includes(ahnTextPath.directory) && thirdLastUrl === '/') {
        this.returnText = ahnTextReturn.default;
      } else {
        this.returnText = ahnTextReturn.results;
        this.isLoaded = true;
      }
    }
  }

  refocusRecieve(): void {
    this.mainContent.nativeElement.focus();
    this.tabableService.enable = false;
  }
}
