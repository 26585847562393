import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SpinnerModule } from '../app/modules/pages/base-page/spinner/spinner.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PagesModule } from './modules/pages/pages.module';
import { RouterModule, Router, Scroll, RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { WINDOW_PROVIDERS } from './modules/services/google-tag-manager/window';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { WindowSizeService } from './modules/services/windowSize/windowSize.service';
import { BrowserStateInterceptor } from './browserstate.interceptor';
import { AppRouteReuseStrategy } from './app-route-reuse-strategy';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SpinnerModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PagesModule,
    RouterModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    WINDOW_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  scrollPosition: number = 0;
  constructor(
    router: Router,
    viewportScroller: ViewportScroller,
    private windowService: WindowSizeService,
  ) {
    this.windowService.windowScroolPosition.subscribe((position) => {
      this.scrollPosition = position;
    });

    router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe((e) => {
      const checkSearchPage = e.routerEvent.url;
      const isCheckSearchPage = checkSearchPage.includes('search-results');
      if (isCheckSearchPage) {
        console.log('Scroll to ' + this.scrollPosition);
        viewportScroller.scrollToPosition([0, this.scrollPosition]);
      } else {
        console.log('Scroll to top');
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
