import { Injectable } from '@angular/core';
import { FadSearchRequest } from '@dmx-fad-ui/fad/data-access';
import { BehaviorSubject } from 'rxjs';
import { FadSearchSortOrder } from './fad-search-sort-order.enum';

@Injectable({
  providedIn: 'root',
})
export class FadSeachStateService {
  request: FadSearchRequest;
  currentSearchQuery?: string;
  currentSearchQuerySubj = new BehaviorSubject<string>('');
  currentSearchOrderSubj = new BehaviorSubject<string>(FadSearchSortOrder.default);
  currentIndexSubj = new BehaviorSubject<number>(0);
  currentSearchPageSubj = new BehaviorSubject<number>(0);
  currentVisibleResultCountSubj = new BehaviorSubject<number>(0);
  isShowSpinnerSubj = new BehaviorSubject<boolean>(false);
  checkLoadMoreSubj = new BehaviorSubject<boolean>(false);

  // Appended filter strings to user query - TODO: will replace filterserv piece by piece
  objectTypeUserQueryAppendedFiltersSubj = new BehaviorSubject<string>('');
  userQueryTotalAppendedFiltersSubj = new BehaviorSubject<string>('');
  userQueryTotalAppendedFilters: string = '';
  scrollY: number = 0;

  constructor() {}

  updateIndex(startIndex: number) {
    this.currentIndexSubj.next(startIndex);
  }

  updateResultsCount(resultsCount: number) {
    this.currentVisibleResultCountSubj.next(resultsCount);
  }
}
