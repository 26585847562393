import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FilterService } from '@dmx-fad-ui/fad/services';
import { ChildOptionChange } from '../filter-child/childOptionChange';

@Component({
  selector: 'atlas-location-provider-filter',
  templateUrl: './location-provider-filter.component.html',
  styleUrls: ['./location-provider-filter.component.scss'],
})
export class LocationProviderFilterComponent implements OnInit {
  filterOptions: string[] = ['All Results', 'Providers Only', 'Locations Only'];
  selectedFilter: string = 'All Results';
  @Output() checkBoxEmitter = new EventEmitter<ChildOptionChange>();

  constructor(private filterServ: FilterService) {}

  ngOnInit(): void {
    this.subscribeToProviderLocationFilterSubj();
  }

  radioFilterChange(event) {
    const childOption = new ChildOptionChange('Provider - Location', true, event.value);
    this.checkBoxEmitter.next(childOption);
  }

  subscribeToProviderLocationFilterSubj() {
    this.filterServ.providerLocationFilterSubj.subscribe((data) => {
      this.selectedFilter = data;
    });
  }
}
