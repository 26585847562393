import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { starRatingPipe } from './provider-ratings.pipe';

@NgModule({
  declarations: [starRatingPipe],
  exports: [starRatingPipe],
  imports: [CommonModule],
})
export class ProviderRatingsPipeModule {}
