import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { BasePageComponent } from './modules/pages/base-page/base-page.component';
import { LandingPageComponent } from './modules/pages/landing-page/landing-page.component';

const routes: Routes = [
  {
    path: '',
    component: BasePageComponent,
    children: [
      {
        path: '',
        component: LandingPageComponent,
      },
    ],
  },
  {
    path: '',
    component: BasePageComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/pages/pages.module').then((m) => m.PagesModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      enableTracing: false,
      useHash: false,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
