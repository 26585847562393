/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProviderPracticeBuilding } from '../../provider-result/provider-practice-building/provider-practice-building';
import { DomSanitizer } from '@angular/platform-browser';
import { npiValidator } from './npi-validator';
import { birthdateValidator } from './birthdate-validator';
import {
  FadProviderDetailResponse,
  FadEmailService,
  FadEmailRequest,
} from '@dmx-fad-ui/fad/data-access';
import { LocationService } from '../../../services/location/location-service';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { ActivatedRoute, Router } from '@angular/router';

export interface SelectOptions {
  value: string;
  label: string;
}

@Component({
  selector: 'atlas-referral-form',
  templateUrl: './referral-form.component.html',
  styleUrls: ['./referral-form.component.scss'],
})
export class ReferralFormComponent implements OnInit {
  scheduleTypes: SelectOptions[] = [
    { value: 'none', label: 'None' },
    { value: 'next-available', label: 'Next Available' },
    { value: 'within-7-days', label: 'Within 7 days' },
    { value: 'routine', label: 'Routine' },
    { value: 'preferred-date', label: 'Preferred Date' },
  ];

  appointmentTypes: SelectOptions[] = [
    { value: 'consult', label: 'Consult' },
    { value: 'second-opinion', label: 'Second Opinion' },
    { value: 'first-visit', label: 'First Visit' },
  ];

  referralTypes: SelectOptions[] = [
    { value: 'none', label: 'None' },
    { value: 'physician', label: 'Physician' },
    { value: 'group', label: 'Group' },
  ];

  form: FormGroup;
  locations: Array<ProviderPracticeBuilding>;
  PHONE = /\(?\b[0-9][0-9][0-9]\)?[-. ]?[0-9][0-9][0-9][-. ]?[0-9][0-9][0-9][0-9]\b/;
  maxBirthdate = new Date();
  minBirthdate = new Date(1903, 1, 0);
  minApptDate = new Date();
  providerNPI: string;
  providerDisplayName: string;
  providerDetailSub: any;
  @Input() providerDetail: FadProviderDetailResponse;
  providerImage: string;
  providerSpecialty: string;
  token: string;
  emailRequest: FadEmailRequest;
  provider: any;
  hasData: boolean = false;

  constructor(
    public fb: FormBuilder,
    private fadEmailService: FadEmailService,
    private locationService: LocationService,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    if (this.providerDetail) {
      this.provider = this.providerDetail.payload.providerDetail;

      this.locations = this.getLocationResults(this.provider.locations);
      this.providerNPI = this.provider.npi;
      this.providerDisplayName = this.provider.displayName;
      if (this.provider.printImageUrl.includes('AHN-provider-not-found')) {
        this.provider.printImageUrl = '../../../../../assets/AHN-provider-not-found-print.png';
      }

      this.hasData = true;
    }

    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      birthdate: ['', [Validators.required, birthdateValidator()]],
      zipCode: [
        '',
        [
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.minLength(5),
          Validators.maxLength(5),
        ],
      ],
      phoneNumber: ['', [Validators.required, Validators.pattern(this.PHONE)]],
      patientEmailAddress: ['', [Validators.email]],
      location: ['', []],
      schedule: ['', []],
      appointment: ['', [Validators.required]],
      referTo: ['', []],
      patientNeedDescription: ['', []],
      referringPhysicianName: ['', [Validators.required]],
      referringPhysicianPhone: ['', [Validators.required, Validators.pattern(this.PHONE)]],
      referringPhysicianNPI: [
        '',
        [
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.minLength(10),
          Validators.maxLength(10),
          npiValidator(),
        ],
      ],
    });
  }

  public resolved(captchaResponse: string): void {
    this.token = captchaResponse;
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  submit() {
    this.createEmailBody(this.form.value);
    this.router.navigate(['thank-you'], { relativeTo: this.route });
  }
  close() {
    this.form.reset();
  }

  getLocationResults(locationResultsArray) {
    const locations: Array<any> = [];
    const pracBuildings = locationResultsArray;
    pracBuildings.forEach((item) => {
      const providerPracticeBuildingObj =
        this.locationService.transformPracticeBuildingStringToObject(item);
      locations.push(providerPracticeBuildingObj);
    });
    return locations;
  }

  createEmailBody(formValue: object) {
    const emailBodyArray: string[] = [];
    const referrerNPI: string = this._sanitizer.sanitize(
      SecurityContext.HTML,
      formValue['referringPhysicianNPI'],
    );

    Object.entries(formValue).forEach(([key, value]) => {
      if (value != null) {
        emailBodyArray.push(
          `${this.parseKey(key)}: ${this._sanitizer.sanitize(SecurityContext.HTML, value)},`,
        );
      } else if (value == '') {
        emailBodyArray.push(`${this.parseKey(key)}: N/A`);
      }
    });

    this.createEmailObject(emailBodyArray.join(''), referrerNPI);
  }

  parseKey(camelCaseWord: string) {
    const result = camelCaseWord.replace(/([a-z])([A-Z])/g, '$1 $2');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }

  createEmailObject(msgBody: string, referringNPI: string) {
    const emailObj: FadEmailRequest = {
      msgBody: '',
      referralNPI: '',
      referringNPI: '',
      referralProviderName: '',
    };

    emailObj.msgBody = msgBody;
    emailObj.referralNPI = this.providerNPI;
    emailObj.referralProviderName = this.providerDisplayName;
    emailObj.referringNPI = referringNPI;

    this.emailRequest = emailObj;
    if (this.token) {
      this.sendEmail();
    }
  }

  sendEmail() {
    this.fadEmailService.sendEmail(this.emailRequest, this.token);
  }
}
