/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'atlas-referral-provider-card',
  templateUrl: './referral-provider-card.component.html',
  styleUrls: ['./referral-provider-card.component.scss'],
})
export class ReferralProviderCardComponent implements OnInit {
  providerDisplayName: string;
  providerImage: string;
  providerSpecialty: string;
  @Input() provider: any;

  constructor() {}

  ngOnInit(): void {
    if (this.provider) {
      this.providerDisplayName = this.provider.displayName;
      this.providerImage = this.provider.printImageUrl;
      if (this.provider.printImageUrl.includes('AHN-provider-not-found')) {
        this.provider.printImageUrl = '../../../../../assets/AHN-provider-not-found-print.png';
      }
      this.providerSpecialty = this.provider.signatureSpecialty;
    }
  }
}
