import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesktopHeaderComponent } from './desktop-header.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [DesktopHeaderComponent],
  exports: [DesktopHeaderComponent],
  imports: [CommonModule, RouterModule],
})
export class DesktopHeaderModule {}
