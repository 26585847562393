import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewSlidersFilterComponent } from './new-sliders-filter.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChildFilterTransformPipeModule } from '../../../pipes/filter-child-transform/child-filter-transform-pipe.module';
import { ToolTipTransformPipeModule } from '../../../pipes/tooltip-transform/tooltip-transform-pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
@NgModule({
  declarations: [NewSlidersFilterComponent],
  exports: [NewSlidersFilterComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    ChildFilterTransformPipeModule,
    ToolTipTransformPipeModule,
    MatTooltipModule,
  ],
})
export class NewSlidersFilterModule {}
