import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderLgbtComponent } from './provider-lgbt.component';

@NgModule({
  declarations: [ProviderLgbtComponent],
  exports: [ProviderLgbtComponent],
  imports: [CommonModule],
})
export class ProviderLgbtModule {}
