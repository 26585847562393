import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderContactComponent } from './provider-contact.component';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { NoSchedulingTextModule } from '../../no-scheduling-text/no-scheduling-text.module';

@NgModule({
  declarations: [ProviderContactComponent],
  imports: [CommonModule, MatButtonModule, MatExpansionModule, NoSchedulingTextModule],
  exports: [ProviderContactComponent],
})
export class ProviderContactModule {}
