/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PhysicianIndexResponse } from './physicianIndexResponse';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class PhysicianIndexService {
  endpoint: string = `${environment.physicianIndexEndpoint}`;
  request: string = '{}';
  physicianIndexSubj = new Subject<any>();
  constructor(private http: HttpClient) {}

  getPhysicianIndex(): void {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', `${environment.xPubApiKey}`);

    this.http
      .post<PhysicianIndexResponse>(this.endpoint, this.request, { headers })
      .pipe(
        map((resp) => {
          this.physicianIndexSubj.next(resp['payload']);
        }),
      )
      .subscribe();
  }
}
