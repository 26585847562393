// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FadCommentService, FadProviderDetailRequest } from '@dmx-fad-ui/fad/data-access';
import { SeoService, WindowSizeService } from '@dmx-fad-ui/fad/services';
import { Subscription } from 'rxjs';
import { AhnTextComponent } from '../../components/ahn-text/ahn-text.component';
import { environment } from '../../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'atlas-provider-comments-page',
  templateUrl: './provider-comments-page.component.html',
  styleUrls: ['./provider-comments-page.component.scss'],
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class ProviderCommentsPageComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  providerComments: any;
  hasData: boolean = false;
  providerName: string = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  providerCommentsdata: any;
  noResultsFound: boolean;
  providerCommentsSub: Subscription;
  filterHistogram: Map<string, number>;
  commentFilter: string = '';

  @ViewChild('ahntext') child: AhnTextComponent;

  constructor(
    private fadCommentServ: FadCommentService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private seoService: SeoService,
    private windowSizeServ: WindowSizeService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.fadCommentServ = fadCommentServ;
    this.activatedRoute = activatedRoute;
    this.router = router;
    this.seoService = seoService;
    this.filterHistogram = new Map<string, number>();
    this.filterHistogram.set('amazing', 0);
    this.filterHistogram.set('caring', 0);
    this.filterHistogram.set('compassionate', 0);
    this.filterHistogram.set('understanding', 0);
    this.filterHistogram.set('professional', 0);
    this.filterHistogram.set('thorough', 0);
    this.filterHistogram.set('empathetic', 0);
    this.filterHistogram.set('listens', 0);
    this.filterHistogram.set('knowledgeable', 0);
    this.filterHistogram.set('great bedside manner', 0);
    this.filterHistogram.set('excellent', 0);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.providerName = params.get('providername');
    });

    const provDetReq: FadProviderDetailRequest = this.createFadProviderDetailRequest(
      this.providerName,
    );

    this.fadCommentServ.getProviderComments(provDetReq);

    this.getCommentsData();

    if (isPlatformBrowser(this.platformId)) {
      const wid = document.getElementById('provider-comments-page').clientWidth;
      this.windowSizeServ.windowWidthSubj.next(wid);

      window.addEventListener('resize', () => {
        const resizeWid = document.getElementById('base-page').clientWidth;
        this.windowSizeServ.windowWidthSubj.next(resizeWid);
      });
    }
  }

  getCommentsData() {
    this.providerCommentsSub = this.fadCommentServ.providerCommentsSubj.subscribe((data) => {
      if (data.payload.commentDetail.commentsAndRatings == null) {
        this.noResultsFound = true;
        this.seoService.addMainContent();
      } else {
        this.noResultsFound = false;
        this.providerComments = data.payload.commentDetail.commentsAndRatings.comments;
        if (environment.commentFilterOn) {
          this.parseCommentsFilter();
        }
        this.seoService.addProfileContent(
          data.payload.commentDetail.commentsAndRatings.displayName,
        );
      }
    });
  }

  createFadProviderDetailRequest(providerName: string): FadProviderDetailRequest {
    return { providerQuery: { profileName: providerName, npi: '' } };
  }

  parseCommentsFilter() {
    this.providerComments.forEach((comment) => {
      this.filterHistogram.forEach((amount, filter) => {
        if (comment.commentText.toLowerCase().includes(filter)) {
          this.filterHistogram.set(filter, this.filterHistogram.get(filter) + 1);
        }
      });
    });
  }

  selectCommentFilter(filterName: string) {
    if (this.commentFilter === filterName) {
      this.commentFilter = '';
    } else {
      this.commentFilter = filterName;
    }
  }

  ngOnDestroy(): void {
    if (this.providerCommentsSub) {
      this.providerCommentsSub.unsubscribe();
    }
  }
}
