/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ClearFilterService } from '@dmx-fad-ui/fad/services';
import { ChildOptionChange } from './childOptionChange';

@Component({
  selector: 'atlas-filter-child',
  templateUrl: './filter-child.component.html',
  styleUrls: ['./filter-child.component.scss'],
})
export class FilterChildComponent implements OnInit {
  @Input() childTitle: string;
  @Input() isGenderFilter: boolean = false;
  @Output() checkBoxEmitter = new EventEmitter<ChildOptionChange>();
  propertyHavingInfoIcon: string = 'LGBTQ+';
  isInitialized: boolean = false;
  @Input() isChecked: boolean = false;
  selectedColor: string = 'primary';
  conditionsFilterEnabled: boolean = false;
  @Input() toolTip: any = '';

  constructor(private clearFilterService: ClearFilterService) {
    this.clearFilterService = clearFilterService;
  }

  ngOnInit(): void {
    this.isInitialized = true;

    //Check any condition dropdown is active
    this.clearFilterSubj();
  }

  clearFilterSubj() {
    this.clearFilterService.isConditionsFilterEnabled.subscribe((data) => {
      if (data == true) {
        this.conditionsFilterEnabled = true;
      } else {
        this.conditionsFilterEnabled = false;
      }
    });
  }

  onModelChange($event: MatCheckboxChange, childTitle: string): void {
    const childOption = new ChildOptionChange(childTitle, $event.checked, $event.source.name);
    this.isChecked = !this.isChecked;
    this.checkBoxEmitter.next(childOption);
  }
}
