import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ListMapViewService {
  userClickViewSubj = new Subject<string>();
  userClickValue: string;
  checkListMapView() {
    this.userClickViewSubj.next(this.userClickValue);
  }
}
