<div *ngIf="providerPracticeBuildings" class="provider-locations-container bottom-border">
  <div>
    <div class="provider-location">
      <div
        class="provider-location left-margin wrap"
        *ngFor="let pracBuilding of providerPracticeBuildings; index as i"
      >
        <div>
          <div class="location-box">
            <div>
              <span class="location-name wrap">{{ pracBuilding.practiceName }}</span>
            </div>
            <div
              class="wrap"
              *ngIf="pracBuilding.primaryPractice === 'true' && pracBuilding.locationName"
            >
              {{ pracBuilding.locationName }} (Primary)
            </div>

            <div
              class="wrap"
              *ngIf="pracBuilding.sublocationName && pracBuilding.primaryPractice === 'true'"
            >
              {{ pracBuilding.sublocationName }}
            </div>
            <div
              class="wrap"
              *ngIf="pracBuilding.primaryPractice === 'false' && pracBuilding.locationName"
            >
              {{ pracBuilding.locationName }}
            </div>
            <div
              class="wrap"
              *ngIf="pracBuilding.sublocationName && pracBuilding.primaryPractice === 'false'"
            >
              {{ pracBuilding.sublocationName }}
            </div>
            <div>{{ pracBuilding.buildingAddress }}</div>
            <div class="wrap">
              {{ pracBuilding.buildingCity }}, {{ pracBuilding.buildingState }}
              {{ pracBuilding.buildingZip }}
            </div>
          </div>
          <div class="provider-phone wrap">
            <a href="tel:{{ pracBuilding.phone }}">{{ pracBuilding.phone }}</a>
          </div>
          <div class="provider-fax" *ngIf="pracBuilding.fax">Fax {{ pracBuilding.fax }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
