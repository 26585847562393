/* eslint-disable @typescript-eslint/no-unused-vars */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  NoResultsModule,
  ReferralFormComponentModule,
  ResultSummaryModule,
  SearchModule,
} from '@dmx-fad-ui/fad/components';
import { HtmlPipeModule } from '../../pipes/html.pipe.module';
import { AhnTextModule } from '@dmx-fad-ui/fad/components';
import { ReferralFormPageComponent } from './referral-form-page.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [ReferralFormPageComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    NoResultsModule,
    SearchModule,
    HtmlPipeModule,
    AhnTextModule,
    ReferralFormComponentModule,
    ResultSummaryModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
})
export class ReferralFormPageModule {}
