<div class="google-map-ahn">
  <google-map [options]="options" name="map" width="100%" [height]="this.mapHeight" #map>
    <map-marker
      #somemarker="mapMarker"
      *ngFor="let marker of markers"
      [position]="marker.position"
      [title]="marker.title"
      [icon]="marker.icon"
      [label]="marker.label"
      (mapClick)="openInfo(somemarker, marker.info)"
    ></map-marker>
    <map-info-window [innerHTML]="infoContent"></map-info-window>
  </google-map>
</div>
