<!-- Desktop Filters Only -->
<div
  *ngIf="this.isDesktop === true"
  id="main-block-desktop"
  class="filters-main-block filter-background display"
>
  <div class="top-nav-link">
    <a
      href="."
      #filterContent
      (click)="refocusEmit()"
      (keydown.emit)="refocusEmit()"
      class="skip-to-main-content"
      >Skip to search results</a
    >
  </div>
  <!-- Find Near me block -->
  <div class="find-near-me filters-outer-container">
    <div class="section-title">Find Near Me</div>
    <div>
      <atlas-distance-filter
        (distanceFilterEmitter)="receiveDistanceFilterChange($event)"
      ></atlas-distance-filter>
    </div>
  </div>

  <!-- Filter Results block -->
  <div class="filter-result-continer filters-outer-container">
    <div class="clear-filter-left">Filter Results</div>
    <div
      *ngIf="
        selectedFilters.ahn === true ||
        selectedFilters.schedule === true ||
        selectedFilters.outpatientenabled === true ||
        selectedFilters.lgbtq === true ||
        selectedFilters.video === true ||
        selectedFilters.conditions !== '' ||
        selectedFilters.languages !== '' ||
        selectedFilters.gender !== 'Any Gender' ||
        isSelectedLocationProvider !== 'All Results'
      "
      class="clear-filter-right"
    >
      <atlas-clear-filter (clearAllFiltersEmitter)="receiveClearFilters()"></atlas-clear-filter>
    </div>
  </div>

  <!-- Filters Child block -->
  <div class="filters-child-block">
    <!-- Location - Provider Filter -->
    <div *ngIf="isLocationSearchEnabled" class="filters-outer-container">
      <div class="filter-global-title filter-global-title-sub">Show Me</div>
      <div class="filter-gender-only">
        <atlas-location-provider-filter
          (checkBoxEmitter)="receiveLocationProviderFilterChange($event)"
        ></atlas-location-provider-filter>
      </div>
    </div>
    <!-- Conditions filter -->
    <div class="filter-conditions-root filters-outer-container">
      <div>
        <atlas-condition-list-filter
          *ngIf="hasValue"
          [conditionList]="conditionBucketsArray"
          class="max-width"
          (conditionFilterEmitter)="receiveConditionFilterChange($event)"
        ></atlas-condition-list-filter>
      </div>
    </div>

    <!-- Inner border bottoms -->
    <div class="filter-inner-border">
      <div class="filter-border"></div>
    </div>

    <!-- Show Only Checkboxes -->
    <div class="filter-show-only filters-outer-container">
      <div class="filter-global-title filter-global-title-sub">Show Only Providers Who</div>
      <div class="show-only-sliders">
        <atlas-filter-child
          [childTitle]="ahnProvidersTitle"
          (checkBoxEmitter)="receiveShowOnlyChange($event)"
          [isChecked]="selectedFilters.ahn"
          [toolTip]="'Work for AHN'"
        >
        </atlas-filter-child>
        <atlas-filter-child
          [childTitle]="makeAppointmentTitle"
          (checkBoxEmitter)="receiveShowOnlyChange($event)"
          [isChecked]="selectedFilters.schedule"
          [toolTip]="'Schedule Online'"
        >
        </atlas-filter-child>
        <atlas-filter-child
          [childTitle]="outPatientEnabledTitle"
          (checkBoxEmitter)="receiveShowOnlyChange($event)"
          [isChecked]="selectedFilters.outpatientenabled"
          [toolTip]="'Schedule by Phone'"
        >
        </atlas-filter-child>
        <atlas-filter-child
          [childTitle]="lgbtTitle"
          (checkBoxEmitter)="receiveShowOnlyChange($event)"
          [isChecked]="selectedFilters.lgbtq"
          [toolTip]="'Are a LGBTQIA+ Champion'"
        >
        </atlas-filter-child>
        <atlas-filter-child
          *ngIf="this.videoFilterEnabled"
          [childTitle]="videoBiosTitle"
          (checkBoxEmitter)="receiveShowOnlyChange($event)"
          [isChecked]="selectedFilters.video"
          [toolTip]="'Have a Video Bio'"
        >
        </atlas-filter-child>
      </div>
    </div>

    <!-- Inner border bottoms -->
    <div class="filter-inner-border">
      <div class="filter-border"></div>
    </div>

    <!-- New Gender Filters -->
    <div class="filters-outer-container">
      <div class="filter-global-title filter-global-title-sub">Gender</div>
      <div class="filter-gender-only">
        <atlas-new-gender-filter
          (checkBoxEmitter)="receiveNewGenderChange($event)"
        ></atlas-new-gender-filter>
      </div>
    </div>

    <!-- Inner border bottoms -->
    <div class="filter-inner-border">
      <div class="filter-border"></div>
    </div>
  </div>

  <!-- Languages Filter -->
  <div class="filters-outer-container">
    <atlas-language-filters
      *ngIf="hasLangSpoken"
      (conditionLanguageFilterEmitter)="receiveLanguageSpoken($event)"
      [clearAllEnabled]="clearAllEnabledStatus"
      [facets]="languageSpokenArray"
    ></atlas-language-filters>
  </div>
</div>
<!-- desktop end here -->

<!-- Mobile and Tablet Filters Only -->
<div
  *ngIf="this.isDesktop === false"
  id="main-block-mobile"
  class="filters-main-block filter-background main-block-mobile"
>
  <div class="filters-second-block">
    <!-- Find Near me block -->
    <div class="find-near-me filters-outer-container mobile-filters">
      <div class="section-title">Find Near Me</div>
      <div>
        <!-- <atlas-location-filter-mobile></atlas-location-filter-mobile> -->
        <atlas-distance-filter (distanceFilterEmitter)="receiveDistanceFilterChange($event)">
        </atlas-distance-filter>
      </div>
    </div>

    <!-- Filters Child block -->
    <div class="filters-child-block mobile-filters">
      <!-- Location - Provider Filter -->
      <div *ngIf="isLocationSearchEnabled" class="filters-outer-container">
        <div class="filter-global-title filter-global-title-sub">Show Me</div>
        <div class="filter-gender-only">
          <atlas-location-provider-filter
            (checkBoxEmitter)="receiveLocationProviderFilterChangeMobile($event)"
          ></atlas-location-provider-filter>
        </div>
      </div>
      <!-- Conditions filter -->
      <div class="filter-conditions-root filters-outer-container">
        <div>
          <atlas-condition-list-filter
            (conditionFilterEmitter)="receiveConditionFilterChangeMobile($event)"
            *ngIf="hasValue"
            [conditionList]="conditionBucketsArray"
            class="max-width"
          >
          </atlas-condition-list-filter>
        </div>
      </div>
    </div>

    <!-- Inner border bottoms -->
    <div class="filter-inner-border">
      <div class="filter-border"></div>
    </div>

    <!-- Show Only Checkboxes -->
    <div class="filter-show-only filters-outer-container mobile-filters">
      <div class="filter-global-title filter-global-title-sub">Show Only Providers Who</div>
      <div class="show-only-sliders">
        <atlas-new-sliders-filter
          (checkBoxEmitter)="receiveMobileSlider($event)"
          [facetName]="'Employed'"
          [sliderCheked]="selectedFilters.ahn"
          [toolTip]="'Work for AHN'"
        ></atlas-new-sliders-filter>

        <atlas-new-sliders-filter
          (checkBoxEmitter)="receiveMobileSlider($event)"
          [toolTip]="'outPatientEnabled'"
          [facetName]="'outPatientEnabled'"
          [sliderCheked]="selectedFilters.outpatientenabled"
          [toolTip]="'Schedule by Phone'"
        >
        </atlas-new-sliders-filter>
        <atlas-new-sliders-filter
          (checkBoxEmitter)="receiveMobileSlider($event)"
          [toolTip]="'schedulable'"
          [facetName]="'schedulable'"
          [sliderCheked]="selectedFilters.schedule"
          [toolTip]="'Schedule Online'"
        >
        </atlas-new-sliders-filter>
        <atlas-new-sliders-filter
          (checkBoxEmitter)="receiveMobileSlider($event)"
          [toolTip]="'lgbtqaligned'"
          [facetName]="'lgbtqaligned'"
          [sliderCheked]="selectedFilters.lgbtq"
          [toolTip]="'Are a LGBTQIA+ Champion'"
        >
        </atlas-new-sliders-filter>
        <atlas-new-sliders-filter
          *ngIf="this.videoFilterEnabled"
          (checkBoxEmitter)="receiveMobileSlider($event)"
          [facetName]="'youtubevideobio'"
          [sliderCheked]="selectedFilters.video"
          [toolTip]="'Have a Video Bio'"
        ></atlas-new-sliders-filter>
      </div>
    </div>

    <!-- Inner border bottoms -->
    <div class="filter-inner-border">
      <div class="filter-border"></div>
    </div>

    <!-- New Gender Filters -->
    <div class="filters-outer-container mobile-filters">
      <div class="filter-global-title filter-global-title-sub">Gender</div>
      <div class="filter-gender-only">
        <atlas-new-gender-filter
          (checkBoxEmitter)="receiveNewGenderMobile($event)"
          *ngIf="!isClearAllFilters"
        ></atlas-new-gender-filter>
      </div>
    </div>

    <!-- Inner border bottoms -->
    <div class="filter-inner-border">
      <div class="filter-border"></div>
    </div>

    <!-- Languages Filter -->
    <div class="filters-outer-container mobile-filters border-down">
      <atlas-language-filters
        *ngIf="hasLangSpoken"
        (conditionLanguageFilterEmitter)="receiveMobileLanguageSpoken($event)"
        [clearAllEnabled]="clearAllEnabledStatus"
        [facets]="languageSpokenArray"
      ></atlas-language-filters>
    </div>
  </div>

  <div class="mobile-apply-container">
    <div
      *ngIf="
        selectedFilters.ahn === true ||
        selectedFilters.schedule === true ||
        selectedFilters.outpatientenabled === true ||
        selectedFilters.lgbtq === true ||
        selectedFilters.video === true ||
        selectedFilters.conditions !== '' ||
        selectedFilters.languages !== '' ||
        selectedFilters.gender !== 'Any Gender'
      "
      class="clear-item-button clear-all"
    >
      <atlas-clear-filter (clearAllFiltersEmitter)="receiveClearFilters()"></atlas-clear-filter>
    </div>
    <div class="clear-item-button mobile-show-results">
      <button
        mat-button
        class="show-results-button"
        (click)="showFilterResultsMobile()"
        (keydown.enter)="showFilterResultsMobile()"
      >
        APPLY
      </button>
    </div>
  </div>
</div>
<!-- Mobile and Tablet Filters end -->
