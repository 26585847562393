import { Component } from '@angular/core';

@Component({
  selector: 'atlas-provider-lgbt',
  templateUrl: './provider-lgbt.component.html',
  styleUrls: ['./provider-lgbt.component.scss'],
})
export class ProviderLgbtComponent {
  constructor() {}
}
