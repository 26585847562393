<div class="ahn-credentials">
  <div *ngIf="credentialsExist === false" class="not-available">- not available -</div>
  <div *ngIf="credentialsExist === true" class="credentials-info">
    <section *ngIf="creds.jobTitlesList.length > 0">
      <div class="credentials-title">Positions</div>
      <div class="credential-container">
        <div *ngFor="let job of creds.jobTitlesList" class="credential-list">{{ job }}</div>
      </div>
    </section>
    <section *ngIf="creds.fellowshipsList.length > 0">
      <div class="credentials-title">Fellowship</div>
      <div class="credential-container">
        <div *ngFor="let fellow of creds.fellowshipsList" class="credential-list">{{ fellow }}</div>
      </div>
    </section>
    <section *ngIf="creds.schoolsList.length > 0">
      <div class="credentials-title">School</div>
      <div class="credential-container">
        <div *ngFor="let school of creds.schoolsList" class="credential-list">{{ school }}</div>
      </div>
    </section>
    <section *ngIf="creds.boardsList.length > 0">
      <div class="credentials-title">Board</div>
      <div class="credential-container">
        <div *ngFor="let board of creds.boardsList" class="credential-list">{{ board }}</div>
      </div>
    </section>
    <section *ngIf="creds.residenciesList.length > 0">
      <div class="credentials-title">Residency</div>
      <div class="credential-container">
        <div *ngFor="let res of creds.residenciesList" class="credential-list">
          {{ res }}
        </div>
      </div>
    </section>
  </div>
</div>
