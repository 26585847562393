// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, Input, OnInit } from '@angular/core';
import { PhysicianLetterModel } from '../physician-index-page/physicianLetterModel';

@Component({
  selector: 'atlas-desktop-phys-column',
  templateUrl: './desktop-phys-column.component.html',
  styleUrls: ['./desktop-phys-column.component.scss'],
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class DesktopPhysColumnComponent implements OnInit {
  @Input() physiciansList: PhysicianLetterModel[] = [];
  @Input() letter: string = '';
  physListLengthPerLetter: number = 0;
  letteredPhysList: PhysicianLetterModel[] = [];
  // physQT0: number = 0;
  // physQT1: number = 0;
  // physQT2: number = 0;
  // physListQ0: PhysicianLetterModel[] = [];
  // physListQ1: PhysicianLetterModel[] = [];
  // physListQ2: PhysicianLetterModel[] = [];
  // physListQ3: PhysicianLetterModel[] = [];

  constructor() {}

  ngOnInit(): void {
    this.getAlphabetizedColumnizedPhysicians(this.letter, this.physiciansList);
  }

  getAlphabetizedColumnizedPhysicians(letter: string, physList: PhysicianLetterModel[]): void {
    this.letteredPhysList = [];
    physList.forEach((phys) => {
      if (phys.lastNameLetter === letter) {
        this.letteredPhysList.push(phys);
      }
    });
  }
  scrollToTop() {
    const bounceToElement: HTMLElement = document.getElementById('top');
    bounceToElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
}
