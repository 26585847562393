import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompareExpertiseComponent } from './compare-expertise.component';

@NgModule({
  declarations: [CompareExpertiseComponent],
  imports: [CommonModule],
  exports: [CompareExpertiseComponent],
})
export class CompareExpertiseModule {}
