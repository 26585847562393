/* eslint-disable @typescript-eslint/no-array-constructor */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FadSeachStateService } from '@dmx-fad-ui/fad/data-access';
import { Subscription } from 'rxjs';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { environment } from '../../../../../environments/environment';
import { ProviderPracticeBuilding } from '../provider-practice-building/provider-practice-building';
import { ahnTextPath } from '../../ahn-text/ahn-text.enum';

@Component({
  selector: 'atlas-provider-contact',
  templateUrl: './provider-contact.component.html',
  styleUrls: ['./provider-contact.component.scss'],
})
export class ProviderContactComponent implements OnInit, OnDestroy {
  @Input() practiceBuildingsDisplay: string = '';
  @Input() providerPracticeBuilding: Array<ProviderPracticeBuilding> =
    new Array<ProviderPracticeBuilding>();
  @Input() schedulable: any = '';
  @Input() displayName: string = '';
  @Input() profileName: string = '';
  @Input() providerNPI: string = '';
  @Input() visitTypes: string[] = [];
  @Input() outPatient: boolean = false;
  @Input() isPractiseLocation: boolean;
  @Input() serviceNameDisplay: string;
  @Input() pracBuildingLocationName: string;
  moreProviderPraticeBuilding: Array<ProviderPracticeBuilding>;
  panelOpenState = false;
  panelTimingsOpenState = false;
  currentSortOrder: any;
  searchStateSub: Subscription;

  seeAll = true;
  serviceNameText = '';
  maxCharacterCount = 80;
  viewPortWidth = 0;
  routerLinkPath: string;

  constructor(
    private router: Router,
    private fadSearchStateServ: FadSeachStateService,
    private readonly viewportRuler: ViewportRuler,
  ) {
    this.router = router;
    this.fadSearchStateServ = fadSearchStateServ;
    this.routerLinkPath = ahnTextPath.results;
  }

  ngOnInit(): void {
    this.setMaxWords();
    this.moreProviderPraticeBuilding = this.moreBuildings(this.providerPracticeBuilding);
    if (!this.visitTypes) {
      this.visitTypes = [];
    }

    this.sortOrderSubj();
  }

  getEncodedUrl(keyword: string): string {
    return '/search-results/' + encodeURIComponent(keyword);
  }

  sortOrderSubj() {
    this.searchStateSub = this.fadSearchStateServ.currentSearchOrderSubj.subscribe((state) => {
      this.currentSortOrder = state;
    });
  }

  moreBuildings(results): Array<ProviderPracticeBuilding> {
    return results.filter((value, i) => i != 0);
  }

  onMakeAnAppointment() {
    if (environment.docasap) {
      const url = environment.docasapBase + this.providerNPI + environment.docasapTrail;
      window.open(url, '_blank');
    } else if (environment.myChartSchedulingEnabled) {
      const url = environment.myChartProdEndpoint + this.providerNPI;
      window.open(url, '_blank');
    } else {
      this.router.navigate(['schedule-appointment/' + this.profileName]);
    }
  }

  seeMore() {
    this.seeAll = !this.seeAll;
    this.getConditionsText();
  }

  getConditionsText() {
    if (this.seeAll && this.serviceNameDisplay != undefined) {
      if (this.serviceNameDisplay.length >= this.maxCharacterCount) {
        this.serviceNameText = this.serviceNameDisplay.substring(
          0,
          this.serviceNameDisplay.substring(0, this.maxCharacterCount).lastIndexOf(' '),
        );
        if (!this.serviceNameText.endsWith(',')) {
          //if breaking mid-word OR mid-term, then append ellipsis
          this.serviceNameText = this.serviceNameText + '...';
        }
      } else {
        this.serviceNameText = this.serviceNameDisplay;
      }
    } else {
      this.serviceNameText = this.serviceNameDisplay;
    }
  }

  setMaxWords() {
    const { width, height } = this.viewportRuler.getViewportSize();
    this.viewPortWidth = width;
    if (this.viewPortWidth >= 1360) {
      this.maxCharacterCount = 80;
    } else {
      this.maxCharacterCount = 30;
    }
    this.getConditionsText();
  }

  ngOnDestroy(): void {
    this.searchStateSub.unsubscribe();
  }
}
