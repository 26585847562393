<div class="provider-videodisclaimer-container">
  <p class="disclaimer-info roboto-regular">
    * By accessing this video, I understand that I am leaving the AHN website and I will be
    re-directed to an external website operated by a third party platform provider. I acknowledge
    that the platform provider may collect personal information about me, and about the video that I
    view, on their platform and may use and disclose this information in accordance with its privacy
    policy. I agree that Allegheny Health Network is not responsible for the data collection and use
    practices of this third party.
  </p>
</div>
