import { Component, Input, OnInit } from '@angular/core';
import { FadProviderDetailResponse } from '@dmx-fad-ui/fad/data-access';
import { ahnTextPath } from '../../ahn-text/ahn-text.enum';
import { ProviderPracticeBuilding } from '../../provider-result/provider-practice-building/provider-practice-building';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'atlas-referral-button',
  templateUrl: './referral-button.component.html',
  styleUrls: ['./referral-button.component.scss'],
})
export class ReferralButtonComponent implements OnInit {
  @Input() providerLocations: Array<ProviderPracticeBuilding>;
  @Input() provider: FadProviderDetailResponse;
  profileName: string;
  formPath: string;
  isNewproviderProfileExperience = environment.isTabProviderPageEnabled;

  constructor() {}

  ngOnInit() {
    if (this.provider) {
      this.profileName = '/' + this.provider.payload.providerDetail.profileName;
      this.formPath = ahnTextPath.referralForm.toString().substring(1); // chop the slash off the enum for router encoding
    }
  }
}
