import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'atlas-compare-online-scheduling',
  templateUrl: './compare-online-scheduling.component.html',
  styleUrls: [
    './compare-online-scheduling.component.scss',
    '../../../pages/compare-page/compare-page.component.scss',
  ],
})
export class CompareOnlineSchedulingComponent {
  @Input() isOnlineScheduling: string = 'false';
  @Input() providerNPI: string;
  @Input() profileName: string;
  constructor(private router: Router) {
    this.router = router;
  }

  onMakeAppointment() {
    if (environment.docasap) {
      const url = environment.docasapBase + this.providerNPI + environment.docasapTrail;
      window.open(url, '_blank');
    } else if (environment.myChartSchedulingEnabled) {
      const url = environment.myChartProdEndpoint + this.providerNPI;
      window.open(url, '_blank');
    } else {
      this.router.navigate(['schedule-appointment/' + this.profileName]);
    }
  }
}
