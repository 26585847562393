<div
  class="share-block universal-pointer"
  *ngIf="showShareIcon"
  (keydown.enter)="openShareModal()"
  (click)="openShareModal()"
>
  <span class="material-icons together-blue-icon">share</span>
  <span class="subtext print-share-color hide-in-mobile" aria-label="Share" tabindex="0"
    >SHARE</span
  >
</div>

<div *ngIf="isShareOpen" class="share-dropdown">
  <div class="dropdown-flex">
    <h3 class="green-underline">Share</h3>
    <a
      class="btn-close universal-pointer"
      (keydown.enter)="closeShareModal()"
      (click)="closeShareModal()"
      href="."
    >
      <i class="material-icons-outlined align-arrow-right ng-star-inserted"> close </i>
    </a>
  </div>
  <ul>
    <li>
      <a
        (click)="copyLinkClicked()"
        (keydown.enter)="copyLinkClicked()"
        class="btn-social copy btn-pointer"
      >
        <div *ngIf="isCopyLinkShow" class="copy-row copy-row-unclicked">
          <div class="copy-icon-flex bump-down-copy">
            <img src="../../../../assets/copy-link.svg" alt="" />
          </div>
          <div class="copy-text-flex">
            <span tabindex="0" class="copy-link" tabindex="0">Copy Link</span>
          </div>
        </div>
        <div *ngIf="!isCopyLinkShow" class="copy-row copy-row-clicked">
          <div class="copy-icon-flex bump-down-copy">
            <img src="../../../../assets/copied.svg" alt="" />
          </div>
          <div class="copy-text-flex">
            <div class="btn-social copy copy-text-flex">
              <span class="link-copied">Link copied!</span>
            </div>
          </div>
        </div>
      </a>
    </li>
    <div>
      <li *ngIf="this.newShareButtonOn">
        <a (click)="shareSocial('facebook')" class="social-media-link"
          ><img
            class="social-media-img"
            src="../../../../assets/facebook.svg"
            alt="facebook icon"
          />Facebook</a
        >
      </li>
      <li *ngIf="this.newShareButtonOn">
        <a (click)="shareSocial('twitter')" class="social-media-link"
          ><img
            class="social-media-img"
            src="../../../../assets/twitter.svg"
            alt="twitter icon"
          />Twitter</a
        >
      </li>
      <li *ngIf="this.newShareButtonOn">
        <a (click)="shareSocial('linkedin')" class="social-media-link"
          ><img
            class="social-media-img"
            src="../../../../assets/linkedin.svg"
            alt="linkedin icon"
          />LinkedIn</a
        >
      </li>
      <li>
        <a
          class="btn-social btn-pointer share-email"
          href="mailto:?to=%20&body=Here%20is%20a%20provider%20from%20findcare.ahn.org%20{{
            this.socialMediaURL
          }}&subject=Check%20out%20this%20AHN%20Provider"
          ><span id="share-email-icon" class="material-icons-outlined social-media-img"></span
          >Email</a
        >
      </li>
    </div>
  </ul>
</div>
