<div *ngIf="this.hasData">
  <atlas-referral-provider-card [provider]="this.provider"></atlas-referral-provider-card>
</div>
<form class="referral-form" [formGroup]="form">
  <div>
    <h3>Patient Information</h3>
    <mat-form-field appearance="outline" class="referral-form-field">
      <mat-label>First Name</mat-label>
      <input matInput formControlName="firstName" #firstName required />
      <mat-hint> * Required </mat-hint>
      <mat-error [hidden]="firstName.valid"> Patient's first name is required. </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="referral-form-field">
      <mat-label>Last Name</mat-label>
      <input matInput formControlName="lastName" #lastName required />
      <mat-hint> * Required </mat-hint>
      <mat-error [hidden]="lastName.valid"> Patient's last name is required. </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="referral-form-field">
      <mat-label>Birthday</mat-label>
      <input
        matInput
        placeholder="Birthday"
        formControlName="birthdate"
        #birthdate
        required
        [max]="maxBirthdate"
        [min]="minBirthdate"
        id="birthdate"
        mask="90/00/0099"
        [dropSpecialCharacters]="false"
      />
      <mat-hint> * Required </mat-hint>
      <mat-error [hidden]="birthdate.valid"> Patient's birthdate is required. </mat-error>
      <!-- <mat-datepicker-toggle matSuffix [for]="birthdatePicker"></mat-datepicker-toggle>
      <mat-datepicker #birthdatePicker></mat-datepicker> -->
    </mat-form-field>
    <mat-form-field appearance="outline" class="referral-form-field">
      <mat-label>Zip Code</mat-label>
      <input matInput #postalCode maxlength="5" formControlName="zipCode" #zipCode required />
      <mat-hint> * Required </mat-hint>
      <mat-error [hidden]="zipCode.valid"> Patient's 5-digit zip code is required. </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="referral-form-field">
      <mat-label>Phone Number</mat-label>
      <input
        type="tel"
        matInput
        placeholder="(555) 555-1234"
        formControlName="phoneNumber"
        #phoneNumber
        required
        mask="(000) 000-0000"
      />
      <mat-hint> * Required </mat-hint>
      <mat-error [hidden]="phoneNumber.valid">
        Phone number must be in format '(XXX) XXX-XXXX' and is required.
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="referral-form-field">
      <mat-label>Email</mat-label>
      <input type="email" matInput formControlName="patientEmailAddress" #patientEmailAddress />
      <mat-error [hidden]="patientEmailAddress.valid">
        Please enter a valid email address.
      </mat-error>
    </mat-form-field>

    <h3>Patient Preferences</h3>

    <mat-form-field appearance="outline" class="referral-form-field">
      <mat-label>Location</mat-label>
      <mat-select formControlName="location" #location>
        <mat-option value="none">No Preference</mat-option>
        <mat-option
          *ngFor="let location of locations"
          [value]="location.locationName"
          title="{{ location.practiceName }}: {{ location.practiceName }} {{
            location.locationName
          }} {{ location.buildingAddress }}"
        >
          <strong>{{ location.practiceName }}</strong
          >: {{ location.locationName }} {{ location.buildingAddress }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="referral-form-field">
      <mat-label>Availability</mat-label>
      <mat-select formControlName="schedule">
        <mat-option *ngFor="let scheduleType of scheduleTypes" [value]="scheduleType.value">
          {{ scheduleType.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <h3>Physician Orders</h3>
    <mat-form-field appearance="outline" class="referral-form-field">
      <mat-label>Appointment Type</mat-label>
      <mat-select formControlName="appointment" #appointment required>
        <mat-option
          *ngFor="let appointmentType of appointmentTypes"
          [value]="appointmentType.value"
        >
          {{ appointmentType.label }}
        </mat-option>
      </mat-select>
      <mat-hint> * Required </mat-hint>
      <mat-error [hidden]="appointment.valid"> Appointment type is required. </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="referral-form-field">
      <mat-label>Refer to</mat-label>
      <mat-select formControlName="referTo">
        <mat-option *ngFor="let referralType of referralTypes" [value]="referralType.value">
          {{ referralType.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <h3>Instructions</h3>
    <mat-form-field appearance="outline" class="referral-form-field">
      <mat-label>Describe the patient's needs</mat-label>
      <textarea matInput formControlName="patientNeedDescription"></textarea>
    </mat-form-field>

    <h3>Referring Physician Information</h3>

    <mat-form-field appearance="outline" class="referral-form-field">
      <mat-label>Physician Name</mat-label>
      <input required matInput formControlName="referringPhysicianName" />
      <mat-hint> * Required </mat-hint>
      <mat-error [hidden]="firstName.valid"> Physician's name is required. </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="referral-form-field">
      <mat-label>Phone Number</mat-label>
      <input
        type="tel"
        matInput
        placeholder="(555) 555-1234"
        formControlName="referringPhysicianPhone"
        mask="(000) 000-0000"
      />
      <mat-hint> * Required </mat-hint>
      <mat-error [hidden]="phoneNumber.valid">
        Phone number must be in format '(XXX) XXX-XXXX' and is required.
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="referral-form-field">
      <mat-label>Physician NPI</mat-label>
      <input matInput required maxlength="10" formControlName="referringPhysicianNPI" />
      <mat-hint> * Required </mat-hint>
      <mat-error [hidden]="phoneNumber.valid"> NPI must be valid and is required. </mat-error>
    </mat-form-field>
  </div>

  <re-captcha
    (resolved)="resolved($event)"
    (errored)="onError($event)"
    errorMode="handled"
  ></re-captcha>

  <div mat-dialog-actions class="dialog-actions">
    <button mat-button class="cancel-button" (click)="close()">Cancel</button>
    <button mat-button class="submit-button" (click)="submit()" [disabled]="!form.valid">
      Submit
    </button>
  </div>
</form>
