import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SearchResultsPageComponent } from './search-results-page/search-results-page.component';
import { ProviderDetailPageComponent } from './provider-detail-page/provider-detail-page.component';
import { PhysicianIndexPageComponent } from './physician-index-page/physician-index-page/physician-index-page.component';
import { PrintPageComponent } from './print-page/print-page.component';
import { ProviderCommentsPageComponent } from './provider-comments-page/provider-comments-page.component';
import { ComparePageComponent } from './compare-page/compare-page.component';
import { ReferralFormPageComponent } from './referral-form-page/referral-form-page.component';
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';

const routes: Routes = [
  {
    path: 'search-results/:search',
    component: SearchResultsPageComponent,
    data: {
      reuseRoute: true,
    },
  },
  {
    path: 'physician-index',
    component: PhysicianIndexPageComponent,
    data: {
      reuseRoute: true,
    },
  },
  {
    path: ':providername',
    component: ProviderDetailPageComponent,
    data: {
      reuseRoute: false,
    },
  },
  {
    path: 'print/:providername',
    component: PrintPageComponent,
    data: {
      reuseRoute: true,
    },
  },
  {
    path: ':providername/comments',
    component: ProviderCommentsPageComponent,
    data: {
      reuseRoute: true,
    },
  },
  {
    path: ':providername/referral-form',
    component: ReferralFormPageComponent,
    data: {
      reuseRoute: true,
    },
  },
  {
    path: ':providername/referral-form/thank-you',
    component: ThankYouPageComponent,
    data: {
      reuseRoute: true,
    },
  },
  {
    path: 'compare/:providers',
    component: ComparePageComponent,
    data: {
      reuseRoute: false,
    },
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
