import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintCredentialsComponent } from './print-credentials.component';

@NgModule({
  declarations: [PrintCredentialsComponent],
  imports: [CommonModule],
  exports: [PrintCredentialsComponent],
})
export class PrintCredentialsModule {}
