/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'atlas-provider-star-rating',
  templateUrl: './provider-star-rating.component.html',
  styleUrls: ['./provider-star-rating.component.scss'],
})
export class ProviderStarRatingComponent implements OnInit {
  @Input() ratingCount: any;
  @Input() ratingValue: any;

  checkZeroString: number;
  constructor() {}

  ngOnInit(): void {
    if (this.ratingValue) {
      this.checkZeroString = parseFloat(this.ratingValue);
    }
  }
}
