import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'childFilterTransform' })
export class ChildFilterTransformPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'lgbtqaligned':
        return 'Are a LGBTQIA+ Champion';
      case 'youtubevideobio':
        return 'Have a Video Bio';
      case 'Employed':
        return 'Work for AHN';
      case 'Male':
        return 'Male';
      case 'Female':
        return 'Female';
      case 'schedulable':
        return 'Schedule Online';
      case 'outPatientEnabled':
        return 'Schedule by Phone';
      case 'videovisitenabled':
        return 'Video Appointments';
      default:
        return 'default';
    }
  }
}
