export class PhysicianLetterModel {
  lastNameLetter: string;
  name: string;
  url: string;
  profileName: string;

  constructor(letter: string, name: string, url: string) {
    this.lastNameLetter = letter;
    this.name = name;
    this.url = url;
    this.profileName = url.substring(url.lastIndexOf('/') + 1);
  }
}
