<div class="container">
  <div>
    <h1>No results</h1>
  </div>
  <div>
    <h3>We're sorry, we couldn't find any results based on your search.</h3>
  </div>
  <div>
    <p>Suggestions:</p>
    <ul>
      <li>Check for any misspellings</li>
      <li>Try adjusting your search filters</li>
      <li>Use the search bar above to begin a new search</li>
    </ul>
  </div>
</div>
