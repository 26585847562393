<section class="ahn-fad-header-mobile">
  <div class="navbar-wrapper">
    <div class="container-mobile">
      <div class="nav-top d-flex justify-content-between align-items-center">
        <div class="logo">
          <a href="https://www.ahn.org" aria-label="Allegheny Health Network">
            <img
              src="../../../../assets/AHNlogo_SVG_fixedsize.svg"
              alt="Allegheny Health Network"
              width="117px"
              height="38px"
            />
          </a>
        </div>
        <div class="top-link-list">
          <ul class="d-flex p-0 m-0">
            <li class="top-nav-link">
              <a (click)="onViewFullDirectory()" routerLink="/physician-index">
                Provider Directory
              </a>
            </li>
            <li class="top-nav-link">
              <a href="https://mychart.ahn.org/" target="_blank"> MyChart </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
