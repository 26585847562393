<div class="star-rating-container">
  <div *ngIf="checkZeroString !== 0">
    <span class="material-icons-outlined">
      {{ ratingValue | starRating: ratingValue }}
    </span>
    <span class="rating-section">
      <span class="rating-value">{{ checkZeroString }}</span>
      <span>{{ ' (' + ratingCount + ')' }}</span>
    </span>
  </div>
</div>
