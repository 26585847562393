import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompareDistanceComponent } from './compare-distance.component';

@NgModule({
  declarations: [CompareDistanceComponent],
  imports: [CommonModule],
  exports: [CompareDistanceComponent],
})
export class CompareDistanceModule {}
