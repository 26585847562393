import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderCommentsPageComponent } from './provider-comments-page.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NoResultsModule, SearchModule } from '@dmx-fad-ui/fad/components';
import { HtmlPipeModule } from '../../pipes/html.pipe.module';
import { AhnTextModule } from '@dmx-fad-ui/fad/components';

@NgModule({
  declarations: [ProviderCommentsPageComponent],
  exports: [ProviderCommentsPageComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    NoResultsModule,
    SearchModule,
    HtmlPipeModule,
    AhnTextModule,
  ],
})
export class ProviderCommentsPageModule {}
