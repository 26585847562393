import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FadSeachStateService } from '@dmx-fad-ui/fad/data-access';

@Component({
  selector: 'atlas-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent {
  constructor(
    private router: Router,
    private fadSeachStateService: FadSeachStateService,
  ) {}

  onViewFullDirectory() {
    if (window.location.pathname.includes('search-results')) {
      this.fadSeachStateService.scrollY = 0;
    }
    this.router.navigate(['physician-index']);
  }
}
