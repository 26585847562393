import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-no-scheduling-text',
  templateUrl: './no-scheduling-text.component.html',
  styleUrls: ['./no-scheduling-text.component.scss'],
})
export class NoSchedulingTextComponent {
  @Input() colorBlue: boolean;
  constructor() {}
}
