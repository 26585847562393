import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesRoutingModule } from './pages-routing.module';
import { BasePageModule } from '@dmx-fad-ui/fad/pages';
import { SearchResultsPageModule } from '@dmx-fad-ui/fad/pages';
import { LandingPageModule } from '@dmx-fad-ui/fad/pages';
import { PhysicianIndexPageModule } from '@dmx-fad-ui/fad/pages';
import { ProviderDetailPageModule } from '@dmx-fad-ui/fad/pages';
import { PrintPageModule } from '@dmx-fad-ui/fad/pages';
import { ProviderCommentsPageModule } from '@dmx-fad-ui/fad/pages';

import { ComparePageModule } from './compare-page/compare-page.module';
import { ReferralFormPageModule } from './referral-form-page/referral-form-page.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PagesRoutingModule,
    BasePageModule,
    SearchResultsPageModule,
    LandingPageModule,
    PhysicianIndexPageModule,
    ProviderDetailPageModule,
    PrintPageModule,
    ProviderCommentsPageModule,
    ComparePageModule,
    ReferralFormPageModule,
  ],
})
export class PagesModule {}
