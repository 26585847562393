import { ProviderPracticeBuilding } from './provider-practice-building/provider-practice-building';

export class ProviderResult {
  id: string;
  displayName: string; // the plain text name
  profileName: string; // the hyphenated name
  conditions: string[];
  signatureSpecialty: string;
  conditionSpecialties: string[];
  providerPracticeBuildings: ProviderPracticeBuilding[];
  ratingValue: string;
  ratingCount: string;
  bio: string;
  schedulable: string;
  npi: string;
  providerImage: string;
  visitTypes: string[];
  employed: string;
  outPatientEnabled?: boolean;
  pracBuildingPracticeName: string;
  pracBuildingLocationName: string;
  facilityTypes: string[];
  serviceNames: string[];
  practiceName: string;
  operationHours: string[];
  isPractiseLocation: boolean;
  buildingSpecialties: string[];
  practiceID: string;
  practiceBuildingID: string;
}
