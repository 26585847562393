import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewGenderFilterComponent } from './new-gender-filter.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [NewGenderFilterComponent],
  exports: [NewGenderFilterComponent],
  imports: [CommonModule, MatRadioModule, FormsModule, ReactiveFormsModule],
})
export class NewGenderFilterComponentModule {}
