/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input, OnInit } from '@angular/core';
import { ProviderResult } from './provider-result';
import { ProviderPracticeBuilding } from './provider-practice-building/provider-practice-building';
import { FadSeachStateService } from '@dmx-fad-ui/fad/data-access';
import { UrlMonitorService } from '../../services/url-monitor/url-monitor.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'atlas-provider-result',
  templateUrl: './provider-result.component.html',
  styleUrls: ['./provider-result.component.scss'],
})
export class ProviderResultComponent implements OnInit {
  @Input() providerResult: ProviderResult;
  @Input() userAdded: string;
  @Input() indexFromResultList: number;
  @Input() providerResultCount: number;
  providerId: string;
  displayName: string;
  profileName: string;
  conditionsDisplay: string = '';
  conditionsSpecialties: string[];
  conditionSpecialtiesDisplay: string = '';
  signatureSpecialty: string;
  practiceBuildings: string[];
  providerPracticeBuildings: ProviderPracticeBuilding[] = [];
  primaryPracticeDistance: string = ''; //for the compare-page distance row
  ratingCount: string = '';
  ratingValue: string = '';
  bio: string = '';
  schedulable: string = '';
  providerNPI: string = '';
  visitTypes: string[];
  providerImage: string = '';
  employed: boolean = false;
  outPatientEnabled: boolean = false;

  isPractiseLocation: boolean;
  pracBuildingPracticeName: string;
  pracBuildingLocationName: string;
  facilityTypes: string[];
  serviceNameDisplay: string = '';
  practiceName: string;
  operationHours: string[];
  serviceDisplay: string;
  buildingSpecialties: string[];
  pracBuildingID: string;
  practiceID: string;
  isInit: boolean = false;
  isScrolledAlready: boolean = false;

  urlArray = [];
  urlMonitorSub: Subscription;
  isShowSearchResultsIndexNumbers: boolean = environment.debugTools.showSearchResultIndexNumbers;

  constructor(
    private fadSeachStateServ: FadSeachStateService,
    private urlMonitorService: UrlMonitorService,
  ) {
    this.fadSeachStateServ = fadSeachStateServ;
    this.urlMonitorService = urlMonitorService;
  }

  fetchPrimaryPracticeInfo(building: ProviderPracticeBuilding) {
    if (building.primaryPractice === 'true' && building.distance !== 'undefined') {
      this.primaryPracticeDistance = building.distance;
    }
  }

  ngOnInit(): void {
    if (this.providerResult) {
      this.isPractiseLocation = this.providerResult.isPractiseLocation;
      this.buildingSpecialties = this.providerResult.buildingSpecialties;
      this.signatureSpecialty = this.providerResult.signatureSpecialty;

      if (this.providerResult.conditions) {
        this.providerResult.conditions.forEach((cond) => {
          this.conditionsDisplay += ` ${cond},`;
        });
      }

      this.employed = this.providerResult.employed == 'Employed' ? true : false;

      this.conditionsDisplay = this.conditionsDisplay.trim();
      this.conditionsDisplay = this.conditionsDisplay.slice(0, -1);

      if (this.providerResult.providerPracticeBuildings) {
        this.providerResult.providerPracticeBuildings.forEach((practiceBuilding) => {
          this.providerPracticeBuildings.push(practiceBuilding);
          this.fetchPrimaryPracticeInfo(practiceBuilding);
        });
      }
      this.ratingCount = this.providerResult.ratingCount;

      this.ratingValue = this.providerResult.ratingValue;

      this.bio = this.providerResult.bio;

      this.displayName = this.providerResult.displayName;

      this.profileName = this.providerResult.profileName;

      this.providerId = this.providerResult.id;

      this.schedulable = this.providerResult.schedulable;

      this.providerNPI = this.providerResult.npi;

      this.providerImage = this.providerResult.providerImage;

      this.visitTypes = this.providerResult.visitTypes;

      this.outPatientEnabled = this.providerResult.outPatientEnabled;

      this.pracBuildingPracticeName = this.providerResult.pracBuildingPracticeName;

      if (this.providerResult.serviceNames) {
        this.providerResult.serviceNames.forEach((servicename) => {
          this.serviceNameDisplay += ` ${servicename},`;
        });
        this.serviceNameDisplay = this.serviceNameDisplay.trim();
        this.serviceDisplay = this.serviceNameDisplay.slice(0, -1);
      }

      this.pracBuildingLocationName = this.providerResult.pracBuildingLocationName;
      this.pracBuildingID = this.providerResult.practiceBuildingID;
      this.practiceID = this.providerResult.practiceID;
      this.practiceName = this.providerResult.practiceName;
    }

    this.urlMonitorSub = this.urlMonitorService.urlMonitorSubj.subscribe((navEvents) => {
      this.urlArray = [];
      navEvents.forEach((item) => {
        if (item) {
          const textUrl = item.url;
          const isHashPresentUrl = textUrl.includes('#');
          if (isHashPresentUrl === false) {
            this.urlArray.push(item);
          }
        }
      });
    });
  }
}
