import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferralButtonComponent } from './referral-button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ReferralButtonComponent],
  imports: [CommonModule, MatDialogModule, RouterModule],
  exports: [ReferralButtonComponent],
})
export class ReferralButtonModule {}
