/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FadProviderDetailRequest,
  FadProviderDetailService,
  FadSeachStateService,
} from '@dmx-fad-ui/fad/data-access';
import { SimpleUtilsService } from '../../services/utilities/simple-utils.service';

@Component({
  selector: 'atlas-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss'],
})
export class ThankYouPageComponent implements OnInit, OnDestroy {
  providerName: string;
  providerDetailSub: any;
  providerDetail: any;
  provider: any;
  hasData: boolean = false;
  constructor(
    private fadStateServ: FadSeachStateService,
    private utilsServ: SimpleUtilsService,
    private activatedRoute: ActivatedRoute,
    private fadProvDetServ: FadProviderDetailService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.providerName = params.get('providername');
    });

    const provDetReq: FadProviderDetailRequest = this.utilsServ.createFadProviderDetailRequest(
      this.providerName,
    );

    this.subscribeToProviderDetail();
    this.fadProvDetServ.getProviderDetail(provDetReq);
  }

  subscribeToProviderDetail() {
    this.providerDetailSub = this.fadProvDetServ.providerDetailSubj.subscribe((provDetail) => {
      if (provDetail) {
        this.providerDetail = provDetail;
        this.provider = this.providerDetail.payload.providerDetail;
        this.hasData = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.providerDetailSub.unsubscribe();
  }
}
