/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { PickedProvider } from '../../../models/pickedProvider/pickedProvider';
import { WindowSizeService } from '@dmx-fad-ui/fad/services';
import { FadSeachStateService } from '@dmx-fad-ui/fad/data-access';

@Component({
  selector: 'atlas-provider-info',
  templateUrl: './provider-info.component.html',
  styleUrls: ['./provider-info.component.scss'],
})
export class ProviderInfoComponent implements OnInit, OnDestroy {
  @Input() providerId: string;
  @Input() signatureSpecialty: string;
  @Input() displayName: string; // for plain text
  @Input() profileName: string; //for navigation
  @Input() conditionsDisplay: string = '';
  @Input() ratingValue: string;
  @Input() ratingCount: string;
  @Input() providerNPI: string;
  @Input() providerImage: string = '';
  @Input() employed: boolean;
  @Input() isPractiseLocation: boolean;
  @Input() pracBuildingPracticeName: string;
  @Input() buildingSpecialties: string[];
  @Input() pracBuildingID: string;
  @Input() practiceID: string;
  @Input() practiceName: string;
  @Input() distance: string = '';

  seeAll = true;
  conditionsText = '';
  maxCharacterCount = 70;
  viewPortWidth = 0;
  pickedProvider: PickedProvider;
  isSquare: boolean = false;

  private readonly viewportChange = this.viewportRuler
    .change(200)
    .subscribe(() => this.ngZone.run(() => this.setMaxWords()));

  constructor(
    private router: Router,
    private readonly viewportRuler: ViewportRuler,
    private readonly ngZone: NgZone,
    private windowService: WindowSizeService,
    private fadSeachStateServ: FadSeachStateService,
  ) {
    this.setMaxWords();
    this.fadSeachStateServ = fadSeachStateServ;
  }

  ngOnInit(): void {
    this.getConditionsText();
    this.pickedProvider = new PickedProvider(
      this.providerId,
      this.signatureSpecialty,
      this.displayName,
      this.ratingValue,
      this.ratingCount,
    );

    if (this.providerImage) {
      this.isSquare = this.providerImage.indexOf('Square') > -1 ? true : false;
    }
  }

  ngOnDestroy() {
    this.viewportChange.unsubscribe();
  }

  seeMore() {
    this.seeAll = !this.seeAll;
    this.getConditionsText();
  }

  getConditionsText() {
    if (this.seeAll) {
      if (this.conditionsDisplay.length >= this.maxCharacterCount) {
        this.conditionsText = this.conditionsDisplay.substring(
          0,
          this.conditionsDisplay.substring(0, this.maxCharacterCount).lastIndexOf(' '),
        );
        if (!this.conditionsText.endsWith(',')) {
          //if breaking mid-word OR mid-term, then append ellipsis
          this.conditionsText = this.conditionsText + '...';
        }
      } else {
        this.conditionsText = this.conditionsDisplay;
      }
    } else {
      this.conditionsText = this.conditionsDisplay;
    }
  }

  redirectToProviderPage() {
    const Y = window.scrollY;
    this.fadSeachStateServ.scrollY = Y;

    const scrollPosition = window.parent.scrollY;
    this.windowService.windowScroolPosition.next(scrollPosition);
    this.router.navigate([this.profileName]);
  }

  setMaxWords() {
    const { width, height } = this.viewportRuler.getViewportSize();
    this.viewPortWidth = width;
    if (this.viewPortWidth >= 1360) {
      this.maxCharacterCount = 70;
    }
    // else if(width >=992){
    //   this.maxCharacterCount = 50;
    // }
    else {
      this.maxCharacterCount = 30;
    }
    this.getConditionsText();
  }

  redirectToLocationDetailPage() {
    let locationName = this.pracBuildingPracticeName;
    locationName = locationName.replace(/\s+/g, '-').toLowerCase();
    this.router.navigate([
      'location/' + `${locationName}/` + `${this.pracBuildingID}-${this.practiceID}`,
    ]);
  }

  updateScrollY() {
    const Y = window.scrollY;
    this.fadSeachStateServ.scrollY = Y;
  }
}
