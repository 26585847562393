/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { WindowSizeService } from '../../services/windowSize/windowSize.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'atlas-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  windowWidthSub: Subscription;
  isDesktop: boolean = false;
  ribbonText: string = '';
  constructor(private windowSizeServ: WindowSizeService) {}

  ngOnInit() {
    this.subToWindowWidthSubj();
    this.ribbonText = environment.ribbonText;
  }

  subToWindowWidthSubj() {
    this.windowWidthSub = this.windowSizeServ.windowWidthSubj
      .pipe(debounceTime(5))
      .subscribe((width) => {
        this.isDesktop = this.windowSizeServ.convertWindowWidthToIsDesktop(width);
      });
  }
}
