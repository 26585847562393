export class CredentialsCompare {
  boardsList: string[];
  fellowshipsList: string[];
  jobTitlesList: string[];
  residenciesList: string[];
  schoolsList: string[];

  NPI: string;

  constructor(
    boardsList: string[],
    fellowshipsList: string[],
    jobTitlesList: string[],
    residenciesList: string[],
    schoolsList: string[],
    npi: string,
  ) {
    this.boardsList = boardsList;
    this.fellowshipsList = fellowshipsList;
    this.jobTitlesList = jobTitlesList;
    this.residenciesList = residenciesList;
    this.schoolsList = schoolsList;
    this.NPI = npi;
  }
}
