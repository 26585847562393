// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class FilterService {
  clearAllFiltersSubj = new Subject<boolean>();
  genderFilterSubj = new BehaviorSubject<string>('Any Gender');
  providerLocationFilterSubj = new BehaviorSubject<string>('All Results');
  conditionFilterSubj = new BehaviorSubject<string>('');
  videoBioSubj = new BehaviorSubject<boolean>(false);
  makeAppointmentSubj = new BehaviorSubject<boolean>(false);
  LGBTQQIP2SAASubj = new BehaviorSubject<boolean>(false);
  ahnProviderSubj = new BehaviorSubject<boolean>(false);
  onlineSchedulingSubj = new BehaviorSubject<boolean>(false);
  videoAppointmentSubj = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locationFilterSubj = new BehaviorSubject<any[]>(['', 0]);
  plainSearchTextSubj = new BehaviorSubject<string>('');
  nearestToMeDisabled = new BehaviorSubject<boolean>(true);
  lanaguageFilterSubj = new BehaviorSubject<string>('');
  totalResultsCountSubj = new BehaviorSubject<number>(10);
  constructor() {}
}
