<div id="provider-comments-page" class="persistent-bar">
  <atlas-search
    [isLandingPage]="false"
    [isProviderDetailPage]="true"
    [isSearchResultsPage]="false"
    [shouldAutoFocus]="false"
  ></atlas-search>
</div>

<div class="provider-detail-comments container">
  <div class="provider-return">
    <!-- <div
      class="universal-pointer"
      (click)="onReturnToProvider()"
      (keydown.enter)="onReturnToProvider()"
    >
      <span class="material-icons green-icon">
        keyboard_arrow_left
      </span>
      <span class="return-to-search">Return to Provider</span>
    </div>-->

    <atlas-ahn-text #ahntext [indexPage]="false"></atlas-ahn-text>
  </div>
  <div class="comments-main-container">
    <div class="comments-title">
      <h1>Patient Comments</h1>
    </div>
    <div class="comments-filter">
      <div *ngFor="let filterButton of filterHistogram | keyvalue">
        <div
          [ngClass]="
            commentFilter === filterButton.key
              ? 'selected-comment-filter comment-filter-button'
              : 'comment-filter-button'
          "
          *ngIf="filterButton.value !== 0"
          (click)="selectCommentFilter(filterButton.key)"
        >
          <p class="comment-button-text">{{ filterButton.key }} ({{ filterButton.value }})</p>
        </div>
      </div>
    </div>
    <div class="comments-section">
      <div *ngFor="let comment of providerComments">
        <div
          *ngIf="commentFilter === '' || comment.commentText.toLowerCase().includes(commentFilter)"
        >
          <p class="roboto-regular">"{{ comment.commentText | specialPipe }}"</p>
          <p class="comment-date roboto-bold">{{ comment.commentDate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
