import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompareProviderCheckboxComponent } from './compare-provider-checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [CompareProviderCheckboxComponent],
  exports: [CompareProviderCheckboxComponent],
  imports: [CommonModule, MatCheckboxModule],
})
export class CompareProviderCheckboxModule {}
