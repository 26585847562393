import { AfterViewChecked, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'atlas-print-provider-image',
  templateUrl: './print-provider-image.component.html',
  styleUrls: ['./print-provider-image.component.scss'],
})
export class PrintProviderImageComponent implements AfterViewChecked {
  @Input() imgSrc: string = '';
  @Output() isContentLoadedEmitter = new EventEmitter<boolean>();

  constructor() {}

  ngAfterViewChecked(): void {
    this.isContentLoadedEmitter.emit(true);
  }
}
