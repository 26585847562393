<div id="phys-index-page" class="container phys-index-page">
  <div id="top" class="return-to-text">
    <atlas-ahn-text></atlas-ahn-text>
  </div>
  <div class="nav-wrapper">
    <a
      *ngFor="let letter of alphabet"
      class="selector-background"
      href="/physician-index#section-{{ letter }}"
    >
      {{ letter }}
    </a>
  </div>
  <!-- BEGIN DESKTOP VIEW -->
  <div class="desktop-view-doctors" *ngIf="hasPhysicians">
    <atlas-desktop-phys-column
      *ngFor="let letter of alphabet"
      [physiciansList]="physiciansList"
      [letter]="letter"
    ></atlas-desktop-phys-column>
  </div>
  <!-- END DESKTOP VIEW -->
</div>
