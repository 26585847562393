/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { FilterService, ClearFilterService } from '@dmx-fad-ui/fad/services';
import { ChildOptionChange } from '../filter-child/childOptionChange';

@Component({
  selector: 'atlas-new-gender-filter',
  templateUrl: './new-gender-filter.component.html',
  styleUrls: ['./new-gender-filter.component.scss'],
})
export class NewGenderFilterComponent implements OnInit {
  genderOptions: string[] = ['Any Gender', 'Male', 'Female'];
  selectedGender: string = 'Any Gender';
  isChecked: boolean = false;
  @Output() checkBoxEmitter = new EventEmitter<ChildOptionChange>();
  onlineSchedulingSubj: boolean;
  videoBioSubj: boolean;
  ahnProviderSubj: boolean;
  LGBTQQIP2SAASubj: boolean;
  genderFilterSubj: string;

  constructor(
    private filterServ: FilterService,
    private clearFilterService: ClearFilterService,
  ) {}

  ngOnInit(): void {
    this.filterServ.genderFilterSubj.subscribe((data) => {
      this.selectedGender = data;
    });
  }

  radioGenderChange(event) {
    const childOption = new ChildOptionChange('Provider Gender', true, event.value);
    this.checkBoxEmitter.next(childOption);
    this.filterServ.genderFilterSubj.next(event.value);
    this.getAllFiltersSubscription();
  }

  getAllFiltersSubscription() {
    this.filterServ.onlineSchedulingSubj.subscribe((data) => {
      this.onlineSchedulingSubj = data;
    });
    this.filterServ.videoBioSubj.subscribe((data) => {
      this.videoBioSubj = data;
    });
    this.filterServ.ahnProviderSubj.subscribe((data) => {
      this.ahnProviderSubj = data;
    });
    this.filterServ.LGBTQQIP2SAASubj.subscribe((data) => {
      this.LGBTQQIP2SAASubj = data;
    });
    this.filterServ.genderFilterSubj.subscribe((data) => {
      this.genderFilterSubj = data;
    });

    //Gender State will be undefined intially, so make it false
    if (this.genderFilterSubj == undefined) {
      this.genderFilterSubj = 'Any Gender';
    }

    if (
      this.onlineSchedulingSubj == false &&
      this.videoBioSubj == false &&
      this.ahnProviderSubj == false &&
      this.LGBTQQIP2SAASubj == false &&
      this.genderFilterSubj == 'Any Gender'
    ) {
      //nothing is selected
      this.clearFilterService.isAllCheckBoxState.next(false);
    } else {
      //something is selected
      this.clearFilterService.isAllCheckBoxState.next(true);
    }
  }
}
