/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompareProviderModel, CompareProviderService } from '@dmx-fad-ui/fad/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-compare-provider-drawer',
  templateUrl: './compare-provider-drawer.component.html',
  styleUrls: ['./compare-provider-drawer.component.scss'],
})
export class CompareProviderDrawerComponent implements OnInit, OnDestroy {
  removeCompareProvidersSubscription = new Subscription();
  addCompareProvidersSubscription = new Subscription();

  panelOpenState: boolean = true;
  iscomparePanelVisible: boolean = true;
  compareProviders: CompareProviderModel[];
  compareProviderCount = 0;

  constructor(
    private compareProviderService: CompareProviderService,
    private router: Router,
  ) {
    this.compareProviderService = compareProviderService;
    this.router = router;
  }

  ngOnInit(): void {
    this.removeCompareProvidersSubscription = this.compareProviderService.removeFromList$.subscribe(
      () => this.removeProviderCompare(),
    );
    this.addCompareProvidersSubscription = this.compareProviderService.addToList$.subscribe(() =>
      this.addProviderCompare(),
    );

    this.compareProviders = this.compareProviderService.compareProviders;
    this.compareProviderCount = this.compareProviderService.checkboxCount;

    if (this.compareProviders[0].profileName === '') {
      this.iscomparePanelVisible = false;
    }
  }

  ngOnDestroy(): void {
    this.removeCompareProvidersSubscription.unsubscribe();
    this.addCompareProvidersSubscription.unsubscribe();
  }

  compareSelected() {
    if (this.compareProviderCount > 1) {
      this.router.navigate(['compare/providers'], {
        queryParams: this.compareProviderService.buildCompareQueryParams(),
      });
    }
  }

  clearAllCompares() {
    this.panelOpenState = false;
    this.compareProviders = [
      { profileName: '', npi: '', specialty: '', displayName: '' },
      { profileName: '', npi: '', specialty: '', displayName: '' },
      { profileName: '', npi: '', specialty: '', displayName: '' },
      { profileName: '', npi: '', specialty: '', displayName: '' },
    ];
    this.iscomparePanelVisible = false;
    this.compareProviderService.checkboxCount = 0;
    this.compareProviderCount = this.compareProviderService.checkboxCount;
    this.compareProviderService.clearAllCompares(this.compareProviders);
    this.compareProviderService.disableCompare(false);
  }

  removeProviderCompare(profileName?: string) {
    if (
      this.compareProviderService.checkboxCount >= 1 &&
      this.compareProviderService.checkboxCount <= 4
    ) {
      this.compareProviderCount = this.compareProviderService.checkboxCount;
      this.compareProviderService.removeFromList(profileName);
      if (this.compareProviderCount < 4) {
        this.compareProviderService.disableCompare(false);
      }
    } else {
      this.clearAllCompares();
    }
  }

  addProviderCompare() {
    if (
      this.compareProviderService.checkboxCount < 4 &&
      this.compareProviderService.checkboxCount > 0
    ) {
      this.compareProviderCount = this.compareProviderService.checkboxCount;
      this.compareProviders = this.compareProviderService.compareProviders;
      this.iscomparePanelVisible = true;
      this.panelOpenState = true;
    }
  }
}
