import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FadSeachStateService } from '@dmx-fad-ui/fad/data-access';
import { TabableService } from '@dmx-fad-ui/fad/services';

@Component({
  selector: 'atlas-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss'],
})
export class DesktopHeaderComponent {
  pageName: string;
  @ViewChild('myChart') myChart: ElementRef;

  constructor(
    private router: Router,
    private tabableService: TabableService,
    private fadSeachStateService: FadSeachStateService,
  ) {
    this.router = router;
    this.tabableService = tabableService;
  }

  onViewFullDirectory() {
    if (window.location.pathname.includes('search-results')) {
      this.fadSeachStateService.scrollY = 0;
    }
    this.router.navigate(['physician-index']);
  }

  refocus() {
    if (window.location.pathname.includes('search-results')) {
      this.tabableService.filterContent();
    } else {
      this.tabableService.mainContent();
    }
    return false;
  }
}
