import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderExpertiseComponent } from './provider-expertise.component';

@NgModule({
  declarations: [ProviderExpertiseComponent],
  exports: [ProviderExpertiseComponent],
  imports: [CommonModule],
})
export class ProviderExpertiseModule {}
