export class PickedProvider {
  displayName: string;
  ratingValue: string;
  expertise?: string[];
  practiceBuildings?: string[];
  ratingCount: string;
  providerId: string;
  bio?: string;
  signatureSpecialty: string;

  constructor(
    providerId: string,
    signatureSpecialty: string,
    displayName: string,
    ratingValue: string,
    ratingCount: string,
  ) {
    this.providerId = providerId;
    this.signatureSpecialty = signatureSpecialty;
    this.displayName = displayName;
    this.ratingValue = ratingValue;
    this.ratingCount = ratingCount;

    //TODO account for expertise array
  }
}
