export class ChildOptionChange {
  isChecked: boolean;
  optionName: string;
  category: string;

  constructor(category: string, isChecked: boolean, optionName: string) {
    this.category = category;
    this.isChecked = isChecked;
    this.optionName = optionName;
  }
}
