import { Component, Input } from '@angular/core';
import { CompareProviderService } from '../../../services/compare-provider/compare-provider.service';
import { Router } from '@angular/router';

@Component({
  selector: 'atlas-compare-prov-name',
  templateUrl: './compare-prov-name.component.html',
  styleUrls: [
    './compare-prov-name.component.scss',
    '../../../pages/compare-page/compare-page.component.scss',
  ],
})
export class CompareProvNameComponent {
  @Input() displayName: string;
  @Input() profileName: string;
  @Input() imageUrl: string;
  @Input() signature: string;
  @Input() ratingCount: string;
  @Input() ratingValue: string;

  constructor(
    private compareProviderService: CompareProviderService,
    private router: Router,
  ) {
    this.compareProviderService = compareProviderService;
  }

  removeProviderCompare(profileName: string) {
    this.compareProviderService.removeFromList(profileName);
  }

  returnToProviderProfile(profileName: string) {
    this.router.navigate([profileName]);
  }
}
