import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CompareProviderModel, CompareProviderService } from '@dmx-fad-ui/fad/services';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'atlas-compare-provider-checkbox',
  templateUrl: './compare-provider-checkbox.component.html',
  styleUrls: ['./compare-provider-checkbox.component.scss'],
})
export class CompareProviderCheckboxComponent implements OnInit, OnDestroy {
  @Input() displayName: string;
  @Input() profileName: string;
  @Input() signatureSpecialty: string;
  @Input() providerNPI: string;
  @Input() distance: string = '';
  isHidden: boolean = false;
  isChecked: boolean = false;
  selectedColor: string = 'primary';
  isDisabled: boolean = false;
  maxCompareSubscription: Subscription;
  clearCheckboxSubscription: Subscription;
  removeListSubscription: Subscription;
  comparedProvider: CompareProviderModel;

  constructor(private compareProviderService: CompareProviderService) {
    this.compareProviderService = compareProviderService;
  }

  ngOnInit(): void {
    if (environment.compares) {
      this.maxCompareSubscription = this.compareProviderService.compareDisabled$.subscribe(
        (disableAllUnchecked) => {
          this.disableCheckbox(disableAllUnchecked);
        },
      );
      this.clearCheckboxSubscription = this.compareProviderService.clearAllCompares$.subscribe(
        (x) => this.uncheckCheckbox(x),
      );
      this.removeListSubscription = this.compareProviderService.removeFromList$.subscribe((x) =>
        this.uncheckCheckbox(x),
      );

      const provIndex = this.compareProviderService.compareProviders.findIndex(
        (x) => x.profileName === this.profileName,
      );

      if (provIndex != -1) {
        this.isChecked = !this.isChecked;
        this.isDisabled = false;
        if (this.distance != '') {
          this.compareProviderService.compareProviders[provIndex].distance = this.distance;
        }
      }
    } else {
      this.isHidden = true;
    }
  }

  ngOnDestroy(): void {
    if (environment.compares) {
      this.clearCheckboxSubscription.unsubscribe();
      this.removeListSubscription.unsubscribe();
      this.maxCompareSubscription.unsubscribe();
    }
  }
  compareProviderCheckbox(): void {
    this.isChecked = !this.isChecked;

    if (this.isChecked && this.compareProviderService.checkboxCount < 4) {
      this.comparedProvider = new CompareProviderModel(
        this.profileName,
        this.displayName,
        this.signatureSpecialty,
        this.providerNPI,
        this.distance,
      );
      this.compareProviderService.addToList(this.comparedProvider);
      if (this.compareProviderService.checkboxCount > 3) {
        this.compareProviderService.disableCompare(true);
      }
    } else if (!this.isChecked && this.compareProviderService.checkboxCount != 0) {
      this.compareProviderService.removeFromList(this.profileName);
      if (this.compareProviderService.checkboxCount < 4) {
        this.compareProviderService.disableCompare(false);
      }
    }
  }

  disableCheckbox(disabled): void {
    if (disabled && this.isChecked) {
      this.isDisabled = false;
    } else if (disabled && !this.isChecked) {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
  }

  uncheckCheckbox(removedProviderName) {
    if (this.isChecked && this.profileName === removedProviderName) {
      this.isChecked = false;
    } else if (removedProviderName === 'CLEAR') {
      this.isChecked = false;
    }
  }
}
