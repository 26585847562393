import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChildFilterTransformPipe } from './childFilterTransform.pipe';

@NgModule({
  declarations: [ChildFilterTransformPipe],
  exports: [ChildFilterTransformPipe],
  imports: [CommonModule],
})
export class ChildFilterTransformPipeModule {}
