import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompareProviderDrawerComponent } from './compare-provider-drawer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [CompareProviderDrawerComponent],
  exports: [CompareProviderDrawerComponent],
  imports: [CommonModule, MatExpansionModule, MatButtonModule],
})
export class CompareProviderDrawerModule {}
