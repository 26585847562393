// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { FadSuggestRequest } from './fad-suggest-request';
import { FadSuggestResponse } from './fad-suggest-response';
import { environment } from '../../../../../../../../apps/dmx-fad-ui/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class FadSuggestService {
  suggestSubj = new Subject<FadSuggestResponse>();
  suggestSub = new Subscription();
  endpoint: string = `${environment.suggestEndpoint}`;
  constructor(private httpClient: HttpClient) {}

  suggest(request: FadSuggestRequest): void {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', `${environment.xApiKey}`);

    this.suggestSub = this.httpClient
      .post<FadSuggestResponse>(this.endpoint, request, {
        headers,
      })
      .pipe(
        map((resp) => {
          this.suggestSubj.next(resp);
          return resp;
        }),
      )
      .subscribe();
  }
}
