import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortBarComponent } from './sort-bar.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [SortBarComponent],
  imports: [CommonModule, MatSelectModule, MatFormFieldModule],
  exports: [SortBarComponent],
})
export class SortBarModule {}
