import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tooltipTransform' })
export class ToolTipTransformPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'Are a LGBTQIA+ Champion':
        return 'Come as you are.These providers are allies, advocates, and supporters — champions of compassionate, just, and inclusive care.';
      case 'Schedule Online':
        return 'View providers who have the option to schedule online to book your appointment.';
      case 'Schedule by Phone':
        return 'View providers who have a phone number you can call to schedule an appointment with them.';
      case 'Have a Video Bio':
        return 'Only view providers who have a video bio in their profile.';
      case 'Work for AHN':
        return 'Only view providers who are an AHN Provider.';
      default:
        return 'default';
    }
  }
}
