<div class="persistent-bar">
  <atlas-search
    [isLandingPage]="false"
    [isProviderDetailPage]="true"
    [isSearchResultsPage]="false"
    [shouldAutoFocus]="false"
  ></atlas-search>
</div>
<div class="thank-you-page">
  <div class="return-print-share">
    <atlas-ahn-text #ahntext [indexPage]="false"></atlas-ahn-text>
  </div>
  <div class="thank-you-page-container">
    <div class="thank-you-page-header">
      <h1>Referral Received</h1>
      <div class="">
        <p class="bold-first-paragraph">
          Our dedicated team of agents will contact your patient and expedite your orders within one
          business day. To check the status of your patient’s appointment or view their chart,
          please
          <a
            class="thank-you-link"
            target="_blank"
            href="https://epiccarelink.ahn.org/EpicCareLink/common/epic_login.asp"
            >log in to AHN’s EpicCare Link</a
          >
          web-based application.
        </p>
        <p>
          If you need assistance, please call us at 1-844-MD-REFER Monday through Friday 7AM-5PM.
        </p>
      </div>
    </div>
    <div class="green-line"></div>
    <div *ngIf="this.hasData">
      <atlas-referral-provider-card [provider]="this.provider"></atlas-referral-provider-card>
    </div>
  </div>
</div>
