import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompareLgbtComponent } from './compare-lgbt.component';

@NgModule({
  declarations: [CompareLgbtComponent],
  imports: [CommonModule],
  exports: [CompareLgbtComponent],
})
export class CompareLgbtModule {}
