/* eslint-disable @typescript-eslint/no-unused-vars */
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { NavigationEndEvent } from './modules/models/navigationEndEvent';
import { UrlMonitorService, SeoService } from './modules/services';
import { env } from 'process';

@Component({
  selector: 'atlas-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Find a Provider';
  key: string = environment.gMapsApiKey;
  currentRoute: string;
  routeSub: Subscription;
  urlArray: NavigationEndEvent[] = [];
  urlMonitorSub: Subscription;

  constructor(
    private router: Router,
    private urlMonitorServ: UrlMonitorService,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.loadGoogleMapApiKeys().then(() => {
        console.log('Google Maps Load Finished');
      });
      this.loadDynatrace().then(() => {
        console.log('Dynatrace Load Finished');
      });
    }
    this.subscribeToUrlMonitorSub();
    this.subscribeToRouterSub();
    this.seoService.addMainContent();
  }

  loadGoogleMapApiKeys() {
    // This was pulled from the index.html file and placed here to allow for dynamic api key assignment
    return new Promise((resolve, reject) => {
      const mapTemplate: string = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${this.key}&callback=Function.prototype`;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = mapTemplate;
      document.getElementsByTagName('head')[0].appendChild(script);
      resolve(null);
    });
  }

  loadDynatrace() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      const dtSource: string = environment.dtSource;
      script.type = 'text/javascript';
      script.src = dtSource;
      script.crossOrigin = `anonymous`;
      document.getElementsByTagName('head')[0].appendChild(script);
      resolve(null);
    });
  }

  subscribeToUrlMonitorSub() {
    this.urlMonitorSub = this.urlMonitorServ.urlMonitorSubj.subscribe((navEvents) => {
      this.urlArray = [];
      navEvents.forEach((item) => {
        if (item) {
          this.urlArray.push(item);
        }
      });
    });
  }

  subscribeToRouterSub() {
    this.routeSub = this.router.events.subscribe((event) => {
      let isDuplicateRouterEntry: boolean = false;
      if (event['routerEvent']) {
        // This solves a Router double-posting issue
        this.urlArray.forEach((presentUrl) => {
          if (event['routerEvent'].id === presentUrl.id) {
            isDuplicateRouterEntry = true;
          }
        });
        if (isDuplicateRouterEntry === false) {
          this.urlArray.push(event['routerEvent']);
          this.urlMonitorServ.urlMonitorSubj.next(this.urlArray);
        }
      }
    });
  }
}
