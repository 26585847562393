import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderLocationsComponent } from './provider-locations.component';
import { AhnTextModule } from '../../ahn-text/ahn-text.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ProviderLocationsComponent],
  exports: [ProviderLocationsComponent],
  imports: [CommonModule, AhnTextModule, RouterModule],
})
export class ProviderLocationsModule {}
