<div class="compare" [hidden]="!iscomparePanelVisible">
  <mat-expansion-panel
    showToggle
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="panelOpenState"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="filter-global-title filter-global-title-sub">Compare Providers</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="compare-cards">
      <div *ngFor="let result of compareProviders; let i = index">
        <div class="provider-compare-cards-data" *ngIf="result.profileName !== ''">
          <div class="provider-display-name sofia-pro-semi-bold">
            {{ result.displayName }}
          </div>
          <div class="signature-specialty-text">{{ result.specialty }}</div>
          <div
            class="remove-provider"
            (click)="removeProviderCompare(result.profileName)"
            (keydown.enter)="removeProviderCompare(result.profileName)"
          >
            REMOVE
          </div>
        </div>
        <div *ngIf="result.profileName === ''" class="provider-compare-cards-empty"></div>
      </div>
      <div class="compare-button-container">
        <div [className]="compareProviderCount > 1 ? 'show-compare' : 'disable-compare'">
          <button
            mat-button
            class="show-compare-button"
            (click)="compareSelected()"
            (keydown.enter)="compareSelected()"
          >
            COMPARE
          </button>
        </div>
        <div class="clear-font" (click)="clearAllCompares()" (keydown.enter)="clearAllCompares()">
          CLEAR ALL
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
