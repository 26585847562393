import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesktopPhysColumnComponent } from './desktop-phys-column.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [DesktopPhysColumnComponent],
  imports: [CommonModule, RouterModule],
  exports: [DesktopPhysColumnComponent],
})
export class DesktopPhysColumnModule {}
