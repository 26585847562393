import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintBioComponent } from './print-bio.component';

@NgModule({
  declarations: [PrintBioComponent],
  imports: [CommonModule],
  exports: [PrintBioComponent],
})
export class PrintBioModule {}
