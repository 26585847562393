export class CompareProviderModel {
  profileName: string;
  displayName: string;
  specialty: string;
  npi: string;
  distance?: string;

  constructor(
    profileName: string,
    displayName: string,
    specialty: string,
    npi: string,
    distance?: string,
  ) {
    this.profileName = profileName;
    this.displayName = displayName;
    this.specialty = specialty;
    this.npi = npi;
    this.distance = distance;
  }
}
