import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompareCredentialsComponent } from './compare-credentials.component';

@NgModule({
  declarations: [CompareCredentialsComponent],
  imports: [CommonModule],
  exports: [CompareCredentialsComponent],
})
export class CompareCredentialsModule {}
