<div>
  <div
    class="inline-container link-color universal-pointer"
    #mainContent
    (click)="onReturnToCare()"
    (keydown.enter)="onReturnToCare()"
    tabindex="0"
  >
    <div class="link-color">
      <span
        id="material-chevron-left"
        class="material-icons-outlined"
        style="font-size: 32px"
      ></span>
    </div>
    <span class="return-text"> {{ returnText }}</span>
  </div>
  <div *ngIf="indexPage === true" class="ahn-viewdirectory-toplinks">
    <h2>Health Care Provider Directory</h2>
  </div>
  <div *ngIf="indexPage === true">
    <p class="phys-index-description">
      Explore a complete A-Z listing of the physicians that are part of Allegheny Health Network
      below.
    </p>
  </div>
</div>
