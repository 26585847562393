<div class="location-filter">
  <mat-form-field appearance="outline" class="autocomplete-container">
    <mat-label>Search City, State, or Zip</mat-label>

    <div matAutocompleteOrigin #origin="matAutocompleteOrigin">
      <input
        #addresssearch
        matInput
        placeholder="Search City, State, or Zip"
        [matAutocomplete]="auto"
        [matAutocompleteConnectedTo]="origin"
      />
    </div>

    <!-- (optionSelected)="selectCurrentLocation($event.option.value)" -->
    <mat-autocomplete
      #auto="matAutocomplete"
      class="address-autocomplete-picklist"
      classlist="address-autocomplete-picklist"
    >
      <div *ngIf="showCurrentLocationOption">
        <mat-option *ngFor="let option of options" [value]="option.value" class="location-option">
          <mat-icon matPrefix>near_me</mat-icon>
          {{ option.viewValue }}
        </mat-option>
      </div>
    </mat-autocomplete>
  </mat-form-field>
</div>
