<div class="new-gender-filter-container">
  <mat-radio-group
    aria-labelledby="gender-radio-group-label"
    class="gender-radio-group"
    [(ngModel)]="selectedGender"
  >
    <mat-radio-button
      [disableRipple]="true"
      (change)="radioGenderChange($event)"
      class="gender-radio-button"
      *ngFor="let gender of genderOptions"
      [value]="gender"
    >
      {{ gender }}
    </mat-radio-button>
  </mat-radio-group>
</div>
