import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompareCategoryComponent } from './compare-category.component';

@NgModule({
  declarations: [CompareCategoryComponent],
  imports: [CommonModule],
  exports: [CompareCategoryComponent],
})
export class CompareCategoryModule {}
