export class LocationPracticeBuilding {
  primaryPractice: string;
  practiceName: string;
  pracBuildingID: string;
  practiceID: string;
  locationName: string;
  sublocationName: string;
  buildingAddress: string;
  buildingCity: string;
  buildingState: string;
  buildingCounty: string;
  buildingZip: string;
  phone: string;
  fax: string;
  directions: string;
  buildingLat: string;
  buildingLong: string;
  distance: string;
}
