import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompareProvNameComponent } from './compare-prov-name.component';
import { ProviderStarRatingModule } from '../../provider-result/provider-star-rating/provider-start-rating.module';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CompareProvNameComponent],
  imports: [CommonModule, ProviderStarRatingModule, MatIconModule, RouterModule],
  exports: [CompareProvNameComponent],
})
export class CompareProvNameModule {}
