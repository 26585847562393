import { Component, Input, OnInit } from '@angular/core';
import { CredentialsCompare } from '../../../pages/compare-page/compare-classes/credentials-compare/credentials-compare';

@Component({
  selector: 'atlas-compare-credentials',
  templateUrl: './compare-credentials.component.html',
  styleUrls: [
    './compare-credentials.component.scss',
    '../../../pages/compare-page/compare-page.component.scss',
  ],
})
export class CompareCredentialsComponent implements OnInit {
  @Input() creds: CredentialsCompare;

  credentialsExist: boolean = true;
  constructor() {}

  ngOnInit(): void {
    const credential =
      this.creds.boardsList.toString() +
      this.creds.fellowshipsList.toString() +
      this.creds.jobTitlesList.toString() +
      this.creds.residenciesList.toString() +
      this.creds.schoolsList.toString();

    if (credential === '') {
      this.credentialsExist = false;
    }
  }
}
