import { Component } from '@angular/core';

@Component({
  selector: 'atlas-provider-videodisclaimer',
  templateUrl: './provider-videodisclaimer.component.html',
  styleUrls: ['./provider-videodisclaimer.component.scss'],
})
export class ProviderVideodisclaimerComponent {
  constructor() {}
}
