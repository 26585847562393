import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhysicianIndexPageComponent } from './physician-index-page.component';
import { AhnTextModule } from '@dmx-fad-ui/fad/components';
import { DesktopPhysColumnModule } from '../desktop-phys-column/desktop-phys-column.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [PhysicianIndexPageComponent],
  imports: [CommonModule, AhnTextModule, DesktopPhysColumnModule, RouterModule],
})
export class PhysicianIndexPageModule {}
