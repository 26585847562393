// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class WindowSizeService {
  mobileMaxWidth: number = 766;
  tabletMinWidth: number = 767;
  tabletMaxWidth: number = 991;
  desktopMinWidth: number = 992;

  windowWidthSubj = new BehaviorSubject<number>(0);
  windowScroolPosition = new Subject<number>();
  constructor() {}

  convertWindowWidthToIsDesktop(width: number) {
    if (width >= this.desktopMinWidth) {
      return true;
    } else {
      return false;
    }
  }
  checkWindowWidthIsMobile(width: number){
    if (width <= this.tabletMinWidth) {
      return true;
    } else {
      return false;
    }
  }
}
