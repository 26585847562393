/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FilterService, ClearFilterService } from '@dmx-fad-ui/fad/services';

@Component({
  selector: 'atlas-clear-filter',
  templateUrl: './clear-filter.component.html',
  styleUrls: ['./clear-filter.component.scss'],
})
export class ClearFilterComponent implements OnInit {
  @Output() clearAllFiltersEmitter = new EventEmitter<any>();
  isCheckConditionFilterEnabled: boolean = false;
  isAllCheckBoxState: boolean = false;
  isLanguageSpokenFilterEnabled: boolean = false;
  isProviderLocationSelected: string = '';
  isGenderSelected: string = '';

  constructor(
    private filterServ: FilterService,
    private clearFilterService: ClearFilterService,
  ) {}

  ngOnInit(): void {
    this.conditionsSubj();

    this.checkboxSubj();

    this.languageSubj();
  }

  conditionsSubj() {
    this.clearFilterService.isCheckConditionFilterEnabled.subscribe((data) => {
      if (data == false) {
        //False Hide clear all
        this.isCheckConditionFilterEnabled = false;
      } else {
        //True Show clear all
        this.isCheckConditionFilterEnabled = true;
      }
    });
  }

  checkboxSubj() {
    this.clearFilterService.isAllCheckBoxState.subscribe((data) => {
      if (data == false) {
        //False Hide clear all
        this.isAllCheckBoxState = false;
      } else {
        //True Show clear all
        this.isAllCheckBoxState = true;
      }
    });
  }

  languageSubj() {
    this.clearFilterService.isLanguageSpokenState.subscribe((data) => {
      if (data == false) {
        this.isLanguageSpokenFilterEnabled = false;
      } else {
        this.isLanguageSpokenFilterEnabled = true;
      }
    });
  }

  onClearFilters() {
    this.clearAllFiltersEmitter.next(true);
    this.filterServ.conditionFilterSubj.next('');
    this.filterServ.lanaguageFilterSubj.next('');
    this.isAllCheckBoxState = false;
    this.isCheckConditionFilterEnabled = false;
    this.isLanguageSpokenFilterEnabled = false;
    this.isGenderSelected = 'Any Gender';
    this.isProviderLocationSelected = 'All Results';

    //Reset to GenderFilterSubj
    this.filterServ.genderFilterSubj.next('Any Gender');

    //Reset provider/location filter
    this.filterServ.providerLocationFilterSubj.next('All Results');

    //some condition dropdown state is removed
    this.clearFilterService.isCheckConditionFilterEnabled.next(false);

    //Language Spoken state
    this.clearFilterService.isLanguageSpokenState.next(false);
  }
}
