import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompareOnlineSchedulingComponent } from './compare-online-scheduling.component';

import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [CompareOnlineSchedulingComponent],
  imports: [CommonModule, MatButtonModule],
  exports: [CompareOnlineSchedulingComponent],
})
export class CompareOnlineSchedulingModule {}
