/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/// <reference types="@types/google.maps" />

import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  AfterViewInit,
  Output,
  ViewChild,
  ElementRef,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { LocationService } from '@dmx-fad-ui/fad/services';
import { query } from 'express';

@Component({
  selector: 'atlas-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
})
export class AddressAutocompleteComponent implements AfterViewInit {
  @Input() addressType: string;
  @Output() setAddressEmitter: EventEmitter<any> = new EventEmitter();
  @Output() setPlaceEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresssearch') addressSearch: ElementRef;

  @Input() autocompleteInput: string;
  queryWait: boolean;
  showCurrentLocationOption: boolean = true;
  options: any[] = [];

  constructor(
    private locationService: LocationService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.locationService = locationService;
  }

  ngAfterViewInit() {
    this.getPlacesAutocomplete();

    // hide picklist when using keyboard with address-autocomplete
    // otherwise 'my-current-location' is selected when you clicks down arrow
    this.addressSearch.nativeElement.addEventListener('keydown', (e) => {
      this.showCurrentLocationOption = false;
    });
  }

  // prompt for location sharing
  // async selectCurrentLocation(selectedValue: string) {
  //   if (selectedValue === '') {
  //     if (await this.locationService.requestUserLocation()) {
  //       this.setAddressEmitter.emit('use-current-location');
  //     } else {
  //       this.showCurrentLocationOption = false;
  //     }
  //   }
  // }

  getPlacesAutocomplete() {
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        const autocomplete = new google.maps.places.Autocomplete(this.addressSearch.nativeElement, {
          componentRestrictions: { country: 'US' },
          types: ['(regions)'], //city, state, & zip
        });

        // GoogleMaps API custom eventlistener method

        google.maps.event.addListener(autocomplete, 'place_changed', () => {
          this.showCurrentLocationOption = false;
          const place = autocomplete.getPlace();

          if (place && place.address_components) {
            this.setAddressEmitter.emit(place);
          } else {
            const predictions = this.getPlacePredictions(place.name);
          }
        });
      }
    }, 3000);
  }

  getPlacePredictions(query: string) {
    if (isPlatformBrowser(this.platformId)) {
      const autocompleteService = new google.maps.places.AutocompleteService();
      const autocompleteRequest = {
        types: ['(regions)'],
        input: query,
        componentRestrictions: { country: 'US' },
      };

      const predictions = null;
      autocompleteService.getPlacePredictions(autocompleteRequest, (prediction, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          this.populateFirstAutocompleteValue(prediction[0].description);
        }
      });
      return predictions;
    }
  }

  invokeEvent(place: Object) {
    this.setAddressEmitter.emit(place);
  }

  populateFirstAutocompleteValue(place: Object) {
    this.setPlaceEmitter.emit(place);
  }
}
