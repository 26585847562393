import { Component, Input, OnInit } from '@angular/core';
import { LocationCompare } from '../../../pages/compare-page/compare-classes/location-compare/location-compare';

import { LocationService, LocationPracticeBuilding } from '@dmx-fad-ui/fad/services';
import { Router } from '@angular/router';
import { ahnTextPath } from '../../ahn-text/ahn-text.enum';

@Component({
  selector: 'atlas-compare-location',
  templateUrl: './compare-location.component.html',
  styleUrls: [
    './compare-location.component.scss',
    '../../../pages/compare-page/compare-page.component.scss',
  ],
})
export class CompareLocationComponent implements OnInit {
  @Input() locationCompare: LocationCompare;
  location: string;

  practiceBuilding: LocationPracticeBuilding;
  seeMore: boolean = false;
  routerLinkPath: string = '';

  constructor(
    private locationService: LocationService,
    private router: Router,
  ) {
    this.locationService = locationService;
    this.router = router;
    this.routerLinkPath = ahnTextPath.results;
  }

  ngOnInit(): void {
    if (this.locationCompare.locations.length > 1) {
      this.seeMore = true;
    }
    this.location = this.locationCompare.locations[0];
    this.practiceBuilding = this.locationService.transformPracticeBuildingStringToObject(
      this.location,
    );
  }

  getEncodedUrl(keyword: string): string {
    return '/search-results/' + encodeURIComponent(keyword);
 }

  providerPage() {
    this.router.navigate([`/${this.locationCompare.profileName}`]);
  }
}
