<div class="conditions-root-container conditions-only">
  <div
    [ngClass]="
      arrowStyleClose
        ? 'condition-filter condition-disable-true'
        : 'condition-filter condition-disable-false'
    "
  >
    <form [formGroup]="conditionFilterGroup">
      <mat-form-field class="example-full-width" appearance="outline" hideRequiredMarker>
        <mat-label>Condition or Treatment</mat-label>
        <input
          type="text"
          aria-label="Condition or Treatment"
          matInput
          formControlName="conditionFilterName"
          [matAutocomplete]="auto"
          #inputAutoComplete
          matAutocompletePosition="below"
          [readonly]="arrowStyleClose"
        />
        <i
          *ngIf="!arrowStyleClose"
          class="material-icons align-arrow-right"
          (click)="openPanel($event)"
          (keydown.enter)="openPanel($event)"
          >{{ arrowStyle ? 'arrow_drop_up' : 'arrow_drop_down' }}</i
        >
        <i
          *ngIf="arrowStyleClose"
          (click)="clearConditionFilter()"
          (keydown.enter)="clearConditionFilter()"
          class="material-icons-outlined align-arrow-right"
        >
          close
        </i>
        <mat-autocomplete
          [disableRipple]="true"
          #auto="matAutocomplete"
          (opened)="isOpened()"
          (closed)="isClosed()"
          (optionSelected)="onCoditionSelected()"
        >
          <mat-option *ngFor="let cond of conditionBucketsList" [value]="cond.stringValue">
            {{ cond.stringValue }} {{ '(' + cond.count + ')' }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div>
</div>
