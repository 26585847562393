/* eslint-disable @typescript-eslint/no-unused-vars */
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'atlas-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(@Inject(PLATFORM_ID) private platformId: string) {
    if (isPlatformBrowser(this.platformId)) {
      addEventListener('resize', () => {
        this.checkMediaSize();
      });
    }
  }
  thisYear: number;
  panelOpenState: boolean = true;

  ngOnInit(): void {
    this.checkMediaSize();
    const today = new Date();
    this.getYear(today);
  }

  getYear(today: Date) {
    this.thisYear = today.getFullYear();
  }

  checkMediaSize() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 767) {
        this.panelOpenState = false;
      } else {
        this.panelOpenState = true;
      }
    }
  }
}
