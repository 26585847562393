import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'atlas-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss'],
})
export class ShareButtonComponent implements OnInit {
  isShareOpen: boolean = false;
  isCopyLinkShow: boolean = true;
  clipboardValue: string;
  showShareIcon: boolean;
  @Input() pageType?: string = '';

  pageShareMap: Map<string, boolean>;
  socialMediaURL: string;
  newShareButtonOn: boolean;

  constructor(
    private clipboard: Clipboard,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.pageShareMap = new Map([
      ['search-results', false],
      ['provider-detail', true],
      ['compare', true],
    ]);
    this.clipboard = clipboard;
    this.newShareButtonOn = environment.newShareFeaturesOn;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.clipboardValue = window.location.href;
      this.socialMediaURL = environment.shareButtonPath + window.location.pathname;
      this.showShareIcon = this.pageShareMap.get(this.pageType);
    }
  }

  openShareModal() {
    this.isShareOpen = !this.isShareOpen;
  }

  closeShareModal() {
    this.isShareOpen = !this.isShareOpen;
    this.isCopyLinkShow = true;
    return false;
  }

  copyLinkClicked() {
    this.isCopyLinkShow = false;
    this.clipboard.copy(this.clipboardValue);
  }

  shareSocial(socialMedia: string) {
    if (isPlatformBrowser(this.platformId)) {
      if (socialMedia === 'facebook') {
        window.open(
          'https://www.facebook.com/sharer/sharer.php?u=' + this.socialMediaURL,
          'facebook-share-dialog',
          'width=626,height=436',
        );
      } else if (socialMedia === 'twitter') {
        window.open(
          'https://twitter.com/share?url=' + this.socialMediaURL,
          'twitter-share-dialog',
          'menubar=no,height=436,width=626',
        );
      } else if (socialMedia === 'linkedin') {
        window.open(
          'https://www.linkedin.com/sharing/share-offsite/?url=' + this.socialMediaURL,
          'linkedIn-share-dialog',
          'menubar=no,height=436,width=626',
        );
      }
    }
  }
}
