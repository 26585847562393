import { Component, Input, OnInit } from '@angular/core';
import { LgbtCompare } from '../../../pages/compare-page/compare-classes/lgbt-compare/lgbt-compare';

@Component({
  selector: 'atlas-compare-lgbt',
  templateUrl: './compare-lgbt.component.html',
  styleUrls: [
    './compare-lgbt.component.scss',
    '../../../pages/compare-page/compare-page.component.scss',
  ],
})
export class CompareLgbtComponent implements OnInit {
  @Input() lgbt: LgbtCompare;
  isLgbt: string;
  constructor() {}

  ngOnInit(): void {
    this.isLgbt = this.lgbt.isLgbt;
  }
}
