/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FadSeachStateService } from '@dmx-fad-ui/fad/data-access';
import { Subscription } from 'rxjs';
import { IDialog } from '@dmx-fad-ui/fad/interfaces';
import { WindowSizeService } from '@dmx-fad-ui/fad/services';
import { SpinnerComponent } from './spinner/spinner.component';

@Component({
  selector: 'atlas-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss'],
})
export class BasePageComponent implements OnInit, IDialog, OnDestroy {
  fadStateSub: Subscription;
  constructor(
    public dialog: MatDialog,
    private fadStateServ: FadSeachStateService,
    private windowSizeServ: WindowSizeService,
  ) {
    this.windowSizeServ = windowSizeServ;
  }

  ngOnInit() {
    this.subscribeToSpinnerSubject();
  }

  subscribeToSpinnerSubject(): void {
    this.fadStateSub = this.fadStateServ.isShowSpinnerSubj.subscribe((isShowSpinner) => {
      if (isShowSpinner) {
        this.openDialog();
      } else {
        this.closeDialog();
      }
    });
  }

  openDialog() {
    this.dialog.open(SpinnerComponent);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  ngOnDestroy() {
    this.fadStateSub.unsubscribe();
  }
}
