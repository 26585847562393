import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoSchedulingTextComponent } from './no-scheduling-text.component';

@NgModule({
  declarations: [NoSchedulingTextComponent],
  imports: [CommonModule],
  exports: [NoSchedulingTextComponent],
})
export class NoSchedulingTextModule {}
