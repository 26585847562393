<div id="header" class="ahn-dmxfad-header">
  <header>
    <div *ngIf="this.ribbonText !== ''" class="ribbon ribbon-top-left">
      <span>{{ this.ribbonText }}</span>
    </div>

    <div *ngIf="this.isDesktop" class="ahn-header-desktop">
      <atlas-desktop-header></atlas-desktop-header>
    </div>
    <div *ngIf="!this.isDesktop" class="ahn-header-mobile">
      <atlas-mobile-header></atlas-mobile-header>
    </div>
  </header>
</div>
