<div class="child-background">
  <div class="child-box-flex bump-down">
    <mat-checkbox
      [checked]="isChecked"
      class="child-items"
      (change)="onModelChange($event, childTitle)"
      [color]="selectedColor"
      name="{{ childTitle }}"
    >
      {{ childTitle }}
      <span
        class="splash-blue-icon material-icons-outlined"
        *ngIf="toolTip !== ''"
        matTooltip="{{ toolTip | tooltipTransform }}"
        matTooltipClass="custom-tool-tip-show-only-filter"
        tabindex="0"
        >info</span
      >
    </mat-checkbox>
  </div>
</div>
