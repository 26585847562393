import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClearFilterService {
  isClear = new Subject<boolean>();
  isClearLocation = new Subject<boolean>();
  isSideNavClosed = new Subject<boolean>();
  hideClearAllButton = new Subject<boolean>();
  isConditionsFilterEnabled = new Subject<boolean>();

  //state to check clear all button
  isClearAllButtonEnabled = new BehaviorSubject<boolean>(false);
  //state to check conditions filter
  isCheckConditionFilterEnabled = new BehaviorSubject<boolean>(false);
  //state to check all check boxes
  isAllCheckBoxState = new BehaviorSubject<boolean>(false);
  //state to check language spoken filters
  isLanguageSpokenState = new BehaviorSubject<boolean>(false);

  clearFilter() {
    this.isClear.next(true);
  }
  clearLocation() {
    this.isClearLocation.next(true);
  }
  closeSideNav() {
    this.isSideNavClosed.next(true);
  }
}
