import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (
      future.firstChild &&
      future.firstChild.firstChild &&
      future.firstChild.firstChild.firstChild
    ) {
      return future.firstChild.firstChild.firstChild.data['reuseRoute'];
    }

    return future.routeConfig === curr.routeConfig;
  }
}
