import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareButtonComponent } from './share-button.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ShareButtonComponent],
  imports: [CommonModule, ClipboardModule, MatIconModule],
  exports: [ShareButtonComponent],
})
export class ShareButtonModule {}
