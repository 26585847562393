/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';
import { Subject, forkJoin, Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../../../apps/dmx-fad-ui/src/environments/environment';
import { FadCompareProviderRequest } from '../fad-provider-detail/fad-compare-provider-request';
import { FadProviderDetailRequest } from '../fad-provider-detail/fad-provider-detail-request';
import { FadProviderCommentsResponse } from './FadProviderCommentsResponse';
import { FadCompareCommentDetail } from '../fad-provider-detail/fad-compare-comment-detail';
import { FadProviderDetailService } from '../fad-provider-detail/fad-provider-detail.service';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class FadCommentService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  providerCommentsSubj = new Subject<any>();
  endpoint: string = `${environment.providerCommentEndpoint}`;
  ratingsCompareList: FadCompareCommentDetail[] = [];
  providerRatingSubj = new Subject<any>();
  compareRatingSubj = new Subject<FadCompareCommentDetail[]>();
  fadProvDetServ: FadProviderDetailService;

  isDetailCallComplete: boolean = false;
  isDetailCallCompleteSub: Subscription;

  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  getProviderComments(request: FadProviderDetailRequest): void {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', `${environment.xPubApiKey}`);

    this.httpClient
      .post<FadProviderCommentsResponse>(this.endpoint, request, {
        headers,
      })
      .pipe(
        map((resp) => {
          this.providerCommentsSubj.next(resp);
        }),
      )
      .subscribe();
  }

  getCompareCommentDetail(request: FadCompareProviderRequest): void {
    this.ratingsCompareList = [];

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', `${environment.xApiKey}`);

    // BEGINNING OF FJ

    const namesFound = [];
    for (let i = 0; i < request.providerQuery.profileNameList.length; i++) {
      if (request.providerQuery.profileNameList[i] !== '') {
        namesFound.push(
          this.createFadProviderDetailRequest(request.providerQuery.profileNameList[i]),
        );
      }
    }
    const observables: any[] = [];
    for (let i = 0; i < namesFound.length; i++) {
      observables.push(
        this.httpClient.post<FadProviderCommentsResponse>(this.endpoint, namesFound[i], {
          headers,
        }),
      );
    }

    const fj: Observable<FadProviderCommentsResponse[]> = forkJoin(
      observables as FadProviderCommentsResponse[],
    );
    fj.subscribe({
      next: (res) => {
        for (let i = 0; i < res.length; i++) {
          if (res[i].payload.commentDetail.commentsAndRatings !== null) {
            const tempCommentDetail: FadCompareCommentDetail = {
              commentsAndRatings: {
                profileName: res[i].payload.commentDetail.commentsAndRatings.profileName,
                comments: res[i].payload.commentDetail.commentsAndRatings.comments,
                ratings: res[i].payload.commentDetail.commentsAndRatings.ratings,
                commentChips: res[i].payload.commentDetail.commentsAndRatings.commentChips,
              },
            };
            this.ratingsCompareList.push(tempCommentDetail);
          }
        }
      },
      complete: () => {
        this.compareRatingSubj.next(this.ratingsCompareList);
      },
    });
    // END OF FJ
  }

  createFadProviderDetailRequest(providerName: string): FadProviderDetailRequest {
    return { providerQuery: { profileName: providerName, npi: '' } };
  }

  createCompareProviderReq(provNames: string[]): FadCompareProviderRequest {
    return { providerQuery: { profileNameList: provNames } };
  }
}
