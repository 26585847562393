import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderResultsComponent } from './provider-results.component';
import { ProviderResultModule } from '../provider-result/provider-result.module';
import { MatButtonModule } from '@angular/material/button';
import { ProviderMapModule } from '../provider-results/provider-map/provider-map.module';

@NgModule({
  declarations: [ProviderResultsComponent],
  exports: [ProviderResultsComponent],
  imports: [CommonModule, ProviderResultModule, MatButtonModule, ProviderMapModule],
})
export class ProviderResultsModule {}
