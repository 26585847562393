/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input } from '@angular/core';

import { RatingsCompare } from '../../../pages/compare-page/compare-classes/ratings-compare/ratings-compare';

@Component({
  selector: 'atlas-compare-ratings',
  templateUrl: './compare-ratings.component.html',
  styleUrls: [
    './compare-ratings.component.scss',
    '../../../pages/compare-page/compare-page.component.scss',
  ],
})
export class CompareRatingsComponent {
  @Input() ratingCompare: RatingsCompare;

  constructor() {}
}
