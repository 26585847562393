import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchMonitorService {
  hasUsedSearchBarIndexSubj = new Subject<boolean>();
  storedSearchEntrySubj = new BehaviorSubject<string>(''); // This is used to prevent redundant suggest entries from popping up when searches are conducting and page navigation happens

  constructor() {}
}
