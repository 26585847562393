import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function birthdateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = new Date(control.value);
    const today = new Date();

    if (!value) {
      return null;
    }

    return value <= today ? null : { birthdateCheck: true };
  };
}
