import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { ProviderComponent } from './provider.component';
import { ProviderProfileComponent } from '../provider/provider-profile/provider-profile.component';
import { ProviderProfileModule } from '../provider/provider-profile/provider-profile.module';
import { ProviderExpertiseModule } from '../provider/provider-expertise/provider-expertise.module';
import { ProviderCredentialsModule } from '../provider/provider-credentials/provider-credentials.module';
import { ProviderRatingsCommentsModule } from '../provider/provider-ratings-comments/provider-ratings-comments.module';
import { ProviderBioModule } from '../provider/provider-bio/provider-bio.module';
import { ProviderLocationsModule } from '../provider/provider-locations/provider-locations.module';
import { ResultSummaryModule } from '../result-summary/result-summary.module';
import { ProviderPublicationsModule } from '../provider/provider-publications/provider-publications.module';
import { ProviderMapModule } from '../provider-results/provider-map/provider-map.module';
import { ProviderLgbtModule } from '../provider/provider-lgbt/provider-lgbt.module';
import { ReferralButtonModule } from '../referral/referral-button/referral-button.module';
import { ProviderVideodisclaimerModule } from './provider-videodisclaimer/provider-videodisclaimer.module';

@NgModule({
  declarations: [ProviderComponent],
  exports: [ProviderComponent, ProviderProfileComponent],
  imports: [
    CommonModule,
    ProviderProfileModule,
    ProviderExpertiseModule,
    ProviderCredentialsModule,
    ProviderRatingsCommentsModule,
    ProviderBioModule,
    ProviderLocationsModule,
    ResultSummaryModule,
    ProviderPublicationsModule,
    ProviderMapModule,
    ProviderLgbtModule,
    ReferralButtonModule,
    ProviderVideodisclaimerModule,
    MatTabsModule,
  ],
})
export class ProviderModule {}
