/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'atlas-provider-bio',
  templateUrl: './provider-bio.component.html',
  styleUrls: ['./provider-bio.component.scss'],
})
export class ProviderBioComponent implements OnInit {
  @Input() bio: string;
  @Input() youTubeVideoBioUrl: string;
  @Input() providerLanguageResults: any;
  @Input() displayName: string;
  @Input() videoFilterEnabled: boolean;
  youTubeVideoSafeUrl: SafeUrl;
  isNewProviderDetailExperience = environment.isTabProviderPageEnabled;


  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.youTubeVideoBioUrl) {
      this.youTubeVideoSafeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
        this.youTubeVideoBioUrl,
      );
    }
  }
}
