import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderRatingsCommentsComponent } from './provider-ratings-comments.component';
import { ProviderRatingsPipeModule } from '../../../pipes/provider-ratings-pipe.module';
import { HtmlPipeModule } from '../../../pipes/html.pipe.module';
import { BoldPipeModule } from '../../../pipes/bold.pipe.module';

@NgModule({
  declarations: [ProviderRatingsCommentsComponent],
  exports: [ProviderRatingsCommentsComponent],
  imports: [CommonModule, ProviderRatingsPipeModule, HtmlPipeModule, BoldPipeModule],
})
export class ProviderRatingsCommentsModule {}
