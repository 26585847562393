import { AfterViewChecked, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'atlas-print-expertise',
  templateUrl: './print-expertise.component.html',
  styleUrls: ['./print-expertise.component.scss'],
})
export class PrintExpertiseComponent implements AfterViewChecked {
  @Input() expertiseItem: string = '';
  @Output() isContentLoadedEmitter = new EventEmitter<boolean>();
  constructor() {}

  ngAfterViewChecked(): void {
    this.isContentLoadedEmitter.emit(true);
  }
}
