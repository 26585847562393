/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';
import {
  FadProviderDetailRequest,
  FadSearchSortOrder,
  FadLocationDetailRequest,
  FadRelatedProvidersRequest,
} from '@dmx-fad-ui/fad/data-access';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class SimpleUtilsService {
  constructor() {}

  verifyElementInArray(arr: any[], element: any) {
    if (arr.indexOf(element) > -1) {
      return true;
    } else {
      return false;
    }
  }

  hasNoValue(input: any) {
    if (input === '' || input === undefined || input === null) {
      return true;
    } else {
      return false;
    }
  }

  buildSimpleSearchRequest(searchTerm: string) {
    return {
      filterDistance: 0,
      requestLocation: '',
      requestResultCount: 10,
      sortOrder: FadSearchSortOrder.default,
      startIndex: 0,
      userQuery: searchTerm,
    };
  }

  sterilizeSearchText(input: string) {
    //replace / with a space
    let sterilized = input.replace(/\//gm, '%2F');

    //remove bullet substring
    const bulletIndex = sterilized.indexOf('\u2022');
    if (bulletIndex > -1) {
      sterilized = sterilized.substring(0, bulletIndex);
    }

    return sterilized.trim();
  }

  removeBackSlashForSearchResultsUrl(inputSearchText: string) {
    /* Regex looks for \ slash chracter in the search query and replaces all the occurences of \ with empty string.
    This was needed as \ is a part of the url and in our case when we add \ to our route it doesn't match any pattern. */
    let modifiedSearchText = '';
    if (inputSearchText === '/') {
      modifiedSearchText = '///no-result';
    } else {
      modifiedSearchText = inputSearchText.replace(/\//gm, '%2F');
    }
    return modifiedSearchText;
  }

  createFadProviderDetailRequest(providerName: string): FadProviderDetailRequest {
    return { providerQuery: { profileName: providerName } };
  }

  createFadLocationDetailRequest(
    pracBuildingID: string,
    practiceID: string,
  ): FadLocationDetailRequest {
    return { locationQuery: { pracBuildingID: pracBuildingID, practiceID: practiceID } };
  }

  createFadRelatedProvidersRequest(pracBuildingID: string): FadRelatedProvidersRequest {
    return { locationQuery: { pracBuildingID: pracBuildingID } };
  }
}
