/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, TransferState, makeStateKey } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class BrowserStateInterceptor implements HttpInterceptor {
  constructor(private transferState: TransferState) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const body = JSON.stringify(req.body);
    if (
      req.url.includes(environment.searchEndpoint) ||
      req.url.includes(environment.suggestEndpoint) ||
      req.url.includes(environment.pdfRenderUrlEndpoint) ||
      req.url.includes(environment.emailEndpoint) ||
      body.includes('npi')
    ) {
      return next.handle(req);
    }
    const storedResponse: string = this.transferState.get(makeStateKey(req.url + body), null);
    if (storedResponse) {
      const response = new HttpResponse({ body: storedResponse, status: 200 });
      return of(response);
    }

    return next.handle(req);
  }
}
