/* eslint-disable @typescript-eslint/no-unused-vars */
import { AfterViewChecked, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'atlas-print-credentials',
  templateUrl: './print-credentials.component.html',
  styleUrls: ['./print-credentials.component.scss'],
})
export class PrintCredentialsComponent implements AfterViewChecked {
  @Input() credentials: string[];
  @Output() isContentLoadedEmitter = new EventEmitter<boolean>();

  constructor() {}

  ngAfterViewChecked(): void {
    this.isContentLoadedEmitter.emit(true);
  }
}
