<div class="result-summary-container">
  <div class="return-to-results">
    <div *ngIf="isShowBreadcrumbs" class="has-results">
      <!--<div
        class="universal-pointer"
        (click)="onReturnToSearch()"
        (keydown.enter)="onReturnToSearch()"
      >
        <span class="material-icons green-icon">
          keyboard_arrow_left
        </span>
        <span
          class="aligned-with-icon sofia-pro-semi-bold return-to-search-color"
          aria-label="Return to Results"
          tabindex="0"
          >Return to Results</span
        >
      </div>-->
      <atlas-ahn-text #ahntext [indexPage]="false"></atlas-ahn-text>
    </div>
    <div *ngIf="this.pageType !== 'provider-detail'">
      <div *ngIf="searchAutocorrect === null && isCanShowResults" class="flex-item-summary">
        <p class="p2">
          Showing {{ currentSearchResultCount }} of {{ currentTotalResultCount }} results for
          <b>"{{ currentSearchQuery }}"</b>
        </p>
      </div>
      <div
        *ngIf="searchAutocorrect !== undefined && searchAutocorrect !== null && isCanShowResults"
        class="flex-item-summary"
      >
        <p class="p2">
          Showing {{ currentSearchResultCount }} of {{ currentTotalResultCount }} results for
          <b>"{{ searchAutocorrect }}"</b>
        </p>
        <p>
          <i>There are no results for "{{ currentSearchQuery }}".</i>
        </p>
      </div>
      <div *ngIf="!isCanShowResults" class="has-no-results"></div>
    </div>
  </div>

  <div id="print-share" class="print-share-block">
    <div *ngIf="!isTablet" class="print-block">
      <div
        *ngIf="isCanShowPrintIcon"
        class="print-block-flex universal-pointer"
        (click)="openInNewTab()"
        (keydown.enter)="openInNewTab()"
      >
        <span id="material-print" class="material-icons-outlined together-blue-icon"></span>
        <span class="subtext print-share-color hide-in-mobile" aria-label="print" tabindex="0"
          >PRINT</span
        >
      </div>
    </div>
    <atlas-share-button pageType="{{ this.pageType }}"></atlas-share-button>
  </div>
</div>
