// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CompareProviderModel } from './compareProviderModel';
@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class CompareProviderService {
  checkboxCount: number = 0;
  compareProviders: CompareProviderModel[] = [
    { profileName: '', npi: '', specialty: '', displayName: '', distance: '' },
    { profileName: '', npi: '', specialty: '', displayName: '', distance: '' },
    { profileName: '', npi: '', specialty: '', displayName: '', distance: '' },
    { profileName: '', npi: '', specialty: '', displayName: '', distance: '' },
  ];

  private _compareDisabled = new BehaviorSubject<boolean>(false);
  private _clearAllCompares = new BehaviorSubject<string>('');

  private _removeFromList = new BehaviorSubject<string>('');
  private _addToList = new BehaviorSubject<string>('');
  private _seeAll = new BehaviorSubject<boolean>(true);

  //disables checkboxes
  compareDisabled$ = this._compareDisabled.asObservable();
  //clears checkboxes
  clearAllCompares$ = this._clearAllCompares.asObservable();

  removeFromList$ = this._removeFromList.asObservable();

  addToList$ = this._addToList.asObservable();
  seeAll$ = this._seeAll.asObservable();

  disableCompare(disable) {
    this._compareDisabled.next(disable);
  }

  clearAllCompares(clearedCompares: CompareProviderModel[]) {
    this.compareProviders = clearedCompares;
    this._clearAllCompares.next('CLEAR');
  }

  removeFromList(profileName) {
    const index = this.compareProviders.findIndex((x) => x.profileName === profileName);
    if (index != -1) {
      this.checkboxCount--;
      this.compareProviders.splice(index, 1);
      this.compareProviders.push({
        profileName: '',
        npi: '',
        specialty: '',
        displayName: '',
        distance: '',
      });
      this._removeFromList.next(profileName);
      this._removeFromList.next('');
    }
  }

  addToList(comparedProvider: CompareProviderModel) {
    for (let i = 0; i < this.compareProviders.length; i++) {
      if (this.compareProviders[i].profileName === '') {
        this.compareProviders[i] = comparedProvider;
        break;
      }
    }
    this.checkboxCount++;
    this._addToList.next('');
  }

  seeAll(seeAll) {
    this._seeAll.next(seeAll);
  }

  buildCompareQueryParams() {
    const queryParams = {};
    this.compareProviders.forEach((provider, index) => {
      if (provider.npi != '') {
        queryParams[`provider${index + 1}`] = provider.npi;
      }
    });

    return queryParams;
  }
}
