<div class="provider-card">
  <div class="provider-image">
    <img [src]="providerImage" />
  </div>
  <div class="provider-info">
    <p class="provider-name bold-first-paragraph sofia-pro-semi-bold">
      {{ this.providerDisplayName }}
    </p>
    <div class="provider-specialty">
      <p class="roboto-regular">{{ this.providerSpecialty }}</p>
    </div>
  </div>
</div>
