<div class="compare-provider-check">
  <mat-checkbox
    [checked]="isChecked"
    (change)="compareProviderCheckbox()"
    [color]="selectedColor"
    [disabled]="isDisabled"
    [hidden]="isHidden"
  >
    Compare Provider
  </mat-checkbox>
</div>
