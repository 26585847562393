/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { FilterService, ClearFilterService } from '@dmx-fad-ui/fad/services';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { ChildOptionChange } from '../filter-child/childOptionChange';

@Component({
  selector: 'atlas-language-filters',
  templateUrl: './language-filters.component.html',
  styleUrls: ['./language-filters.component.scss'],
})
export class LanguageFiltersComponent implements OnInit, OnChanges {
  @Output() conditionLanguageFilterEmitter = new EventEmitter<ChildOptionChange>();
  @Input() facets: any;
  @Input() clearAllEnabled: any;
  selectedLanguage: string = '';
  clearFilterSub: Subscription;
  conditionBucketsList: any;
  arrowStyle: boolean = false;
  arrowStyleClose: boolean = false;
  languageFilterGroup: UntypedFormGroup;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  languageAutoComplete: MatAutocompleteTrigger;
  languageUserSelectedItem: string = '';

  constructor(
    private filterServ: FilterService,
    private clearFilterService: ClearFilterService,
  ) {}

  ngOnInit(): void {
    this.setFormConditionFilter();
    this.languageFilterGroup.get('languageFilterName').valueChanges.subscribe((value) => {
      this.conditionBucketsList = this.facets;
      if (value == null) {
        this.languageFilterGroup.setValue({ languageFilterName: '' });
      } else {
        if (value.length > 0) {
          const filterValue = value.toLowerCase();
          this.conditionBucketsList = this.conditionBucketsList.filter((val) =>
            val.stringValue.toLowerCase().includes(filterValue),
          );
        }
      }
    });

    this.filterServ.lanaguageFilterSubj.subscribe((conditionModel) => {
      if (conditionModel.length > 0) {
        this.languageUserSelectedItem = conditionModel;
        this.languageFilterGroup.setValue({
          languageFilterName: this.languageUserSelectedItem,
        });
        this.arrowStyleClose = true;
      } else {
        this.arrowStyleClose = false;
        this.languageFilterGroup.setValue({ languageFilterName: '' });
      }
    });
  }

  setFormConditionFilter() {
    this.languageFilterGroup = new UntypedFormGroup({
      languageFilterName: new UntypedFormControl('', [Validators.required]),
    });
  }

  openPanel(evt): void {
    evt.stopPropagation();
    const isOpen = this.languageAutoComplete.autocomplete.isOpen;
    if (isOpen) {
      this.languageAutoComplete.closePanel();
    } else {
      this.languageAutoComplete.openPanel();
    }
  }

  isOpened() {
    this.arrowStyle = !this.arrowStyle;
  }

  isClosed() {
    this.arrowStyle = !this.arrowStyle;
  }

  ngOnChanges(): void {
    if (this.facets.length > 0) {
      this.facets.sort((a, b) => a.stringValue.localeCompare(b.stringValue));
      this.conditionBucketsList = this.facets;
    }
  }

  onCoditionSelected() {
    this.arrowStyleClose = true;
    this.languageUserSelectedItem = this.languageFilterGroup.value.languageFilterName;
    this.selectedLanguage = this.languageFilterGroup.value.languageFilterName;
    this.filterServ.lanaguageFilterSubj.next(this.languageUserSelectedItem);
    this.conditionLanguageFilterEmitter.next(
      new ChildOptionChange('language', true, this.selectedLanguage),
    );

    //some language dropdown state is removed
    this.clearFilterService.isLanguageSpokenState.next(true);
  }

  clearConditionFilter() {
    this.arrowStyleClose = false;
    this.filterServ.lanaguageFilterSubj.next('');
    this.conditionLanguageFilterEmitter.next(new ChildOptionChange('language', false, ''));
    this.languageFilterGroup.reset();

    //some language dropdown state is removed
    this.clearFilterService.isLanguageSpokenState.next(false);
  }
}
