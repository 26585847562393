import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationProviderFilterComponent } from './location-provider-filter.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [LocationProviderFilterComponent],
  imports: [CommonModule, MatRadioModule, FormsModule, ReactiveFormsModule],
  exports: [LocationProviderFilterComponent],
})
export class LocationProviderFilterModule {}
