/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input, OnInit, Output, EventEmitter, AfterViewChecked } from '@angular/core';

@Component({
  selector: 'atlas-print-locations',
  templateUrl: './print-locations.component.html',
  styleUrls: ['./print-locations.component.scss'],
})
export class PrintLocationsComponent implements OnInit, AfterViewChecked {
  @Input() providerPracticeBuildings: any;
  jsonLocationObject: any;
  @Output() isContentLoadedEmitter = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit(): void {
    this.jsonLocationObject = JSON.stringify(this.providerPracticeBuildings);
  }

  ngAfterViewChecked(): void {
    this.isContentLoadedEmitter.emit(true);
  }
}
