<div class="persistent-bar">
  <atlas-search
    [isLandingPage]="false"
    [isProviderDetailPage]="true"
    [isSearchResultsPage]="false"
    [shouldAutoFocus]="false"
  ></atlas-search>
</div>

<div class="referral-form-page">
  <div class="return-print-share">
    <atlas-ahn-text #ahntext [indexPage]="false"></atlas-ahn-text>
  </div>

  <div class="referral-form-container">
    <div class="referral-form-header">
      <h1>Physician Referral</h1>
      <p class="bold-first-paragraph">
        AHN makes it easy for you to refer your patient to a specialist. Complete this form to
        expedite the referral process.
      </p>
      <p class="referral-form-text">
        If you need assistance or to order testing call us at 1-844-MD-REFER Monday through Friday
        7AM-5PM.
      </p>
      <div class="blue-line"></div>
    </div>
    <div *ngIf="providerDetail">
      <atlas-referral-form [providerDetail]="this.providerDetail"></atlas-referral-form>
    </div>
  </div>
</div>
