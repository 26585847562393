/* eslint-disable @typescript-eslint/no-array-constructor */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ChildOptionChange } from './filter-child/childOptionChange';
import {
  FilterService,
  ClearFilterService,
  TabableService,
  WindowSizeService,
} from '@dmx-fad-ui/fad/services';
import { FacetsBucketsList } from './facets-buckets-list';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DistanceOptionChange } from './distance-filter/distanceOptionChange';
import { SelectedFilters } from './selectedFilters';
import {
  FadSeachStateService,
  FadSearchService,
  FadSearchResult,
} from '@dmx-fad-ui/fad/data-access';
import { debounceTime } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'atlas-provider-results-filter',
  templateUrl: './provider-results-filter.component.html',
  styleUrls: ['./provider-results-filter.component.scss'],
})
export class ProviderResultsFilterComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  appointmentsTitle: string = 'Appointment Types';
  ahnProvidersTitle: string = 'Work for AHN';
  makeAppointmentTitle: string = 'Schedule Online';
  outPatientEnabledTitle: string = 'Schedule by Phone';
  lgbtTitle: string = 'Are a LGBTQIA+ Champion';
  videoBiosTitle: string = 'Have a Video Bio';
  appointmentsOptions: string[] = ['Video Appointments'];
  viewOnlyTitle: string = 'Show Only';
  viewOnlyOptions: string[] = [
    'AHN Providers',
    'Video Bios',
    'LGBTQ+',
    'Online Scheduling, Make Appointments ',
  ];
  genderTitle: string = 'Provider Gender';
  showClearAllFilters: boolean = false;
  isClearAllFilters: boolean = false;
  conditionsListData: any;
  facetResults: any;
  conditionsData: any;
  genderData: any;
  videoappointmentData: any;
  videoappointmentObj: FacetsBucketsList;
  lgbtData: any;
  lgbtBucketObj: FacetsBucketsList;
  onlineSchedulingData: any;
  onlineSchedulingBucketObj: FacetsBucketsList;
  makeAppointmentData: any;
  makeAppointmentBucketObj: FacetsBucketsList;
  videoData: any;
  videoBucketObj: FacetsBucketsList;
  ahnData: any;
  ahnBucketObj: FacetsBucketsList;
  appointmentBucketObj: FacetsBucketsList;
  appointmentBucketArray: FacetsBucketsList[] = [];
  viewOnlyBucketObj: FacetsBucketsList;
  viewOnlyBucketArray: FacetsBucketsList[] = [];
  conditionBucketsArray: FacetsBucketsList[] = [];
  conditionsBucketObj: FacetsBucketsList;
  genderBucketObj: FacetsBucketsList;
  genderBucketArray: FacetsBucketsList[] = [];
  languageSpokenArray: FacetsBucketsList[] = [];
  languageSpokenData: any;
  languageSpokenBucketObj: FacetsBucketsList;
  hasLangSpoken: boolean = false;
  hasValue: boolean = false;
  staticFemaleNullBucket: FacetsBucketsList = new FacetsBucketsList();
  staticMaleNullBucket: FacetsBucketsList = new FacetsBucketsList();
  staticAHNEmployedNullBucket: FacetsBucketsList = new FacetsBucketsList();
  hasNoAHNEmplyedResults: boolean = false;
  staticVideoBioNullBucket: FacetsBucketsList = new FacetsBucketsList();
  hasNoVideoBioResults: boolean = false;
  staticLGBNullBucket: FacetsBucketsList = new FacetsBucketsList();
  hasNoLGBTResults: boolean = false;
  staticOnlineSchedulingNullBucket: FacetsBucketsList = new FacetsBucketsList();
  hasNoOnlineSchedulingResults: boolean = false;
  staticVideoAppointmentNullBucket: FacetsBucketsList = new FacetsBucketsList();
  hasNoVideoAppointmentResults: boolean = false;
  totalSearchResultCount: number = 0;
  staticLanguageSpokenNullBucket: FacetsBucketsList = new FacetsBucketsList();
  hasNoLanguageSpokenResults: boolean = false;
  staticMakeAppointmentNullBucket: FacetsBucketsList = new FacetsBucketsList();
  hasNoMakeAppointmentResults: boolean = false;
  clearAllEnabledStatus: boolean = false;
  //operator names used for facet-searches
  //    the operator names for last (4) do not match field names,
  //    they were shortened to prevent spell-correction issues
  conditionString: string = 'conditions';
  genderString: string = 'gender';
  employedString: string = 'employed';
  lgbtqalignedString: string = 'lgbtq';
  youtubevideobioString: string = 'video';
  onlineSchedulingString: string = 'schedulable';
  videoAppointmentString: string = 'telemedicine';
  languageSpokenString: string = 'languages';
  makeAppointmentOnlineString: string = 'outatientenabled';
  sortOrder: string = 'DEFAULT';
  totalResultsCount: number = 10;

  previouslySelectedGenderOptions?: string[] = [];
  filterSub: Subscription = new Subscription();
  clearSub: Subscription = new Subscription();
  refocusSub: Subscription = new Subscription();
  totalResultsCountSub: Subscription = new Subscription();
  videoFilterEnabled: boolean;

  @ViewChild('filterContent') filterContent: ElementRef;

  @Output() conditionEmitter: EventEmitter<any> = new EventEmitter();
  @Output() genderEmitter: EventEmitter<any> = new EventEmitter();
  @Output() ahnEmitter: EventEmitter<any> = new EventEmitter();
  @Output() onlineScheduleEmitter: EventEmitter<any> = new EventEmitter();
  @Output() phoneScheduleEmitter: EventEmitter<any> = new EventEmitter();
  @Output() lgbtqiaplusEmitter: EventEmitter<any> = new EventEmitter();
  @Output() videoBioEmitter: EventEmitter<any> = new EventEmitter();
  @Output() distanceFilterEmitter: EventEmitter<DistanceOptionChange> = new EventEmitter();
  @Output() languageEmitter: EventEmitter<string> = new EventEmitter();
  @Output() mobileApplyEmitter: EventEmitter<boolean> = new EventEmitter();

  selectedFilters: SelectedFilters = {
    outpatientenabled: false,
    ahn: false,
    video: false,
    languages: '',
    conditions: '',
    gender: 'Any Gender',
    schedule: false,
    lgbtq: false,
    location: '',
    locationdistance: 0,
    sortorder: 'DEFAULT',
    totalResultsCount: 0,
  };

  isSelectedLocationProvider: string = 'All Results';
  isDesktop: boolean = undefined;

  //feature flags
  isLocationSearchEnabled: boolean = false;
  windowWidthSub: Subscription;

  constructor(
    private filterServ: FilterService,
    private fadSearchService: FadSearchService,
    private fadSearchStateServ: FadSeachStateService,
    private clearFilterService: ClearFilterService,
    private tabableService: TabableService,
    private windowSizeServ: WindowSizeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.filterServ = filterServ;
    this.fadSearchService = fadSearchService;
    this.fadSearchStateServ = fadSearchStateServ;
    this.clearFilterService = clearFilterService;
    this.tabableService = tabableService;
    this.windowSizeServ = windowSizeServ;
    this.router = router;
    this.activatedRoute = activatedRoute;
    this.videoFilterEnabled = environment.videoFilterEnabled;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.fadSearchService.searchSubj.subscribe((data) => {
        this.totalSearchResultCount = data.payload.totalResultCount;
      });

      this.conditionsListData = this.getFilterList();
      this.setClearFilters();
      this.setClearLocations();
      this.getSliderFilters();
    }
  }

  ngAfterViewInit() {
    this.refocusSub = this.tabableService.focusFilterContent$.subscribe((data) => {
      if (data) {
        this.refocusRecieve();
      }
    });
  }

  getSliderFilters() {
    this.subToVideoBioSubj();
    this.subToAhnProvSubj();
    this.subTolgbSubj();
    this.subToOnlineSchedSubj();
    this.subToMakeApptSubj();
    this.subToLanguageSubj();
    this.subToConditionSubj();
    this.subToGenderSubj();
    this.subToLocationProviderFilterSubj();
    this.subToLocationFilterSubj();
    this.subToSortOrderSubj();
    this.subToTotalResultsCountSubj();
    this.subToWindowWidthSubj();
  }

  subToTotalResultsCountSubj() {
    this.filterServ.totalResultsCountSubj.subscribe((data) => {
      this.totalResultsCount = data;
    });
  }

  subToVideoBioSubj() {
    this.filterServ.videoBioSubj.subscribe((data) => {
      this.selectedFilters.video = data;
    });
  }

  subToGenderSubj() {
    this.filterServ.genderFilterSubj.subscribe((data) => {
      this.selectedFilters.gender = data;
    });
  }

  subToLocationProviderFilterSubj() {
    this.filterServ.providerLocationFilterSubj.subscribe((data) => {
      this.isSelectedLocationProvider = data;
    });
  }

  subTolgbSubj() {
    this.filterServ.LGBTQQIP2SAASubj.subscribe((data) => {
      this.selectedFilters.lgbtq = data;
    });
  }

  subToAhnProvSubj() {
    this.filterServ.ahnProviderSubj.subscribe((data) => {
      this.selectedFilters.ahn = data;
    });
  }

  subToOnlineSchedSubj() {
    this.filterServ.onlineSchedulingSubj.subscribe((data) => {
      this.selectedFilters.schedule = data;
    });
  }

  subToMakeApptSubj() {
    this.filterServ.makeAppointmentSubj.subscribe((data) => {
      this.selectedFilters.outpatientenabled = data;
    });
  }

  subToLanguageSubj() {
    this.filterServ.lanaguageFilterSubj.subscribe((lang) => {
      this.selectedFilters.languages = lang;
    });
  }

  subToConditionSubj() {
    this.filterServ.conditionFilterSubj.subscribe((cond) => {
      this.selectedFilters.conditions = cond;
    });
  }

  subToLocationFilterSubj() {
    this.filterServ.locationFilterSubj.subscribe((data) => {
      this.selectedFilters.location = data[0];
      this.selectedFilters.locationdistance = data[1];
    });
  }

  subToSortOrderSubj() {
    this.fadSearchStateServ.currentSearchOrderSubj.subscribe((sort) => {
      if (this.selectedFilters.sortorder !== sort) {
        this.selectedFilters.sortorder = sort;
      }
    });
  }

  ngOnChanges(): void {
    this.getFilterList();
  }

  setClearFilters() {
    this.clearFilterService.isClear.subscribe((data) => {
      if (data == true) {
        this.receiveClearFilters();
      }
    });
  }

  setClearLocations() {
    this.clearFilterService.isClearLocation.subscribe((data) => {
      if (data == true) {
        this.receiveDistanceFilterChange(new DistanceOptionChange('locations', false, '', 0));
      }
    });
  }

  processConditionsData() {
    if (this.conditionsData !== undefined) {
      this.conditionsData.forEach((conditionlist) => {
        conditionlist.buckets.forEach((list) => {
          this.conditionsBucketObj = new FacetsBucketsList();
          this.conditionsBucketObj.count = this.computeBucketCountFromPercentage(list.percentage);
          this.conditionsBucketObj.percentage = list.percentage;
          this.conditionsBucketObj.stringValue = list.value.stringValue;
          if (this.conditionsBucketObj.count) {
            this.conditionBucketsArray.push(this.conditionsBucketObj);
          }
        });
      });
    }
  }

  createLanuageSpokenBucket() {
    if (this.languageSpokenData !== undefined) {
      this.languageSpokenData.forEach((languagelist) => {
        languagelist.buckets.forEach((list) => {
          this.languageSpokenBucketObj = new FacetsBucketsList();
          this.languageSpokenBucketObj.percentage = list.percentage;
          this.languageSpokenBucketObj.stringValue = list.value.stringValue;
          this.languageSpokenArray.push(this.languageSpokenBucketObj);
        });
      });
    }
  }

  receiveLanguageSpoken(childChange: ChildOptionChange) {
    this.selectedFilters.languages = childChange.optionName;
    this.setClearAllToTrue();
    this.checkAnyFilterForClear();
    this.languageEmitter.next(this.selectedFilters.languages);
  }

  receiveMobileLanguageSpoken(childChange: ChildOptionChange) {
    this.setClearAllToTrue();
  }

  getFilterList() {
    this.fadSearchService.searchSubj.subscribe((result: FadSearchResult) => {
      this.facetResults = result.payload.facetResults;
      this.genderBucketArray = new Array<FacetsBucketsList>();
      this.conditionBucketsArray = new Array<FacetsBucketsList>();
      this.viewOnlyBucketArray = new Array<FacetsBucketsList>();
      this.appointmentBucketArray = new Array<FacetsBucketsList>();
      this.languageSpokenArray = new Array<FacetsBucketsList>();
      this.ahnBucketObj = new FacetsBucketsList();

      this.conditionsData = this.filterArrayWithOperator(this.facetResults, this.conditionString);
      this.genderData = this.filterArrayWithOperator(this.facetResults, this.genderString);
      this.ahnData = this.filterArrayWithOperator(this.facetResults, this.employedString);
      this.lgbtData = this.filterArrayWithOperator(this.facetResults, this.lgbtqalignedString);
      this.videoData = this.filterArrayWithOperator(this.facetResults, this.youtubevideobioString);
      this.onlineSchedulingData = this.filterArrayWithOperator(
        this.facetResults,
        this.onlineSchedulingString,
      );
      this.makeAppointmentData = this.filterArrayWithOperator(
        this.facetResults,
        this.makeAppointmentOnlineString,
      );
      this.videoappointmentData = this.filterArrayWithOperator(
        this.facetResults,
        this.videoAppointmentString,
      );
      this.languageSpokenData = this.filterArrayWithOperator(
        this.facetResults,
        this.languageSpokenString,
      );

      this.createLanuageSpokenBucket();
      this.processConditionsData();

      if (this.conditionBucketsArray) {
        this.hasValue = true;
      }

      if (this.languageSpokenArray) {
        this.hasLangSpoken = true;
      }
    });
  }

  filterArrayWithOperator(facets, operatorString): any {
    if (facets !== null) {
      return facets.filter((val) => val.operatorName == operatorString);
    }
  }

  computeBucketCountFromPercentage(percentage: number): number {
    return Math.round((percentage / 100) * this.totalSearchResultCount);
  }

  ngOnDestroy(): void {
    this.filterSub.unsubscribe();
    this.clearSub.unsubscribe();
    this.refocusSub.unsubscribe();
  }

  //Desktop Conditions Dropdown
  receiveConditionFilterChange(childChange: ChildOptionChange) {
    this.selectedFilters.conditions = childChange.optionName;
    this.conditionEmitter.next(this.selectedFilters.conditions);
    this.setClearAllToTrue();
    this.checkAnyFilterForClear();
  }

  //Mobile Conditions Dropdown
  receiveConditionFilterChangeMobile(childChange: ChildOptionChange) {
    this.setClearAllToTrue();
  }

  //Desktop New Receiver From Gender Radio Buttons
  receiveNewGenderChange(childChange: ChildOptionChange) {
    this.selectedFilters.gender = childChange.optionName;
    this.genderEmitter.next(this.selectedFilters.gender);
    this.genderChanged(childChange);
  }

  // Desktop new location-provider filter option change
  // AKA Distance Filter
  receiveLocationProviderFilterChange(childChange: ChildOptionChange) {
    this.isSelectedLocationProvider = childChange.optionName;
    this.setClearAllToTrue();
  }

  //Mobile New Receiver From Gender Radio Buttons
  receiveNewGenderMobile(childChange: ChildOptionChange) {
    this.genderChanged(childChange);
  }

  genderChanged(childChange: ChildOptionChange) {
    this.selectedFilters.gender = childChange.optionName;
    this.setClearAllToTrue();
    this.filterServ.genderFilterSubj.next(this.selectedFilters.gender);
  }

  //Desktop Slider
  receiveShowOnlyChange(childChange: ChildOptionChange) {
    this.setClearAllToTrue();
    switch (childChange.optionName) {
      case 'Work for AHN':
        this.ahnEmitter.next(childChange.isChecked);
        this.selectedFilters.ahn = childChange.isChecked;
        this.checkAnyFilterForClear();
        break;
      case 'Have a Video Bio':
        this.videoBioEmitter.next(childChange.isChecked);
        this.selectedFilters.video = childChange.isChecked;
        this.checkAnyFilterForClear();
        break;
      case 'Are a LGBTQIA+ Champion':
        this.lgbtqiaplusEmitter.next(childChange.isChecked);
        this.selectedFilters.lgbtq = childChange.isChecked;
        this.checkAnyFilterForClear();
        break;
      case 'Schedule by Phone':
        this.phoneScheduleEmitter.next(childChange.isChecked);
        this.selectedFilters.outpatientenabled = childChange.isChecked;
        this.checkAnyFilterForClear();
        break;
      case 'Schedule Online':
        this.onlineScheduleEmitter.next(childChange.isChecked);
        this.selectedFilters.schedule = childChange.isChecked;
        this.checkAnyFilterForClear();
        break;
      default:
        this.checkAnyFilterForClear();
        break;
    }
  }

  checkAnyFilterForClear() {
    if (
      this.selectedFilters.ahn &&
      this.selectedFilters.video &&
      this.selectedFilters.lgbtq &&
      this.selectedFilters.outpatientenabled &&
      this.selectedFilters.schedule
    ) {
      this.clearFilterService.isAllCheckBoxState.next(false);
    } else {
      this.clearFilterService.isAllCheckBoxState.next(true);
    }
  }

  //Mobile Slider
  receiveMobileSlider(childChange: ChildOptionChange) {
    this.setClearAllToTrue();
    switch (childChange.optionName) {
      case 'Employed':
        this.selectedFilters.ahn = childChange.isChecked;
        this.ahnEmitter.next(childChange.isChecked);
        break;
      case 'youtubevideobio':
        this.selectedFilters.video = childChange.isChecked;
        this.videoBioEmitter.next(childChange.isChecked);
        break;
      case 'lgbtqaligned':
        this.selectedFilters.lgbtq = childChange.isChecked;
        this.lgbtqiaplusEmitter.next(childChange.isChecked);
        break;
      case 'schedulable':
        this.selectedFilters.schedule = childChange.isChecked;
        this.onlineScheduleEmitter.next(childChange.isChecked);
        break;
      case 'outPatientEnabled':
        this.selectedFilters.outpatientenabled = childChange.isChecked;
        this.phoneScheduleEmitter.next(childChange.isChecked);
        break;
    }
  }

  receiveClearFilters() {
    //TODO to reset the filters, we are destorying them and recreating them after 1 millisecond.
    //This resets the component lifecycle and values.
    this.isClearAllFilters = true;

    setTimeout(() => {
      this.isClearAllFilters = false;
    }, 1);
    this.conditionEmitter.next('');
    //Remove
    this.filterServ.lanaguageFilterSubj.next('');
    this.ahnEmitter.next(false);
    this.videoBioEmitter.next(false);
    this.lgbtqiaplusEmitter.next(false);
    this.onlineScheduleEmitter.next(false);
    this.phoneScheduleEmitter.next(false);
    this.genderEmitter.next('Any Gender');
    //this.distanceFilterEmitter.next(new LocationOptionChange('locations', false, '', 0));
    this.filterServ.clearAllFiltersSubj.next(true);

    this.selectedFilters.ahn = false;
    this.selectedFilters.conditions = '';
    this.selectedFilters.gender = 'Any Gender';
    this.selectedFilters.languages = '';
    this.selectedFilters.lgbtq = false;
    this.selectedFilters.outpatientenabled = false;
    this.selectedFilters.schedule = false;
    this.selectedFilters.video = false;

    //TODO reset the search query
    this.showClearAllFilters = false;
    this.previouslySelectedGenderOptions = [];
    this.clearFilterService.closeSideNav();
    this.clearAllEnabledStatus = true;

    let searchTerm = '';
    searchTerm = this.activatedRoute.snapshot.params['search'];
    /*let params = {};
    this.router.navigate(['search-results/' + searchTerm], {
      queryParams: params,
    });*/
  }

  setClearAllToTrue() {
    if (this.showClearAllFilters === false) {
      this.showClearAllFilters = true;
    }
  }

  receiveDistanceFilterChange(locationChange: DistanceOptionChange) {
    this.selectedFilters.location = locationChange.optionName;
    this.selectedFilters.locationdistance = locationChange.optionValue;
    this.distanceFilterEmitter.next(locationChange);
  }

  showFilterResultsMobile() {
    this.clearFilterService.closeSideNav();
    this.mobileApplyEmitter.next(true);
  }

  refocusRecieve(): void {
    this.filterContent.nativeElement.focus();
    this.tabableService.enable = false;
  }

  refocusEmit() {
    this.tabableService.searchResults();
    return false;
  }

  subToWindowWidthSubj() {
    this.windowWidthSub = this.windowSizeServ.windowWidthSubj
      .pipe(debounceTime(500))
      .subscribe((width) => {
        this.isDesktop = this.convertWindowWidthToIsDesktop(
          width,
          this.windowSizeServ.desktopMinWidth,
        );
      });
  }

  convertWindowWidthToIsDesktop(width: number, desktopMinWidth: number) {
    if (width >= desktopMinWidth) {
      return true;
    } else {
      return false;
    }
  }
}
