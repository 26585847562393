/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FadProviderDetailRequest, FadProviderDetailService } from '@dmx-fad-ui/fad/data-access';
import { Subscription } from 'rxjs';
import { PickedProvider } from '../../models/pickedProvider/pickedProvider';
import { ViewportScroller } from '@angular/common';

import {
  FilterService,
  SeoService,
  SimpleUtilsService,
  WindowSizeService,
} from '@dmx-fad-ui/fad/services';
import { ahnTextPath } from '../../components/ahn-text/ahn-text.enum';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'atlas-provider-detail-page',
  templateUrl: './provider-detail-page.component.html',
  styleUrls: ['./provider-detail-page.component.scss'],
})
export class ProviderDetailPageComponent implements OnInit, OnDestroy {
  providerNPI: string;
  pickedProvider: PickedProvider;
  fadSearchQuery: string = '';
  pickedProviderSub: Subscription;
  providerDetailSub: Subscription;
  plainTextSub: Subscription;
  providerDetail: any;
  hasData: boolean = false;
  providerName: string = '';
  noResultsFound: boolean;
  providerComments: any;
  pageType: ahnTextPath;
  isNewProviderDetailExperience = environment.isTabProviderPageEnabled;

  constructor(
    private fadProvDetServ: FadProviderDetailService,
    private filterServ: FilterService,
    private utilsServ: SimpleUtilsService,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    private windowSizeServ: WindowSizeService,
    @Inject(PLATFORM_ID) private platformId: string,
    private viewportScroller: ViewportScroller,
  ) {
    this.pageType = ahnTextPath.provider;
  }

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.activatedRoute.paramMap.subscribe((params) => {
      this.providerName = params.get('providername');
    });

    const provDetReq: FadProviderDetailRequest = this.utilsServ.createFadProviderDetailRequest(
      this.providerName,
    );
    this.subscribeToProviderDetail();
    this.fadProvDetServ.getProviderDetail(provDetReq);

    this.setPlainTextSub();

    if (isPlatformBrowser(this.platformId)) {
      const wid = document.getElementById('provider-detail-page').clientWidth;
      this.windowSizeServ.windowWidthSubj.next(wid);

      window.addEventListener('resize', () => {
        const resizeWid = document.getElementById('base-page').clientWidth;
        this.windowSizeServ.windowWidthSubj.next(resizeWid);
      });
    }
  }

  subscribeToProviderDetail() {
    this.providerDetailSub = this.fadProvDetServ.providerDetailSubj.subscribe((provDetail) => {
      if (provDetail.payload.providerDetail == null) {
        this.noResultsFound = true;
        this.seoService.addMainContent();
      } else {
        this.noResultsFound = false;
        this.providerDetail = provDetail;
        this.hasData = true;
        this.seoService.addProfileContent(provDetail.payload.providerDetail.displayName);
      }
    });
  }

  setPlainTextSub(): void {
    this.plainTextSub = this.filterServ.plainSearchTextSubj.subscribe((text) => {
      this.fadSearchQuery = text;
    });
  }

  ngOnDestroy(): void {
    this.providerDetailSub.unsubscribe();
  }
}
