<div class="new-sliders-filters">
  <mat-checkbox
    [labelPosition]="'after'"
    (change)="sliderChange($event)"
    [disableRipple]="true"
    [name]="facetName"
    [checked]="sliderCheked"
    [color]="selectedColor"
    class="child-items"
    >{{ facetName | childFilterTransform }}</mat-checkbox
  >
  <span
    #tooltip="matTooltip"
    class="splash-blue-icon material-icons-outlined"
    *ngIf="toolTip"
    matTooltip="{{ toolTip | tooltipTransform }}"
    matTooltipClass="custom-tool-tip-show-only-filter"
    (click)="tooltip.toggle()"
    (keydown.enter)="tooltip.toggle()"
    (onfocusout)="tooltip.hide()"
    (onscroll)="tooltip.hide()"
    (onmousewheel)="tooltip.hide()"
    tabindex="0"
    >info</span
  >
</div>
