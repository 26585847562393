/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import {
  PhysicianIndexService,
  UrlMonitorService,
  SeoService,
  WindowSizeService,
} from '@dmx-fad-ui/fad/services';
import { SpinnerComponent } from '../../base-page/spinner/spinner.component';
import { PhysicianLetterModel } from './physicianLetterModel';
import { IDialog } from '@dmx-fad-ui/fad/interfaces';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'atlas-physician-index-page',
  templateUrl: './physician-index-page.component.html',
  styleUrls: ['./physician-index-page.component.scss'],
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class PhysicianIndexPageComponent implements OnInit, IDialog, OnDestroy {
  userUsingSearchSub: Subscription;

  alphabet: string[];
  rawPhysicians: any = [];
  physiciansList: PhysicianLetterModel[] = [];
  physicianLetterBucket: PhysicianLetterModel[] = [];
  hasPhysicians: boolean = false;

  physicianIndexSub: Subscription;
  currentRoute: any;
  constructor(
    private physicianIndexServ: PhysicianIndexService,
    public dialog: MatDialog,
    private urlMonitorServ: UrlMonitorService,
    private windowSizeServ: WindowSizeService,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async ngOnInit(): Promise<any> {
    this.openDialog();
    this.populateLatinAlphabet();
    this.subToPhysIndex();
    this.loadPhysicians();
    this.seoService.addDirectoryContent();
    if (isPlatformBrowser(this.platformId)) {
      const wid = document.getElementById('phys-index-page').clientWidth;
      this.windowSizeServ.windowWidthSubj.next(wid);

      window.addEventListener('resize', () => {
        const resizeWid = document.getElementById('base-page').clientWidth;
        this.windowSizeServ.windowWidthSubj.next(resizeWid);
      });
    }
  }

  subToPhysIndex() {
    this.physicianIndexSub = this.physicianIndexServ.physicianIndexSubj.subscribe((resp) => {
      this.rawPhysicians = resp['providerDirectory'];
      if (JSON.stringify(this.rawPhysicians) !== '{}') {
        this.categorizePhysicians();
        this.closeDialog();
        this.hasPhysicians = true;
      }
    });
  }

  populateLatinAlphabet() {
    this.alphabet = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];
  }

  loadPhysicians() {
    this.physicianIndexServ.getPhysicianIndex();
  }

  categorizePhysicians() {
    this.alphabet.forEach((letter) => {
      this.rawPhysicians[letter].forEach((phys) => {
        this.physiciansList.push(new PhysicianLetterModel(letter, phys.name, phys.url));
      });
    });
  }

  getAlphabetizedPhysicians(
    letter: string,
    physList: PhysicianLetterModel[],
  ): PhysicianLetterModel[] {
    const letteredArray: PhysicianLetterModel[] = [];

    physList.forEach((phys) => {
      if (phys.lastNameLetter === letter) {
        letteredArray.push(phys);
      }
    });
    return letteredArray;
  }

  openDialog() {
    this.dialog.open(SpinnerComponent);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this.physicianIndexSub.unsubscribe();
  }
}
