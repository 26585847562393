<section class="ahn-fad-header">
  <div class="navbar-wrapper">
    <div class="top-nav-link">
      <a class="skip-to-content-link" (click)="refocus()" href="."> Skip to main content </a>
    </div>

    <div class="container">
      <div class="nav-top d-flex justify-content-between align-items-center">
        <div class="logo">
          <a href="https://www.ahn.org" aria-label="Allegheny Health Network">
            <img
              src="../../../../assets/AHNlogo_SVG_fixedsize.svg"
              alt="Allegheny Health Network"
              width="130px"
              height="42px"
            />
          </a>
        </div>
        <div class="top-link-list">
          <ul class="d-flex p-0 m-0">
            <li class="top-nav-link">
              <a
                (click)="onViewFullDirectory()"
                (keydown.enter)="onViewFullDirectory()"
                routerLink="/physician-index"
              >
                Provider Directory
              </a>
            </li>
            <li class="top-nav-link">
              <a href="https://selfserviceupdater.ahn.org/provider/login" target="_blank">
                Edit Provider Profile
              </a>
            </li>
            <li class="top-nav-link">
              <a href="https://mychart.ahn.org/" target="_blank"> MyChart </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
