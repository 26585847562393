export class NameCompare {
  displayName: string;

  profileName: string;
  NPI: string;
  signatureSpecialty: string;
  ratingCount: string;
  ratingValue: string;
  imageUrl: string;

  constructor(
    displayName: string,
    profileName: string,
    npi: string,
    signatureSpecialty: string,
    ratingCount: string,
    ratingValue: string,
    imageUrl: string,
  ) {
    this.displayName = displayName;
    this.profileName = profileName;
    this.NPI = npi;
    this.signatureSpecialty = signatureSpecialty;
    this.ratingCount = ratingCount;
    this.ratingValue = ratingValue;
    this.imageUrl = imageUrl;
  }
}
