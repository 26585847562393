/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrintRequest } from './print-request';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class PdfServiceService {
  constructor(private http: HttpClient) {}
  endpoint: string = `${environment.pdfRenderUrlEndpoint}`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPdf(printReq): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', `${environment.xApiKey}`);

    return this.http
      .post(this.endpoint, printReq, {
        headers: headers,
        observe: 'body',
        responseType: 'blob',
      })
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }

  getPrintRequestJSON(printReq: PrintRequest) {
    const reqJSON: any = {
      url: printReq.profileName,
    };
    return reqJSON;
  }
}
