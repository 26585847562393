<div class="provider-lgbt">
  <div class="lgbt-title">LGBTQIA+ Champion</div>
  <div class="lgbt-desc">
    Come as you are. These providers are allies, advocates, and supporters — champions of
    compassionate, just, and inclusive care.<span
      ><a
        href="https://www.ahn.org/services/medicine/center-for-inclusion-health/lgbtqia-champions"
        target="_blank"
      >
        Learn more.</a
      ></span
    >
  </div>
</div>
