import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderResultsFilterComponent } from './provider-results-filter.component';
import { FilterChildModule } from './filter-child/filter-child.module';
import { ConditionListFilterModule } from './condition-list-filter/condition-list-filter.module';
import { ClearFilterModule } from './clear-filter/clear-filter.module';
import { DistanceFilterModule } from './distance-filter/distance-filter.module';
import { NewGenderFilterComponentModule } from './new-gender-filter/new-gender-filter.module';
import { NewSlidersFilterModule } from './new-sliders-filter/new-sliders-filter.module';
import { LanguageFiltersModule } from './language-filters/language-filters.module';
import { LocationProviderFilterModule } from './location-provider-filter/location-provider-filter.module';

@NgModule({
  declarations: [ProviderResultsFilterComponent],
  exports: [ProviderResultsFilterComponent],
  imports: [
    CommonModule,
    FilterChildModule,
    ConditionListFilterModule,
    ClearFilterModule,
    DistanceFilterModule,
    NewGenderFilterComponentModule,
    LocationProviderFilterModule,
    NewSlidersFilterModule,
    LanguageFiltersModule,
  ],
})
export class ProviderResultsFilterModule {}
