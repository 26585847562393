// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class SeoService {
  constructor(
    private metaTagService: Meta,
    private titleTagService: Title,
  ) {}
  enable: boolean = false;

  addMainContent() {
    this.metaTagService.updateTag({
      name: 'description',
      content:
        'Search our complete list of AHN providers and doctors who proudly serve Pittsburgh, Erie and communities throughout Western PA.',
    });
    this.titleTagService.setTitle('Find a Doctor – Find Care at AHN | Allegheny Health Network');
  }
  addDirectoryContent() {
    this.metaTagService.updateTag({
      name: 'description',
      content:
        'Find a complete listing of all AHN doctors and providers serving the communities of Pittsburgh, Erie and Western PA.',
    });
    this.titleTagService.setTitle('Find a Doctor at AHN | Pittsburgh, Erie and Western PA');
  }
  addProfileContent(providerNameTitle: string) {
    this.metaTagService.updateTag({
      name: 'description',
      content: `Find professional, biographical, location and contact information for AHN provider ${providerNameTitle}`,
    });
    this.titleTagService.setTitle(providerNameTitle + ` | AHN`);
  }
}
