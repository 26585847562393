<div class="container">
  <div class="dmxfad-search-container">
    <div class="search-container">
      <form [formGroup]="searchFormGroup" (ngSubmit)="initiateSearch()">
        <mat-form-field floatLabel="auto" appearance="outline" class="search-bar-position">
          <span tabindex="0" class="close-icon-btn material-icons-outlined ahn-text-dishwater-gray">
            search
          </span>
          <div tabindex="-1" class="search-btn">
            <input
              (keydown)="enableSuggestProcessingOnKeydown($event)"
              class="rounded search-bar roboto-regular input-text-size shadowed search-bar-underline search-bar-radius"
              placeholder="{{ placeholder }}"
              matInput
              formControlName="searchTermsControlName"
              [matAutocomplete]="auto"
              (ngModelChange)="onSearchTextModelChange()"
              #searchInput
            />
          </div>
          <span tabindex="-1">
            <button
              tabindex="-1"
              #clearEl
              id="clearTextButton"
              type="button"
              mat-icon-button
              aria-label="Clear"
              (click)="onClearText()"
              (keydown.enter)="onClearText()"
              [disableRipple]="true"
            >
              <div
                tabindex="0"
                *ngIf="searchFormGroup.value.searchTermsControlName"
                class="clear-button"
                matTooltip="Clear"
                matTooltipClass="custom-tooltip-clear"
              >
                <mat-icon tabindex="-1">close</mat-icon>
              </div>
            </button>
          </span>
          <span [ngClass]="isLandingPage ? 'button-radius-landing-page' : 'button-radius'">
            <button
              id="search-btn-desktop"
              mat-raised-button
              class="ahn-background-shade-green ahn-text-white search-btn-desktop search-button search-button-width roboto-bold"
              mat-button
              (click)="searchCommandFromControl()"
              (keydown.enter)="searchCommandFromControl()"
              [disableRipple]="true"
            >
              SEARCH
            </button>
            <!-- This is Mobile Invisible -->
            <button
              id="search-mobile"
              (click)="searchCommandFromControl()"
              (keydown.enter)="searchCommandFromControl()"
            ></button>
          </span>
          <mat-autocomplete
            id="search-autocomplete"
            class="extend-autocomplete-height"
            #auto="matAutocomplete"
            (optionSelected)="onPickerSelected($event.option._element.nativeElement.name)"
          >
            <span *ngFor="let type of suggestResultTypes; let i = index">
              <span *ngFor="let picker of suggestedResults[type]">
                <span
                  *ngIf="
                    highlight(
                      searchFormGroup.value.searchTermsControlName.trim(),
                      picker,
                      type
                    ) as item
                  "
                >
                  <mat-option
                    [value]="picker"
                    [name]="type"
                    [attr.data-suggestType]="type"
                    [ngStyle]="{
                      height: item.length > 1 ? '60px' : '48px',
                      'line-height': item.length > 1 ? '22px' : '48px'
                    }"
                  >
                    <div [innerHTML]="item[0]"></div>
                    <div
                      style="font-size: 12px; color: #0014468c"
                      [innerHTML]="item.length > 1 ? item[1] : ''"
                    ></div>
                  </mat-option>
                </span>
              </span>
              <mat-divider
                style="background-color: #f1f4f4"
                class="mat-divider-background"
              ></mat-divider>
            </span>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
  </div>
</div>
