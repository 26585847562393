import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterChildComponent } from './filter-child.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChildFilterTransformPipeModule } from '../../../pipes/filter-child-transform/child-filter-transform-pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToolTipTransformPipeModule } from '../../../pipes/tooltip-transform/tooltip-transform-pipe.module';

@NgModule({
  declarations: [FilterChildComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    ChildFilterTransformPipeModule,
    MatTooltipModule,
    ToolTipTransformPipeModule,
  ],
  exports: [FilterChildComponent],
})
export class FilterChildModule {}
