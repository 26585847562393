<div class="new-gender-filter-container">
  <mat-radio-group
    aria-labelledby="gender-radio-group-label"
    class="gender-radio-group"
    [(ngModel)]="selectedFilter"
  >
    <mat-radio-button
      [disableRipple]="true"
      (change)="radioFilterChange($event)"
      class="gender-radio-button"
      *ngFor="let fil of filterOptions"
      [value]="fil"
    >
      {{ fil }}
    </mat-radio-button>
  </mat-radio-group>
</div>
