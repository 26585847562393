import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompareRatingsComponent } from './compare-ratings.component';

import { ProviderRatingsPipeModule } from '../../../pipes/provider-ratings-pipe.module';
import { HtmlPipeModule } from '../../../pipes/html.pipe.module';

@NgModule({
  declarations: [CompareRatingsComponent],
  imports: [CommonModule, ProviderRatingsPipeModule, HtmlPipeModule],
  exports: [CompareRatingsComponent],
})
export class CompareRatingsModule {}
