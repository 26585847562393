import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationEndEvent } from '../../models/navigationEndEvent';

@Injectable({
  providedIn: 'root',
})
export class UrlMonitorService {
  isUserUsingSearchResultsSubj = new BehaviorSubject<boolean>(false);
  urlMonitorSubj = new BehaviorSubject<NavigationEndEvent[]>([]);

  constructor() {}
}
