<div
  *ngIf="isPractiseLocation === true; then locationBuildingCards; else defaultProviderCards"
></div>

<ng-template #locationBuildingCards>
  <div class="provider-practisebuiding-left">
    <div class="provider-information-container">
      <a
        class="provider-practisebuiding-left-title sofia-pro-semi-bold"
        (click)="redirectToLocationDetailPage()"
        (keydown.enter)="redirectToLocationDetailPage()"
      >
        {{ pracBuildingPracticeName }}
      </a>
    </div>
  </div>
</ng-template>

<ng-template #defaultProviderCards>
  <div class="provider-information-container">
    <div
      class="provider-profile-image universal-pointer"
      (click)="redirectToProviderPage()"
      (keydown.enter)="redirectToProviderPage()"
    >
      <div [ngClass]="isSquare ? 'provider-square' : ''">
        <img width="100%" height="185px" [src]="providerImage" alt="AHN Provider Logo" />
      </div>
      <div *ngIf="employed" class="profile-bcg-text">
        <img
          src="../../../../../assets/small_ahn_logo.svg"
          height="17px"
          width="18px"
          alt="AHN Provider"
        />
        <div class="ahn-provider-text">AHN Provider</div>
      </div>
    </div>
    <div class="provider-information">
      <div class="provider-display-name sofia-pro-semi-bold universal-pointer">
        <a
          (click)="updateScrollY()"
          role="link"
          [routerLink]="['/', this.profileName]"
          tabindex="0"
          title="View {{ displayName }}'s Profile"
        >
          {{ displayName }}
        </a>
      </div>
      <div class="provider-text roboto-regular provider-specialty">{{ signatureSpecialty }}</div>
      <atlas-provider-star-rating
        [ratingCount]="this.ratingCount"
        [ratingValue]="this.ratingValue"
      ></atlas-provider-star-rating>
      <div style="display: flex; flex-direction: row; padding-top: 0">
        <div
          [ngClass]="seeAll ? 'conditions-text-limited' : 'conditions-text-all'"
          *ngIf="conditionsDisplay !== ''"
          style="flex: 1"
        >
          <span
            ><span><strong>Expertise: </strong></span>{{ conditionsText }}</span
          >
          <span
            *ngIf="conditionsDisplay.length >= maxCharacterCount"
            class="see-all-text"
            (click)="seeMore()"
            (keydown.enter)="seeMore()"
            tabindex="0"
            >{{ seeAll ? 'See all...' : 'See less...' }}</span
          >
        </div>
      </div>
      <div class="remove-on-tablet">
        <atlas-compare-provider-checkbox
          [displayName]="this.displayName"
          [providerNPI]="this.providerNPI"
          [profileName]="this.profileName"
          [signatureSpecialty]="this.signatureSpecialty"
          [distance]="this.distance"
        >
        </atlas-compare-provider-checkbox>
      </div>
    </div>
  </div>
</ng-template>
