/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/// <reference types="@types/google.maps" />

import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FadSeachStateService, FadSearchService } from '@dmx-fad-ui/fad/data-access';
import { Subscription } from 'rxjs';
import {
  SearchMonitorService,
  ListMapViewService,
  LocationService,
  TabableService,
} from '@dmx-fad-ui/fad/services';
import { ProviderResult } from '../provider-result/provider-result';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'atlas-provider-results',
  templateUrl: './provider-results.component.html',
  styleUrls: ['./provider-results.component.scss'],
})
export class ProviderResultsComponent implements OnInit, OnChanges, OnDestroy {
  @Output() requestTenMoreEmitter = new EventEmitter<number>();
  @Input() providerResults: ProviderResult[];
  providerResultCount: number;
  result: any;
  pageSize: number;
  totalRecordsToShow: number;
  checkLoadMoreSub: Subscription = new Subscription();
  searchTermSub: Subscription = new Subscription();
  searchStateSub: Subscription = new Subscription();
  sortOrderSub: Subscription = new Subscription();
  indexSub: Subscription = new Subscription();
  currentResultsSub: Subscription = new Subscription();
  currentSearchTerm: string;
  hasMoreResults: boolean = true;
  startIndex: number;
  currentResultsShowing: number;
  isInitialized: boolean = false;
  searchMonitorSub: Subscription;
  currentSortOrder: string;
  userAdded: string;
  subscriptionListMap: Subscription;
  allLocations: any = [];
  locations: any = [];
  bounds: any;
  google: any;
  icon: string;
  isLoadMoreActive: any;
  buttonFlag: boolean = false;
  scroll: number;
  refocusSub: Subscription = new Subscription();
  indexFromResultList: number;
  mapHeight: string = '410';

  @Input() previouslySelectedFilterOptions?: string[] = [];
  @ViewChild('searchContent') searchContent: ElementRef;

  constructor(
    private fadSearchServ: FadSearchService,
    private fadSearchStateServ: FadSeachStateService,
    private searchMonitorServ: SearchMonitorService,
    private listMapViewService: ListMapViewService,
    private locationService: LocationService,
    private tabableService: TabableService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.result = ProviderResult;
    this.pageSize = 10;
    this.totalRecordsToShow = 10;
    this.fadSearchServ = fadSearchServ;
    this.fadSearchStateServ = fadSearchStateServ;
    this.searchMonitorServ = searchMonitorServ;
    this.listMapViewService = listMapViewService;
    this.locationService = locationService;
    this.tabableService = tabableService;
  }

  ngOnInit(): void {
    this.checkLoadMoreSub = this.fadSearchStateServ.checkLoadMoreSubj.subscribe((value) => {
      this.isLoadMoreActive = value;
    });

    this.searchTermSub = this.fadSearchStateServ.currentSearchQuerySubj.subscribe((text) => {
      this.currentSearchTerm = text;
    });

    this.searchStateSub = this.fadSearchServ.searchSubj.subscribe((searchResp) => {
      this.hasMoreResults = searchResp.payload.hasMoreResults;
    });

    this.searchMonitorSub = this.searchMonitorServ.hasUsedSearchBarIndexSubj.subscribe(() => {
      this.fadSearchStateServ.currentIndexSubj.next(0);
    });

    this.indexSub = this.fadSearchStateServ.currentIndexSubj.subscribe((index) => {
      this.startIndex = index;
    });

    this.currentResultsSub = this.fadSearchStateServ.currentVisibleResultCountSubj.subscribe(
      (resultCount) => {
        this.currentResultsShowing = resultCount;
      },
    );

    this.sortOrderSub = this.fadSearchStateServ.currentSearchOrderSubj.subscribe((order) => {
      this.currentSortOrder = order;
    });

    this.subscriptionListMap = this.listMapViewService.userClickViewSubj.subscribe((data) => {
      this.userAdded = data;
    });

    this.tabableService.focusSearchResults$.subscribe((data) => {
      if (data) {
        this.refocusRecieve();
      }
    });

    if (!this.userAdded) {
      this.userAdded = this.listMapViewService.userClickValue;
    }

    if (this.providerResultCount) {
      this.providerResultCount = this.calculateResultCount(this.providerResults);
    }
    this.isInitialized = true;
  }

  ngOnDestroy() {
    this.checkLoadMoreSub.unsubscribe();
    this.searchTermSub.unsubscribe();
    this.searchStateSub.unsubscribe();
    this.indexSub.unsubscribe();
    this.sortOrderSub.unsubscribe();
    this.subscriptionListMap.unsubscribe();
    this.refocusSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isInitialized && changes['providerResults'] !== undefined) {
      if (changes['providerResults'].currentValue !== undefined) {
        this.providerResults = [];
        changes['providerResults'].currentValue.forEach((prov) => {
          this.providerResults.push(prov);
        });
      }
      if (this.providerResults) {
        this.providerResultCount = this.calculateResultCount(this.providerResults);
      }
    }
  }

  showTenMoreResults() {
    this.scroll = document.documentElement.scrollTop;
    this.requestTenMoreEmitter.emit(this.scroll);
    this.buttonFlag = true;
  }

  calculateResultCount(providerResults: ProviderResult[]): number {
    return providerResults.length;
  }

  refocusRecieve() {
    this.searchContent.nativeElement.focus();
    this.tabableService.enable = false;
  }

  isBrowser() {
    return isPlatformBrowser(this.platformId);
  }
}
