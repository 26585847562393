<div [ngClass]="isNewProviderDetailExperience ? 'provider-bio-new' : 'provider-bio'">
  <div class="provider-bio-container">
    <div class="bio-info" [innerHtml]="bio"></div>
    <div
      *ngIf="providerLanguageResults && providerLanguageResults.length > 0"
      class="provider-language"
    >
      <h3>Languages Spoken</h3>
      <div class="language-container">
        <div class="language-list" *ngFor="let providerlanguage of providerLanguageResults">
          <span>{{ providerlanguage }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="youTubeVideoSafeUrl && videoFilterEnabled" class="provider-bio-youtube">
      <h3>Video Bio</h3>
      <a [href]="youTubeVideoSafeUrl" target="_blank" class="youtube-link roboto-semi-bold">
        Meet {{ displayName }} on YouTube
        <span class="material-icons-outlined"> open_in_new </span></a
      >
      <p>* Scroll to see video disclaimer</p>
    </div>
  </div>
</div>
