<div *ngIf="userAdded !== 'map'" class="provider-result-container">
  <div attr.id="{{ profileName }}" class="provider-info-left-container">
    <div *ngIf="this.isShowSearchResultsIndexNumbers">{{ indexFromResultList }}</div>
    <atlas-provider-info
      [displayName]="displayName"
      [profileName]="profileName"
      [providerNPI]="providerNPI"
      [providerId]="providerId"
      [signatureSpecialty]="signatureSpecialty"
      [conditionsDisplay]="conditionsDisplay"
      [ratingCount]="ratingCount"
      [ratingValue]="ratingValue"
      [providerImage]="providerImage"
      [employed]="employed"
      [isPractiseLocation]="isPractiseLocation"
      [pracBuildingPracticeName]="pracBuildingPracticeName"
      [buildingSpecialties]="buildingSpecialties"
      [pracBuildingID]="pracBuildingID"
      [practiceID]="practiceID"
      [practiceName]="practiceName"
      [distance]="this.primaryPracticeDistance"
    >
    </atlas-provider-info>
  </div>
  <div class="provider-info-right-container">
    <atlas-provider-contact
      [displayName]="displayName"
      [providerNPI]="providerNPI"
      [profileName]="profileName"
      [schedulable]="schedulable"
      [providerPracticeBuilding]="providerPracticeBuildings"
      [visitTypes]="visitTypes"
      [outPatient]="outPatientEnabled"
      [isPractiseLocation]="isPractiseLocation"
      [serviceNameDisplay]="serviceDisplay"
      [pracBuildingLocationName]="pracBuildingLocationName"
    ></atlas-provider-contact>
  </div>
</div>

<div *ngIf="userAdded === 'map'" class="provider-result-map-container">
  <div attr.id="{{ profileName }}" class="provider-map-container">
    <div *ngIf="this.isShowSearchResultsIndexNumbers">{{ indexFromResultList }}</div>
    <div class="tablet-map-left-container">
      <atlas-provider-info
        [displayName]="displayName"
        [profileName]="profileName"
        [providerNPI]="providerNPI"
        [providerId]="providerId"
        [signatureSpecialty]="signatureSpecialty"
        [conditionsDisplay]="conditionsDisplay"
        [ratingCount]="ratingCount"
        [ratingValue]="ratingValue"
        [providerImage]="providerImage"
        [employed]="employed"
        [distance]="this.primaryPracticeDistance"
      >
      </atlas-provider-info>
    </div>
    <div class="tablet-map-right-container">
      <atlas-provider-contact
        [displayName]="displayName"
        [providerNPI]="providerNPI"
        [profileName]="profileName"
        [schedulable]="schedulable"
        [providerPracticeBuilding]="providerPracticeBuildings"
        [visitTypes]="visitTypes"
        [outPatient]="outPatientEnabled"
      ></atlas-provider-contact>
    </div>
  </div>
</div>
