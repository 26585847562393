import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchListMapviewComponent } from './search-list-mapview.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [SearchListMapviewComponent],
  imports: [CommonModule, MatButtonToggleModule, MatFormFieldModule],
  exports: [SearchListMapviewComponent],
})
export class SearchListMapviewModule {}
