import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderResultComponent } from './provider-result.component';
import { ProviderInfoComponent } from './provider-info/provider-info.component';
import { ProviderContactComponent } from './provider-contact/provider-contact.component';
import { RouterModule } from '@angular/router';
import { ProviderContactModule } from './provider-contact/provider-contact.module';
import { ProviderInfoModule } from './provider-info/provider-info.module';

@NgModule({
  declarations: [ProviderResultComponent],
  exports: [ProviderResultComponent, ProviderInfoComponent, ProviderContactComponent],
  imports: [ProviderContactModule, ProviderInfoModule, CommonModule, RouterModule],
})
export class ProviderResultModule {}
