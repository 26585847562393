import { Component, Input } from '@angular/core';
import { LanguageCompare } from '../../../pages/compare-page/compare-classes/language-compare/language-compare';

@Component({
  selector: 'atlas-compare-languages',
  templateUrl: './compare-languages.component.html',
  styleUrls: [
    './compare-languages.component.scss',
    '../../../pages/compare-page/compare-page.component.scss',
  ],
})
export class CompareLanguagesComponent {
  @Input() languageCompare: LanguageCompare;
  constructor() {}
}
