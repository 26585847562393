import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintExpertiseComponent } from './print-expertise.component';

@NgModule({
  declarations: [PrintExpertiseComponent],
  imports: [CommonModule],
  exports: [PrintExpertiseComponent],
})
export class PrintExpertiseModule {}
