import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MobileHeaderModule } from './mobile-header/mobile-header.module';
import { DesktopHeaderModule } from './desktop-header/desktop-header.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule,
    HttpClientModule,
    MobileHeaderModule,
    DesktopHeaderModule,
  ],
})
export class HeaderModule {}
