<div *ngIf="ratingCompare" class="provider-ratings-container star-rating-container">
  <div class="ratings-left">
    <div
      class="mobile-columns"
      *ngFor="let rating of ratingCompare.ratingsList.commentsAndRatings.ratings"
    >
      <div>
        <span class="rating-value">{{ rating.ratingValue }}</span>
        <span class="material-icons-outlined">{{
          rating.ratingValue | starRating: rating.ratingValue
        }}</span>
      </div>
      <p class="roboto-regular">{{ rating.ratingName }}</p>
    </div>
    <p *ngIf="ratingCompare.ratingsList.commentsAndRatings.ratings.length > 0" class="subtext">
      Unlike websites where anyone can post doctor reviews, only actual patients can provide
      information by completing the survey sent to them.
      <a href="https://www.ahn.org/about/about-reviews.html" target="_blank">Learn more.</a>
    </p>
    <p
      *ngIf="ratingCompare.ratingsList.commentsAndRatings.ratings.length === 0"
      class="rating-align"
    >
      - not available -
    </p>
  </div>
</div>
