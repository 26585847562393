import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasePageComponent } from './base-page.component';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '@dmx-fad-ui/fad/components';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FooterModule } from '@dmx-fad-ui/fad/components';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [BasePageComponent],
  imports: [
    CommonModule,
    RouterModule,
    HeaderModule,
    MatInputModule,
    MatListModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FooterModule,
    HttpClientModule,
  ],
})
export class BasePageModule {}
