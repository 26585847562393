<div
  *ngIf="isPractiseLocation === true; then locationBuildingCards; else defaultProviderCards"
></div>
<ng-template #locationBuildingCards>
  <div class="provider-contact-container">
    <div class="provider-contact">
      <ng-container *ngFor="let pracBuilding of providerPracticeBuilding; index as i">
        <ng-container *ngIf="i === 0">
          <div class="provider-practice-name roboto-bold">
            <a [href]="[this.routerLinkPath + pracBuildingLocationName]">{{
              pracBuildingLocationName
            }}</a>
            <div *ngIf="currentSortOrder === 'DISTANCE'">
              <span class="provider-distance">{{ pracBuilding.distance }}</span>
            </div>
          </div>
          <div class="provider-practice-name" *ngIf="pracBuilding.locationName">
            {{ pracBuilding.locationName }}
          </div>
          <div class="provider-practice-name" *ngIf="pracBuilding.sublocationName">
            {{ pracBuilding.sublocationName }}
          </div>
          <div class="provider-practice-name">{{ pracBuilding.buildingAddress }}</div>
          <div class="provider-practice-name">
            {{ pracBuilding.buildingCity }}, {{ pracBuilding.buildingState }}
            {{ pracBuilding.buildingZip }}
          </div>
          <div *ngIf="pracBuilding.phone" class="provider-phone-call">
            <a href="tel:{{ pracBuilding.phone }}">{{ pracBuilding.phone }}</a>
          </div>
        </ng-container>
      </ng-container>
      <div *ngIf="serviceNameDisplay !== undefined" class="provider-location-services">
        <div [ngClass]="seeAll ? 'conditions-text-limited' : 'conditions-text-all'" style="flex: 1">
          <span
            ><span><strong>Services: </strong></span>{{ serviceNameText }}</span
          >
          <span
            *ngIf="serviceNameDisplay.length >= maxCharacterCount"
            class="see-all-text"
            (click)="seeMore()"
            (keydown.enter)="seeMore()"
            tabindex="0"
            >{{ seeAll ? 'See all...' : 'See less...' }}</span
          >
        </div>
      </div>
      <div class="more-timings-result">
        <mat-accordion>
          <mat-expansion-panel
            (opened)="panelTimingsOpenState = true"
            (closed)="panelTimingsOpenState = false"
          >
            <mat-expansion-panel-header class="mat-expansion-panel-header-remove-bkgrd-color">
              <mat-panel-description>
                {{ panelTimingsOpenState ? 'Less Locations' : 'More Locations' }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div>Timings data comes here</div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #defaultProviderCards>
  <div class="provider-contact-container">
    <div class="provider-contact">
      <div *ngFor="let pracBuilding of providerPracticeBuilding; index as i">
        <div *ngIf="i === 0">
          <div class="provider-practice-name roboto-bold">
            <a
              class="practice-name-link"
              [href]="getEncodedUrl(pracBuilding.practiceName)"
              >{{ pracBuilding.practiceName }}</a
            >
            <div *ngIf="currentSortOrder === 'DISTANCE'">
              <span class="provider-distance">{{ pracBuilding.distance }}</span>
            </div>
          </div>
          <div
            class="provider-practice-name"
            *ngIf="pracBuilding.primaryPractice === 'true' && pracBuilding.locationName"
          >
            {{ pracBuilding.locationName }} (Primary)
          </div>
          <div
            class="provider-practice-name"
            *ngIf="pracBuilding.primaryPractice === 'true' && pracBuilding.sublocationName"
          >
            {{ pracBuilding.sublocationName }}
          </div>
          <div
            class="provider-practice-name"
            *ngIf="pracBuilding.primaryPractice === 'false' && pracBuilding.locationName"
          >
            {{ pracBuilding.locationName }}
          </div>
          <div
            class="provider-practice-name"
            *ngIf="pracBuilding.primaryPractice === 'false' && pracBuilding.sublocationName"
          >
            {{ pracBuilding.sublocationName }}
          </div>
          <div class="provider-practice-name">{{ pracBuilding.buildingAddress }}</div>
          <div class="provider-practice-name">
            {{ pracBuilding.buildingCity }}, {{ pracBuilding.buildingState }}
            {{ pracBuilding.buildingZip }}
          </div>
          <div *ngIf="outPatient === true">
            <div class="provider-fax" *ngIf="pracBuilding.fax">Fax {{ pracBuilding.fax }}</div>
            <div class="make-appointment-container" *ngIf="schedulable === 'true'">
              <button
                mat-button
                class="make-appointment-button"
                (click)="onMakeAnAppointment()"
                (keydown.enter)="onMakeAnAppointment()"
                [disableRipple]="true"
              >
                MAKE AN APPOINTMENT
              </button>
            </div>
            <div class="provider-phone-call">
              <a href="tel:{{ pracBuilding.phone }}">{{ pracBuilding.phone }}</a>
            </div>
          </div>
          <div *ngIf="this.outPatient === false">
            <atlas-no-scheduling-text></atlas-no-scheduling-text>
          </div>
        </div>
      </div>

      <div *ngIf="providerPracticeBuilding.length > 1" class="more-location-result">
        <mat-accordion>
          <mat-expansion-panel> </mat-expansion-panel>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="mat-expansion-panel-header-remove-bkgrd-color">
              <mat-panel-description>
                {{ panelOpenState ? 'Less Locations' : 'More Locations' }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div
              class="more-prac-building"
              *ngFor="let pracBuilding of moreProviderPraticeBuilding; index as i"
            >
              <div>
                <div class="provider-practice-name roboto-bold">
                  <a
                    class="practice-name-link"
                    [href]="getEncodedUrl(pracBuilding.practiceName)"
                    >{{ pracBuilding.practiceName }}</a
                  >
                  <div *ngIf="currentSortOrder === 'DISTANCE'">
                    <span class="provider-distance roboto-regular">{{
                      pracBuilding.distance
                    }}</span>
                  </div>
                </div>
                <div
                  class="provider-practice-name"
                  *ngIf="pracBuilding.primaryPractice === 'true' && pracBuilding.locationName"
                >
                  {{ pracBuilding.locationName }} (Primary)
                </div>
                <div class="provider-practice-name" *ngIf="pracBuilding.sublocationName">
                  {{ pracBuilding.sublocationName }}
                </div>
                <div
                  class="provider-practice-name"
                  *ngIf="pracBuilding.primaryPractice === 'false' && pracBuilding.locationName"
                >
                  {{ pracBuilding.locationName }}
                </div>
                <div class="provider-practice-name">{{ pracBuilding.buildingAddress }}</div>
                <div class="provider-practice-name">
                  {{ pracBuilding.buildingCity }}, {{ pracBuilding.buildingState }}
                  {{ pracBuilding.buildingZip }}
                </div>
                <div class="provider-phone">
                  <a href="tel:{{ pracBuilding.phone }}">{{ pracBuilding.phone }}</a>
                </div>
                <div class="provider-fax" *ngIf="pracBuilding.fax">Fax {{ pracBuilding.fax }}</div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</ng-template>
