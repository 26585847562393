/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/// <reference types="@types/google.maps" />

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { LocationService, FilterService, ClearFilterService } from '@dmx-fad-ui/fad/services';
import {
  FadSearchService,
  FadSeachStateService,
  FadSearchSortOrder,
} from '@dmx-fad-ui/fad/data-access';
import { DistanceOptionChange } from './distanceOptionChange';

@Component({
  selector: 'atlas-distance-filter',
  templateUrl: './distance-filter.component.html',
  styleUrls: ['./distance-filter.component.scss'],
})
export class DistanceFilterComponent implements OnInit {
  @ViewChild('distancePicklist', { static: false }) distancePicklist: MatSelect;
  @ViewChild('hiddenTriggerField') hiddenTriggerField: ElementRef;

  @Output() distanceFilterEmitter = new EventEmitter<DistanceOptionChange>();

  defaultDistanceValue: string;
  showDistancePicklist: boolean = true;
  showSelectedAddress: boolean = false;

  distanceOptions: any[] = [
    { value: 5, viewValue: '5 miles' },
    { value: 10, viewValue: '10 miles' },
    { value: 20, viewValue: '20 miles' },
    { value: 30, viewValue: '30 miles' },
    { value: 40, viewValue: '40 miles' },
  ];

  displayAddressValue: string; //address displayed in UI

  constructor(
    private locationService: LocationService,
    private fadSearchService: FadSearchService,
    private filterServ: FilterService,
    private fadSeachStateService: FadSeachStateService,
    private clearFilterService: ClearFilterService,
  ) {}

  ngOnInit(): void {
    this.defaultDistanceValue = '20';

    //TODO - improve solution?
    //force a click-event, otherwise spinner hangs until user clicks within the UI
    this.fadSearchService.searchSubj.subscribe((data) => {
      setTimeout(() => {
        this.hiddenTriggerField.nativeElement.click();
      });
    });

    //update displayed address if user triggers location sharing from sort-bar
    this.locationService.searchAddressSubject.subscribe((searchAddress) => {
      if (searchAddress !== '') {
        this.displayAddressValue = searchAddress;
        this.showSelectedAddress = true;
        this.showDistancePicklist = true;
        if (this.distancePicklist === undefined) {
          setTimeout(() => {
            this.distancePicklist.value = this.locationService.searchDistance;
          });
        }
      }
    });

    //if request has location data, then default into component
    if (this.locationService.searchAddress !== '') {
      this.displayAddressValue = this.locationService.searchAddress;
      if (this.distancePicklist === undefined) {
        setTimeout(() => {
          this.distancePicklist.value = this.locationService.searchDistance;
        });
      }
      this.showSelectedAddress = true;
      this.showDistancePicklist = true;
    } else {
      this.showSelectedAddress = false;
      this.showDistancePicklist = false;
    }
  }

  selectDistanceValue(selectedDistance: string) {
    this.locationService.searchDistance = parseInt(selectedDistance);
    this.locationService.searchAddress = this.displayAddressValue;
    this.distanceFilterEmitter.next(
      new DistanceOptionChange(
        'location',
        true,
        this.displayAddressValue,
        this.locationService.searchDistance,
      ),
    );
  }

  displayDistancePicklist() {
    this.showDistancePicklist = !this.showDistancePicklist;
  }
  onEnter(placeObj: any) {
    let selectedCity = '';
    if (placeObj !== '') {
      selectedCity = placeObj.split(',')[0];
      const selectedState = placeObj.split(',')[1];
      this.displayAddressValue = `${selectedCity}, ${selectedState}`.trim();
    }
    if (this.displayAddressValue === '') {
      this.displayAddressValue = 'Invalid location entered';
    }
    this.locationService.searchAddress = this.displayAddressValue;
    this.selectDistanceValue('20');

    //update UI mat-select values
    if (this.distancePicklist === undefined) {
      setTimeout(() => {
        this.distancePicklist.focus();
        this.distancePicklist.value = 20;
      });
    } else {
      this.distancePicklist.focus();
      this.distancePicklist.value = 20;
    }

    //update display variables
    this.showDistancePicklist = true;
    this.showSelectedAddress = true;

    //Nearest to Me should show
    this.filterServ.nearestToMeDisabled.next(false);
  }
  selectAddress(placeObj: any) {
    //Google address selected
    let selectedCity = this.getAutocompleteCity(placeObj) || '';
    if (selectedCity !== '') {
      selectedCity = selectedCity.concat(',');
    }
    const selectedState = this.getAutocompleteState(placeObj) || '';
    const selectedZip = this.getAutocompletePostalCode(placeObj) || '';

    this.displayAddressValue = `${selectedCity} ${selectedState} ${selectedZip}`.trim();
    if (this.displayAddressValue === '') {
      this.displayAddressValue = 'Invalid location entered';
    }
    this.locationService.searchAddress = this.displayAddressValue;

    //Nearest to Me should show
    this.filterServ.nearestToMeDisabled.next(false);

    //when user selects a location, execute a search using default distance = 20 miles
    this.selectDistanceValue('20');

    //update UI mat-select values
    if (this.distancePicklist === undefined) {
      setTimeout(() => {
        this.distancePicklist.focus();
        this.distancePicklist.value = 20;
      });
    } else {
      this.distancePicklist.focus();
      this.distancePicklist.value = 20;
    }

    //update display variables
    this.showDistancePicklist = true;
    this.showSelectedAddress = true;
  }

  clearSelectedAddress() {
    this.filterServ.nearestToMeDisabled.next(true);
    this.fadSeachStateService.currentSearchOrderSubj.next(FadSearchSortOrder.default);
    this.locationService.locationCoordinates = '';
    this.displayAddressValue = '';
    this.showSelectedAddress = false;
    this.showDistancePicklist = false;
    this.locationService.searchAddress = '';
    this.locationService.locationAddress = '';
    this.locationService.searchDistance = 0;
    this.filterServ.locationFilterSubj.next(['', 0]);
    this.clearFilterService.clearLocation();
  }

  getAddressComponent(placeObj, componentTemplate) {
    let result;

    if (placeObj.address_components !== undefined) {
      for (let i = 0; i < placeObj.address_components.length; i++) {
        const addressType = placeObj.address_components[i].types[0];
        if (componentTemplate[addressType]) {
          result = placeObj.address_components[i][componentTemplate[addressType]];
          return result;
        }
      }
    }
  }

  getAutocompleteCity(placeObj) {
    const COMPONENT_TEMPLATE = {
        locality: 'long_name',
      },
      city = this.getAddressComponent(placeObj, COMPONENT_TEMPLATE);
    return city;
  }

  getAutocompleteState(placeObj) {
    const COMPONENT_TEMPLATE = {
        administrative_area_level_1: 'short_name',
      },
      state = this.getAddressComponent(placeObj, COMPONENT_TEMPLATE);
    return state;
  }

  getAutocompletePostalCode(placeObj) {
    const COMPONENT_TEMPLATE = {
        postal_code: 'long_name',
      },
      postCode = this.getAddressComponent(placeObj, COMPONENT_TEMPLATE);
    return postCode;
  }

  @HostListener('document:click')
  clickToBlur() {}
}
