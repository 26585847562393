import { Pipe, PipeTransform, Sanitizer, SecurityContext } from '@angular/core';

@Pipe({
  name: 'bold',
})
export class BoldPipe implements PipeTransform {
  constructor(private sanitizer: Sanitizer) {}

  transform(textValue: string, subTextValue: string): string {
    if (subTextValue === '') {
      return textValue;
    }
    return textValue.replace(new RegExp(`(${subTextValue})`, 'gi'), '<b>$1</b>');
  }
  sanitize(str) {
    return this.sanitizer.sanitize(SecurityContext.HTML, str);
  }
}
