/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterService, ClearFilterService } from '@dmx-fad-ui/fad/services';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ChildOptionChange } from '../filter-child/childOptionChange';

@Component({
  selector: 'atlas-condition-list-filter',
  templateUrl: './condition-list-filter.component.html',
  styleUrls: ['./condition-list-filter.component.scss'],
})
export class ConditionListFilterComponent implements OnInit, OnChanges {
  @Output() conditionFilterEmitter = new EventEmitter<ChildOptionChange>();
  @Input() conditionList: any;
  selectedCondition: string = '';
  clearFilterSub: Subscription;
  conditionBucketsList: any;
  arrowStyle: boolean = false;
  arrowStyleClose: boolean = false;
  conditionFilterGroup: UntypedFormGroup;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  inputAutoComplete: MatAutocompleteTrigger;
  conditionsUserSelectedItem: string = '';

  constructor(
    private filterServ: FilterService,
    private clearFilterService: ClearFilterService,
  ) {}

  ngOnInit(): void {
    this.setFormConditionFilter();
    this.conditionFilterGroup.get('conditionFilterName').valueChanges.subscribe((value) => {
      this.conditionBucketsList = this.conditionList;
      if (value) {
        const filterValue = value.toLowerCase();
        this.conditionBucketsList = this.conditionList.filter((val) =>
          val.stringValue.toLowerCase().includes(filterValue),
        );
      }
    });

    this.filterServ.conditionFilterSubj.subscribe((conditionModel) => {
      if (conditionModel.length > 0) {
        this.conditionsUserSelectedItem = conditionModel;
        this.conditionFilterGroup.setValue({
          conditionFilterName: this.conditionsUserSelectedItem,
        });
        this.arrowStyleClose = true;
      } else {
        this.arrowStyleClose = false;
        this.conditionFilterGroup.setValue({ conditionFilterName: '' });
        this.clearFilterService.isCheckConditionFilterEnabled.next(false);
      }
    });
  }

  setFormConditionFilter() {
    this.conditionFilterGroup = new UntypedFormGroup({
      conditionFilterName: new UntypedFormControl('', [Validators.required]),
    });
  }

  openPanel(evt): void {
    evt.stopPropagation();
    const isOpen = this.inputAutoComplete.autocomplete.isOpen;
    if (isOpen) {
      this.inputAutoComplete.closePanel();
    } else {
      this.inputAutoComplete.openPanel();
    }
  }

  isOpened() {
    this.arrowStyle = !this.arrowStyle;
  }

  isClosed() {
    this.arrowStyle = !this.arrowStyle;
  }

  ngOnChanges(): void {
    this.conditionBucketsList = this.conditionList;
  }

  onCoditionSelected() {
    this.arrowStyleClose = true;
    this.conditionsUserSelectedItem = this.conditionFilterGroup.value.conditionFilterName;
    this.selectedCondition = this.conditionFilterGroup.value.conditionFilterName;
    this.filterServ.conditionFilterSubj.next(this.conditionsUserSelectedItem);
    this.conditionFilterEmitter.next(
      new ChildOptionChange('condition', false, this.selectedCondition),
    );
    //some condition dropdown state is selected
    this.clearFilterService.isCheckConditionFilterEnabled.next(true);
  }

  clearConditionFilter() {
    this.arrowStyleClose = false;
    this.filterServ.conditionFilterSubj.next('');
    this.conditionFilterEmitter.next(new ChildOptionChange('condition', false, ''));
    this.conditionFilterGroup.reset();
    //some condition dropdown state is removed
    this.clearFilterService.isCheckConditionFilterEnabled.next(false);
  }
}
