/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {
  FadSeachStateService,
  FadSearchService,
  FadSearchSortOrder,
} from '@dmx-fad-ui/fad/data-access';
import { Subscription } from 'rxjs';
import { LocationService, FilterService } from '@dmx-fad-ui/fad/services';
import { MatSelect } from '@angular/material/select';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'atlas-sort-bar',
  templateUrl: './sort-bar.component.html',
  styleUrls: ['./sort-bar.component.scss'],
})
export class SortBarComponent implements OnInit {
  @ViewChild('sortPicklist') sortPicklist: MatSelect;
  @Output() sortEmitter: EventEmitter<any> = new EventEmitter();

  searchTextSub: Subscription;
  currentSearchText: string;
  sortOrderSub: Subscription;
  selected: any;
  sortValue: string;
  activeSortOrder: string;
  nearestToMeDisabled: boolean;
  alphaSortDisabled: boolean = false;
  alphaSortDis = environment.disableAlphaSort;

  sorting: any[] = [
    { value: 'bestmatch', viewValue: 'Best Match' },
    { value: 'nearest', viewValue: 'Nearest To Me' },
    { value: 'a to z last name', viewValue: 'A to Z (Last Name)' },
    { value: 'z to a last name', viewValue: 'Z to A (Last Name)' },
  ];
  constructor(
    private fadSearchServ: FadSearchService,
    private fadSearchStateServ: FadSeachStateService,
    private locationService: LocationService,
    private filterService: FilterService,
  ) {}

  ngOnInit(): void {
    this.subscribeToSortOrder();
    this.searchTextSub = this.fadSearchStateServ.currentSearchQuerySubj.subscribe((text) => {
      this.currentSearchText = text;
      this.updateSortingOptions();
    });

    this.filterService.nearestToMeDisabled.subscribe((value) => {
      this.nearestToMeDisabled = value;
    });
  }

  updateSortingOptions() {
    if (this.currentSearchText.includes('"') || this.currentSearchText.includes('|')) {
      this.alphaSortDisabled = true;
    } else {
      this.alphaSortDisabled = false;
    }
  }

  async allowLocationAccess() {
    //hide 'nearest to me' option if user has not shared location
    // await this.locationService.requestUserLocation();
    setTimeout(() => {
      this.sortPicklist.focus();
    });
    this.locationService.updateAddressWhenLocationSharedFromSortBar();
  }

  onSelect($event: any) {
    this.activeSortOrder = this.getSortEnum($event.value);
    this.sortEmitter.next(this.activeSortOrder);
    this.sortPicklist.close();
  }

  getSortEnum(sortValue: string): string {
    switch (sortValue) {
      case 'nearest':
        return FadSearchSortOrder.nearest;
      case 'best match':
        return FadSearchSortOrder.default;
      case 'a to z last name':
        return FadSearchSortOrder.ascending;
      case 'z to a last name':
        return FadSearchSortOrder.descending;
      default:
        return FadSearchSortOrder.default;
    }
  }

  reverseSortEnum(sortValue: string): string {
    switch (sortValue) {
      case FadSearchSortOrder.nearest:
        return 'nearest';
      case FadSearchSortOrder.default:
        return 'bestmatch';
      case FadSearchSortOrder.ascending:
        return 'a to z last name';
      case FadSearchSortOrder.descending:
        return 'z to a last name';
      default:
        return 'bestmatch';
    }
  }

  subscribeToSortOrder(): void {
    this.sortOrderSub = this.fadSearchStateServ.currentSearchOrderSubj.subscribe((sort) => {
      this.activeSortOrder = sort;
      this.sortValue = this.reverseSortEnum(sort);
      if (this.sortPicklist !== undefined && sort === FadSearchSortOrder.default) {
        this.sortPicklist.value = 'bestmatch';
      }
    });
  }
}
